import React, { FC, useEffect, useState } from "react"

import {
    PlusOutlined, QuestionCircleOutlined
} from "@ant-design/icons"
import { Button, CheckboxOptionType, Tooltip } from "antd"

import styles from "./AddSpend.module.scss"
import ActiveSelect from "./components/ActiveSelect"
import AddModal from "./components/modals/AddModal"
import AddRK from "./components/modals/AddRK"
import EditModal from "./components/modals/EditModal"
import EditRK from "./components/modals/EditRK"
import TableCabs from "./components/TableCabs"
import TabSelect from "./components/TabSelect"
import { API_REPORT_TABLE } from "../../../../shared/common/api/urls"
import TableReport from "../../../../shared/common/components/reports/table-report/TableReport"
import { useActions } from "../../../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../../../shared/common/hooks/useTypedSelector"
import usePermission from "../../../../shared/common/hooks/usePermission";
import { ImportExcel } from "./components/modals/ImportExcel";

import { useNavigate } from 'react-router-dom';
import ProcessRKModal from "./components/modals/ProcessRKModal";
import AdButton from "./components/AdButton";
const AddSpend: FC = () => {
    const navigate = useNavigate();
    const checkPermission = usePermission()
    const { isUpdate } = useTypedSelector((state) => state.adsSpend)
    const { setAdsSpendUpdate } = useActions()
    const metrics = [
        { name: "Дата рекламного расxода", dataKey: "date_stat" },
        { name: "ID рекламного кабинета", dataKey: "account_id" },
        { name: "Название рекламного кабинета", dataKey: "account_name" },
        { name: "Название кампании", dataKey: "campaign_name" },
        { name: "Название группы объявлений", dataKey: "adset_name" },
        { name: "Название Объявления", dataKey: "ads_name" },
        { name: "ID кампании", dataKey: "campaign_id" },
        { name: "ID группы объявлений", dataKey: "adset_id" },
        { name: "ID объявления", dataKey: "ads_id" },
        { name: "Сумма расхода", dataKey: "spend" },
        { name: "Валюта", dataKey: "currency_name" },
        { name: "utm_source", dataKey: "utm_source_value" },
        { name: "utm_medium", dataKey: "utm_medium_value" },
        { name: "utm_campaign", dataKey: "utm_campaign_value" },
        { name: "utm_term", dataKey: "utm_term_value" },
        { name: "utm_group", dataKey: "utm_group_value" },
        { name: "utm_content", dataKey: "utm_content_value" },
        { name: "Показы", dataKey: "impressions" },
        { name: "Клики", dataKey: "count_link_clicks" },
        { name: "Домен", dataKey: "domain_value" },
        { name: "Страница", dataKey: "page_value" },
        { name: "URL Ссылка", dataKey: "url_value" },
        { name: "Заголовок", dataKey: "title_value" },
        { name: "Подзаголовок", dataKey: "subtitle_value" },
        { name: "Текст", dataKey: "text_value" },
        { name: "ID картинки", dataKey: "picture_value" },
        { name: "URL картинки", dataKey: "picture_url_value" },
        { name: "Превью объявления", dataKey: "preview_url_value" },
        { name: "Текст кнопки", dataKey: "button_text_value" },
        { name: "Статус обработки", dataKey: "processing_status" },
        { name: "Комментарий", dataKey: "ads_comment" },
    ]
    const [addModalVisible, setAddModalVisible] = useState(false)

    const [pageMode, setPageMode] = useState(Number(new URLSearchParams(location.search).get('tab')) || 1)
    const [addRKVisible, setAddRKVisible] = useState(false)
    const [editRKVisible, setEditRKVisible] = useState({
        visible: false,
        id: 0
    })
    const [processRKState, setProcessRKState] = useState({
        visible: false,
        id: 0
    })
    const [importVisible, setImportVisible] = useState<boolean>(false)
    const [mode, setMode] = useState(Number(new URLSearchParams(location.search).get('sub')) || 1)
    const [refetch, setRefetch] = useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('showModal') === 'true') {
            setAddModalVisible(true);
            // Удаляем параметр из URL после открытия
            searchParams.delete('showModal');
            navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
        }
    }, [location.search]);

    const handleCloseModal = () => {
        setAddModalVisible(false);
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('showModal');
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    };

    const handleRenameRK = (record: { id: number }) => {
        setEditRKVisible({ visible: true, id: record.id })
    }
    const handleProcessingRK = (record: { id: number }) => {
        setProcessRKState({ visible: true, id: record.id })
    }
    const plainOptions = [
        { label: "Рекламные кабинеты", value: 1 },
        { label: "Объявления", value: 2 }
    ] as (string | number | CheckboxOptionType)[]

    const handleChangeSubTab = (mode: number) => {
        setMode(Number(mode))
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('sub', `${mode}`)
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }

    useEffect(() => {
        const action = addModalVisible ? "add" : "remove"
        document.body.classList[action]("hiddenOverflow")
    }, [addModalVisible])
    return (
        <div>
            <div className={styles.selectWrapper}>
                <TabSelect options={plainOptions} pageMode={pageMode} setPageMode={setPageMode} />
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    columnGap: "8px",
                    marginTop: "-50px"
                }}>
                    <div>
                        <Button
                            type="default"
                            icon={<PlusOutlined />}
                            style={{ background: "#e51400", color: "white" }}
                            onClick={() => {
                                setAddModalVisible(true);
                                const searchParams = new URLSearchParams(location.search);
                                searchParams.set('showModal', 'true');
                                navigate(`${location.pathname}?${searchParams.toString()}`);
                            }}
                        >
                            Добавить расход
                        </Button>
                    </div>
                    <Button style={{ border: "1px solid black" }} onClick={() => setImportVisible(true)}
                        type="default">Загрузить объявление</Button>
                    <Tooltip
                        placement="topLeft"
                        title={<div className="page-title-tooltip-text">
                            Вы можете загрузить сотни объявлений в SCAN одним файлом. <a href="https://help.scananalytics.ru/massovaya-zagruzka-obyavleniy-ruchnogo-razmescheniya/" rel="noopener noreferrer" target="_blank">Подробнее</a>
                            <br />
                            <a href="https://help.scananalytics.ru/wp-content/uploads/2025/03/shablon-zagruzki-dannyh-po-ruchnoy-zagruzke-reklamnyh-rashodov.xlsx" rel="noopener noreferrer" target="_blank">Скачать шаблон файла</a>
                        </div>}
                        color="#fff"
                        zIndex={9999}
                        overlayClassName="page-title-tooltip"
                    >
                        <QuestionCircleOutlined
                            style={{ color: "#ccc", fontSize: 16 }} />
                    </Tooltip>
                </div>
                {pageMode === 1 && (
                    <>
                        <AdButton imgStyles={{ height: "60px" }} imgSrc={'/assets/img/ads/ads.jpg'}
                            text={"Создать рекламный кабинет"} onClick={() => setAddRKVisible(true)} />
                    </>
                )}

                <div className={styles.addWrapper}>
                    {pageMode === 1 && <ActiveSelect mode={mode} setMode={handleChangeSubTab} />}
                </div>
            </div>

            {pageMode === 2 ? (
                <TableReport
                    apiUrl={API_REPORT_TABLE}
                    metrics={metrics}
                    withFilter={true}
                    filtersFilter={["Реклама (РР)"]}
                    withSegment={true}
                />
            )
                : <TableCabs refetch={refetch} setRefetch={setRefetch} handleRenameRK={handleRenameRK}
                    handleProcessingRK={handleProcessingRK} mode={mode} />}
            {importVisible &&
                <ImportExcel setRefetch={setRefetch} visible={importVisible} setVisible={setImportVisible} />}
            <AddRK setRefetch={setRefetch} isVisible={addRKVisible} setVisible={setAddRKVisible} />
            <EditRK setRefetch={setRefetch} isVisible={editRKVisible} setVisible={setEditRKVisible} />
            {processRKState.visible &&
                <ProcessRKModal state={processRKState} setRefetch={setRefetch} setState={setProcessRKState} />}

            {isUpdate && <EditModal
                setRefetch={setRefetch}
                isVisible={isUpdate}
                setVisible={setAdsSpendUpdate}
            />}
            {addModalVisible && <AddModal
                setRefetch={() => { }}
                isVisible={addModalVisible}
                setVisible={handleCloseModal}
            />}
        </div>
    )
}

export default AddSpend
