import React, { FC, useEffect, useState } from "react"

import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Button, Popover, Tooltip, Checkbox } from "antd"

import { IAttributionItem } from "../../../../../../../app/types/filter"
import { useActions } from "../../../../../hooks/useActions"
import { useTypedSelector } from "../../../../../hooks/useTypedSelector"

const ManualSelect: FC<IAttributionItem> = ({
                                                id,
                                                icon,
                                                name,
                                                selectValues,
                                                description,
                                                isDashboardAttributes,
                                                setBtnDisabled,
                                            }) => {
    const filtersReq = useTypedSelector((state) =>
        isDashboardAttributes
            ? state.attributes.attributesDashboard
            : state.attributes.attributes
    )

    const {
        setAttributes,
        setDashboardAttributes,
        setMetaFilterUpdate,
    } = useActions()

    const [ isPopoverVisible, setIsPopoverVisible ] = useState(false)
    const [ currentSelection, setCurrentSelection ] = useState<string[]>([])

    const { isFixedHeader } = useTypedSelector((state) => state.dashboard)

    useEffect(() => {
        initializeSelection()
    }, [])

    const initializeSelection = () => {
        const initialValue = filtersReq[`${id}`] || []
        setCurrentSelection(initialValue)

        if (initialValue.length === 0 && selectValues.length > 0) {
            const defaultSelection = selectValues.find((item) => item.id === "1")
                ? [ "2" ]
                : [ selectValues[0].id ]
            updateSelection(defaultSelection, true)
        }
    }

    const updateSelection = (selection: string[], initial = false) => {
        const payload = {
            ...filtersReq,
            [`${id}`]: selection,
        }

        // Просто сохраняем состояние выбора, но не отправляем запрос
        isDashboardAttributes ? setDashboardAttributes(payload) : setAttributes(payload)
        setCurrentSelection(selection)

        // Если выбор изменился (не инициализация), показать кнопку
        if (!initial) {
            setMetaFilterUpdate({ value:true })
        }
        setBtnDisabled && setBtnDisabled(false)
    }


    const handleCheckboxChange = (checked: boolean, value: string) => {
        let newSelection = null 
        if(value === 'blocks') {
            newSelection = checked ? [ value ] : []
        }else{
        newSelection = checked
            ? [ ...currentSelection, value ].filter((item) => item !== 'blocks')
            : currentSelection.filter((item) => item !== value)
        }
        updateSelection(newSelection)
        setBtnDisabled && setBtnDisabled(false)
    }

    const renderSelectionList = () => (
        <div className="params__attr-select-list">
            {/*рендер с чекбоксами */}
            {!selectValues.find((el) => el.icon)  ? (
                selectValues.map((value) => (
                    <div key={value.id} className="segment-list-item">
                        <Checkbox
                            checked={currentSelection.includes(value.id)}
                            onChange={(e) => handleCheckboxChange(e.target.checked, value.id)}
                        >
                            {value.name}
                        </Checkbox>
                    </div>
                ))
            ) : (
                // рендер без чекбоксов 
                selectValues.map((value) => (
                    <div key={value.id} className="segment-list-item"
                         onClick={() => {
                             setIsPopoverVisible(false)
                             updateSelection([ value.id ])
                         }}>
                        <div
                        >
                            {value.icon ? value.icon : null} {value.name}

                        </div>
                    </div>
                ))
            )}
        </div>
    )

    const displaySelection = currentSelection
        .map((id) => selectValues.find((item) => item.id === id)?.name || "")
        .filter((name) => name.trim() !== "") // Убираем пустые имена
        .join(" | ")


    return (
        <div className="params__attr-list-item">
            <Popover
                content={renderSelectionList()}
                open={isPopoverVisible}
                onOpenChange={(visible) => setIsPopoverVisible(visible)}
                trigger="click"
                placement="bottomLeft"
                showArrow={false}
                overlayStyle={{ paddingTop: 0 }}
            >
                <Button
                    icon={icon ? icon : null}
                    className="params__segment-show-button"
                >
                    {name}&nbsp;
                    {displaySelection || "Не выбрано"}
                    &nbsp; <DownOutlined />
                </Button>
            </Popover>

            {description && !isFixedHeader && (
                <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
                    <Tooltip
                        placement="bottom"
                        title={
                            <div
                                className="page-title-tooltip-text"
                                dangerouslySetInnerHTML={{ __html: description }}
                            ></div>
                        }
                        color="#fff"
                        zIndex={9999}
                        trigger="click"
                        overlayClassName="page-title-tooltip"
                    >
                        <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
                    </Tooltip>
                </span>
            )}
        </div>
    )
}
export default ManualSelect