import React, { useState } from 'react';
import { Modal, List, Tabs, Input, Button } from 'antd';
import { getLinkPath } from '../../../../../helpers/utils/functions';
import { routeNames } from '../../../constants/routes';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

type ModalProps = {
	visible: boolean;
	onClose: () => void;
	schoolList: { [key: string]: string };
	selectedSchool: { id: string; name: string } | null;
	changeSchool: (id: string) => void;
	schoolSelectorRef: React.RefObject<HTMLDivElement>;
};

const SchoolModal: React.FC<ModalProps> = ({ visible, onClose, schoolList, selectedSchool, changeSchool, schoolSelectorRef }) => {
	const [activeTab, setActiveTab] = useState('all');
	const [searchTerm, setSearchTerm] = useState('');

	const filteredSchools = Object.entries(schoolList || {}).filter(([key, val]) =>
		val.toLowerCase().includes(searchTerm.toLowerCase())
	);
	return (
	
		<Modal
			title={
				<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
					<div style={{ display: 'flex', gap: 8, marginRight: 30 }}>
						<Button
							type={activeTab === 'all' ? 'primary' : 'default'}
							onClick={() => setActiveTab('all')}
							style={{ borderRadius: '5px' }}
						>
							Все
						</Button>
						<Button
							type={activeTab === 'owner' ? 'primary' : 'default'}
							onClick={() => setActiveTab('owner')}
							style={{ borderRadius: '5px' }}
						>
							Я владелец
						</Button>
						<Button
							type={activeTab === 'employee' ? 'primary' : 'default'}
							onClick={() => setActiveTab('employee')}
							style={{ borderRadius: '5px' }}
						>
							Я сотрудник
						</Button>
					</div>
					<Button
						type="primary"
						style={{ borderRadius: '5px' }}
					>
						<Link to={getLinkPath(routeNames.schoolAdd)}>
							Добавить школу
						</Link>
					</Button>
				</div>
			}
			visible={visible}
			onCancel={onClose}
			onOk={onClose}
			footer={null}
			getContainer={() => schoolSelectorRef.current || document.body}
			style={{ position: 'absolute', top: "55px", left: "215px", transform: 'translateX(0)', zIndex: 1000 }}
		>
			<Input
				placeholder="Поиск по названию школы"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				style={{ marginBottom: '10px', borderRadius: '5px' }}
			/>
			<List
				dataSource={filteredSchools}
				renderItem={([key, val]) => (
					<List.Item
						onClick={() => {
							changeSchool(key);
							onClose();
						}}
						style={{
							cursor: 'pointer',
							padding: '10px',
							backgroundColor: selectedSchool?.id === key ? '#f0f0f0' : 'white'
						}}
					>
						{val}
					</List.Item>
				)}
			/>
		</Modal>
	);
};

export default SchoolModal;