import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/AdMenu.module.scss';
import { TabType } from '../types';

type DynamicMenuProps = {
	isOpen: boolean;
	onClose: () => void;
	activeMainTab: 'overview' | 'dashboards' | 'reports' | 'ads' | 'bd' | 'access' | 'tools' | 'settings' | 'import' | 'suppliers' | 'education';
	activeTab: TabType;
	setActiveTab: (tab: TabType) => void;
	menuType: 'ad' | 'bd' | 'access' | 'tools' | 'settings' | 'import';
	sections?: Array<{
		title?: string;
		items: string[];
		itemLabels: Record<string, string>;
	}>;
	items?: Array<{
		key: string;
		label: string;
		url?: any;
		onClick?: () => void;
	}>;
};


const MenuItem: React.FC<{
	item: {
		key: string;
		label: string;
		url?: any;
		onClick?: () => void;
	};
	activeTab: TabType;
	setActiveTab: (tab: TabType) => void;
	onClose: () => void
}> = ({ item, activeTab, setActiveTab, onClose }) => {
	const className = `${styles['menu-item']} ${activeTab === item.key ? styles['active'] : ''}`;

	const handleClick = () => {
		// Всегда сбрасываем активную вкладку при клике
		setActiveTab('all');
		item.onClick?.();
		onClose();
	};

	if (item.onClick) {
		return (
			<div className={className} onClick={handleClick}>
				{item.label}
			</div>
		);
	}

	return (
		<Link
			to={item.url || '#'}
			className={className}
			onClick={handleClick}
		>
			{item.label}
		</Link>
	);
};

// Компонент для секций с заголовками
const MenuSection: React.FC<{
	section: {
		title?: string;
		items: string[];
		itemLabels: Record<string, string>;
	};
	activeTab: TabType;
	setActiveTab: (tab: TabType) => void;
}> = ({ section, activeTab, setActiveTab }) => (
	<div className={styles['menu-section']}>
		{section.title && <div className={styles['menu-title']}>{section.title}</div>}
		{section.items.map((item) => (
			<div
				key={item}
				className={`${styles['menu-item']} ${activeTab === item ? styles['active'] : ''}`}
				onClick={() => setActiveTab(item as TabType)}
			>
				{section.itemLabels[item]}
			</div>
		))}
	</div>
);

const DynamicMenu: React.FC<DynamicMenuProps> = ({
	isOpen,
	onClose,
	activeTab,
	setActiveTab,
	menuType,
	sections,
	items
}) => {
	useEffect(() => {
		if (!isOpen) {
			setActiveTab('all'); // Сброс при закрытии меню
		}
	}, [isOpen, setActiveTab]);

	if (!isOpen) return null;

	return (
		<div className={styles['side-menu']}>
			<div className={styles['side-menu-content']}>
				{['ad', 'access', 'import', 'tools'].includes(menuType) && items && (
					<div className={styles['menu-section']}>
						{items.map((item) => (
							<MenuItem
								key={item.key}
								item={item}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								onClose={onClose} // Передаем onClose
							/>
						))}
					</div>
				)}

				{menuType === 'bd' && sections?.map((section, index) => (
					<MenuSection
						key={index}
						section={section}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				))}

				{menuType === 'settings' && (
					<>
						{sections?.map((section, index) => (
							<MenuSection
								key={`section-${index}`}
								section={section}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
							/>
						))}

						{items && (
							<div className={styles['menu-section']}>
								{items.map((item) => (
									<MenuItem
										key={item.key}
										item={item}
										activeTab={activeTab}
										setActiveTab={setActiveTab}
										onClose={onClose} // Передаем onClose
									/>
								))}
							</div>
						)}
					</>
				)}
			</div>
			<div className={styles['side-menu-overlay']} onClick={onClose} />
		</div>
	);
};

export default DynamicMenu;