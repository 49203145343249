import dayjs from "dayjs"

const LENGTH_DATE = 10 // Формат даты в виде 01.01.1972 -> 10 символов
export const FORMAT = "DD.MM.YYYY"
const MONTHES = [
  "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
  "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
]
const WEEKDAYS = [
  "понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскресенье"
]

export const formatTime = (sec: number) => {
  let seconds: string | number = sec ? sec : 0
  let minutes: string | number = Math.floor(seconds / 60)

  minutes = minutes >= 10 ? minutes : "0" + minutes
  seconds = Math.floor(seconds % 60)
  seconds = seconds >= 10 ? seconds : "0" + seconds

  return minutes + ":" + seconds
}

export const isDate = (str: string | undefined) => dayjs(str).isValid()

export const isPeriod = (str: string | undefined) => {
  if (!str) return false

  if (str.includes("&nbsp;-&nbsp;)")) {
    const [ start, end ] = str.split("&nbsp;-&nbsp;")
  
    return dayjs(start, FORMAT).isValid() && dayjs(end, FORMAT).isValid()  
  }

  const length = str.length
  const start = str.slice(0, LENGTH_DATE)
  const end = str.slice(length - LENGTH_DATE)

  return dayjs(start, FORMAT).isValid() && dayjs(end, FORMAT).isValid()  
}

export const isMonth = (str: string[] | undefined) => {
  if (!str) return false

  let isValid = false
  for(let month of MONTHES) {
    if (str.includes(month)) return true
  }

  return isValid
}

export const monthSort = (a: string, b: string, typeSort: "ASC" | "DESC" | "") => {
  let indexA = 0
  let indexB = 0
  for(let index in MONTHES) {
    if (a.includes(MONTHES[index])) {
      indexA = Number(index)
    }
    if (b.includes(MONTHES[index])) {
      indexB = Number(index)
    }
  }

  return typeSort === "ASC" ? indexA - indexB : indexB - indexA
}

export const isQuart = (str: string | undefined) => !!str && str.toLowerCase().includes("квартал")

export const isWeekday = (str: string | undefined) => {
  if (!str) return false
  return WEEKDAYS.includes(str.toLowerCase())
}

export const weekdaySort = (a: string, b: string, typeSort: "ASC" | "DESC" | "") => {
  const indexA = WEEKDAYS.indexOf(a.toLowerCase())
  const indexB = WEEKDAYS.indexOf(b.toLowerCase())

  return typeSort === "ASC" ? indexA - indexB : indexB - indexA
}
