import { IReportsState } from "../../../../../app/providers/redux/slices/reports/params/reports/types";
import { STATIC_TAGS } from "../components/constants/tags";
import { TabContentProps } from "../components/TabContent";
import { DatePeriodType, TabType } from "../types";

// const tabs: TabType
type SubTub = "all" | "favorites";
type MainTab = TabContentProps["mainTab"];

const periodType: Record<DatePeriodType, 1 | 2 | 3 | 4 | 5> = {
    registrations: 1,
    "orders": 2,
    "payments": 3,
    "ads": 4,
    "webinars": 5,
};

const isDatePeriodType = (tab: TabType | undefined): tab is DatePeriodType => {
    const keys = Object.keys(periodType);
    const availableTabs = new Set<string | number>(keys);

    return !!tab && availableTabs.has(tab);
};

type ContentTabValue = 1 | 2 | 3;
const сontentTab: Record<Exclude<TabContentProps["activeContentTab"], "all">, ContentTabValue> = {
    my: 1,
    schools: 2,
    scan: 3,
};

export const filterByParams = (
    report: IReportsState["savedReports"][number],
    activeTab: TabType | undefined,
    subTab: SubTub, 
    mainTab: MainTab,
    tags: TabContentProps['tags'],
    activeContentTab: TabContentProps["activeContentTab"],
    searchQuery: string,
) => {
    // Фильтрация по имени
    if (!report.name) return false;

    // Проверка поисковой строки
    if (searchQuery && !report.name.toLowerCase().includes(searchQuery.toLowerCase())) return false;

    // фильтрация по табе контента (все школы и пр.)
    if (activeContentTab in сontentTab && activeContentTab !== "all" && report.access_id !== сontentTab[activeContentTab]) return false;

	// Фильтр по избранным (если выбрана вкладка "Избранные")
	if (subTab === "favorites" && !report.is_favorite) return false;

    // Проверка если выбрана таба "по периоду"
    if (mainTab === "reports" && isDatePeriodType(activeTab) && periodType[activeTab] !== report.report_id) return false;

    if (activeTab) {
        const itemTags = report.tags
                                ? Array.isArray(report.tags)
                                    ? report.tags
                                    : Object.entries(report.tags).map(([id, name]) => ({ id: Number(id), name: String(name) })) : [];
        
        // Проверяем серверные теги
        const serverTag = tags.reports.concat(tags.dashboards).find(t =>
            t.id === activeTab || t.name === activeTab
        );
        
        // Проверяем статические теги
        const staticTag = STATIC_TAGS.find(t =>
            t.id === activeTab || t.name === activeTab
       );
        
        // Если выбран серверный тег
        if (serverTag) return itemTags.some((t: any) => t.id === serverTag.id);
        
        if (staticTag) return itemTags.some((t: any) => t.id === staticTag.id) ||
            (staticTag.id === 1 && report.category === 'manager') ||
            (staticTag.id === 2 && report.category === 'traffic') ||
            (staticTag.id === 3 && report.category === 'funnels') ||
            (staticTag.id === 4 && report.category === 'contractors');
    }

    return true;
};
