import { createSlice, PayloadAction } from "@reduxjs/toolkit"
const updateQueryParams = (data:any,key?:any)=>{}
import { ITableState } from "./types"
import { normalizeCreatedMetrics } from "../../../../../../helpers/utils/functions"
import { prepareRows } from "../../../../../../helpers/utils/reports"
import { listKeysGroupings } from "../../../../../../shared/common/components/reports/Groupings"
import { listKeysMetrics } from "../../../../../../shared/common/components/reports/Metrics"
export const defaultGroupings = [ // Дефолтные группировки
    "reg_utm_source",
    "reg_utm_medium",
    "reg_utm_campaign",
].map((dataKey) => listKeysGroupings.find((item: any) => item.dataKey === dataKey))
export const defaultMetrics = [ // Дефолтные метрики
    "regs_count",
    "users_count",
    "orders_count",
    "orders_sum",
    "payments_count",
    "payments_sum",
    "orders_to_forders_converse",
    "user_to_buyer_converse",
].map((dataKey) =>
    listKeysMetrics.find((item: any) => item.dataKey === dataKey))

const defaultMetricsRegNotPrepareNumber = [
    "regs_count",
].map((dataKey) =>
    listKeysMetrics.find((item: any) => item.dataKey === dataKey)
);

export const defaultMetricsReg = defaultMetricsRegNotPrepareNumber
    .filter((item) => item)
    .map((item) => ({
        ...item,
        dataKey: item.dataKey,
    }));


const defaultGroupingsRegNotPrepareNumber = [
    "reg_date_y",
].map((dataKey) =>
    listKeysGroupings.find((item: any) => item.dataKey === dataKey)
);

export const defaultGroupingReg = defaultGroupingsRegNotPrepareNumber
    .filter((item) => item)
    .map((item) => ({
        ...item,
        dataKey: item.dataKey,
    }));

const fakeCreatedMetricsData =  [ {
    name: "Созданные метрики",
    children: []
} ]

const initialState: ITableState = {
    groupings: defaultGroupings,
    metrics: defaultMetrics,
    createdMetrics:fakeCreatedMetricsData || [],
    groupsChecked: [],
    metricsChecked: [],
    totalChecked: [],
    percentages: [],
    tableData: {
        data: [],
        total: {},
        totalChosen:{
            active:[],
            metrics:{}
        }
    },
    filtersTable: {},
    sortTable: {},
    groupsLimit: false,
    metricsLimit: false,
    metrics_filters_update: false,

    tableReportData: {
        data: [],
        total: {}
    },
    metricsReport: [],
    metricsReportChecked: [],
    blocks: {}
}
const initialBlockState = {
    groupings: defaultGroupings,
    metrics: defaultMetrics,
    createdMetrics:fakeCreatedMetricsData || [],
    groupsChecked: [],
    metricsChecked: [],
    totalChecked: [ { label: "Итого и среднее", pathStr: "total" } ],
    percentages: [],
    tableData: {
        data: [],
        total: {},
        totalChosen:{
            active:[],
            metrics:{}
        }
    },
    filtersTable: {},
    sortTable: {},
    groupsLimit: false,
    metricsLimit: false,
    metrics_filters_update: false,

    tableReportData: {
        data: [],
        total: {}
    },
    metricsReport: [],
    metricsReportChecked: [],
}
const url = new URL(window.location.href)
const currentParams = url.searchParams.get("params")
const paramsObject = currentParams ? JSON.parse(currentParams) : {}
!paramsObject?.totalChecked && updateQueryParams([ { label: "Итого и среднее", pathStr: "total" } ],"totalChecked")

export const tableSlice = createSlice({
    name: "table",
    initialState: initialState,
    reducers: {
        setDashboardsBlocksTableInitialState:(state,action: PayloadAction<{ids:string[],data?:Partial<Omit<ITableState,"blocks">>}>) => {
            const { ids,data } = action.payload

            const newBlocksValues:{[key:string]:Omit<ITableState,"blocks">} = {}
            ids.forEach((el)=>{
                newBlocksValues[el] = state.blocks[ids[0]] || initialBlockState
            })
            if(data){
                newBlocksValues[ids[0]] = { ...newBlocksValues[ids[0]],...data }
            }
            state.blocks = { ...state.blocks,...newBlocksValues }
        },
        setCreatedMetrics:(state,action:PayloadAction<any>) => {
            const newCreatedMetrics = normalizeCreatedMetrics(action.payload)
            state.metrics.forEach(metric => {
                const correspondingMetric = newCreatedMetrics.find(nm => nm.dataKey === metric.dataKey)
                if (correspondingMetric) {
                    Object.keys(correspondingMetric).forEach(key => {
                        metric[key] = correspondingMetric[key]
                    })
                }
            })
            state.metricsChecked.forEach(metric => {
                const correspondingMetric = newCreatedMetrics.find(nm => nm.dataKey === metric.name)
                if (correspondingMetric) {
                    Object.keys(correspondingMetric).forEach(key => {
                        if(key === "name"){
                            metric["label"] = correspondingMetric["name"]
                        }
                        if(key === "plan"){
                            metric[key] ? metric[key] = correspondingMetric[key] : null
                        }
                    })
                }
            })
          state.createdMetrics[0].children = newCreatedMetrics
        },
        setTotalChosenStatus: (state,action:PayloadAction<any>) => {
            state.tableData.totalChosen.active = action.payload
        },
        setTotalChosenMetrics: (state, action: PayloadAction<any>) => {
            state.tableData.totalChosen.metrics = { ...action.payload }
        },
        setMetrics: (state, action: PayloadAction<any>) => {
            const { blockId,data } = action.payload

            if(blockId){
                state.blocks[blockId].metrics = data
            }else{
                
                updateQueryParams(data.map((el:{dataKey:string})=>el.dataKey),"metrics")
                state.metrics = data
            }
        },
        setMetricsChecked: (state, action: PayloadAction<any>) => {
            const { blockId,data } = action.payload

            if(blockId){
                state.blocks[blockId].metricsChecked = data
            }else{
                state.metricsChecked = data
            }
        },
        setGroupings: (state, action: PayloadAction<any>) => {
            const { blockId,data } = action.payload
            if(blockId){
                state.blocks[blockId].groupsChecked = []
                state.blocks[blockId].groupings = data
            }else{
                state.groupsChecked = []
                updateQueryParams(data.map((el:{dataKey:string})=>el.dataKey),"groupings")
                state.groupings = data
            }
        },
        setGroupingsChecked: (state, action: PayloadAction<any | { data:any[],blockId:string }>) => {
            if(Array.isArray(action.payload)){
                const getGroupsCheckedObject = () => {
                    const url = new URL(window.location.href)
                    const currentParams = url.searchParams.get("params")
                    const paramsObject = currentParams ? JSON.parse(currentParams) : {}
                    if(paramsObject?.groupsChecked?.includes(action.payload[action.payload.length-1].pathStr)){
                        return paramsObject?.groupsChecked
                    }else{
                        return action.payload.map((el:{pathStr:string})=>el.pathStr)
                    }
                }
                updateQueryParams(action.payload.length > 0 ?getGroupsCheckedObject() : [],"groupsChecked")
                state.groupsChecked = action.payload
            }else {
                state.blocks[(action.payload as unknown as  {data:any[],blockId:string}).blockId].groupsChecked = action.payload
            }
        },
        setTotalChecked: (state) => {
            updateQueryParams([ { label: "Итого и среднее", pathStr: "total" } ],"totalChecked")
            state.totalChecked = [ { label: "Итого и среднее", pathStr: "total" } ]
        },
        clearTotalChecked: (state) => {
            updateQueryParams([],"totalChecked")
            state.totalChecked = []
        },
        setPercentages: (state, action: PayloadAction<any>) => {
            if(Array.isArray(action.payload)){
                state.percentages = action.payload
            }else{
                state.blocks[action.payload.blockId].percentages = action.payload.data
            }
        },
        setFiltersTable: (state, action: PayloadAction<any>) => {
            if (action.payload && typeof action.payload === 'object') {
                if (!Object.hasOwnProperty.call(action.payload, "blockId")) {
                    state.filtersTable = action.payload;
                } else {
                    state.blocks[action.payload.blockId].filtersTable = action.payload.data;
                }
            } else {
                console.error("Ошибка: action.payload не является объектом или равен null/undefined");
            }
        },
        setSortTable: (state, action: PayloadAction<any>) => {
            if(Object.hasOwn(action.payload,"blockId") && action.payload?.blockId){
                state.blocks[action.payload.blockId].sortTable =  action.payload.data
            }else{
                updateQueryParams(action.payload,"sortTable")
                state.sortTable = Object.hasOwn(action.payload,"blockId") ? action.payload.data : action.payload
            }
        },
        setGroupsLimit: (state, action: PayloadAction<boolean>) => {
            state.groupsLimit = action.payload
        },
        setMetricsLimit: (state, action: PayloadAction<boolean>) => {
            state.metricsLimit = action.payload
        },
        setMetricsFiltersUpdate: (state, action: PayloadAction<boolean>) => {
            state.metrics_filters_update = action.payload
        },
        setTableData: (state, action: PayloadAction<any>) => {
            const { blockId } = action.payload
            if(blockId){
                state.blocks[blockId].tableData.data = prepareRows({ rows: action.payload.rows, groupings: action.payload.groupings })
                state.blocks[blockId].tableData.total = action.payload.total
            }else{
                state.tableData.data = prepareRows({ rows: action.payload.rows, groupings: action.payload.groupings })
                state.tableData.total = action.payload.total
            }

        },
        setTableChildrenData: (state, action: PayloadAction<any>) => {
            const { loadCurrent, data, groupings,blockId } = action.payload
            const recurseChildren = (rows: any) => {
                for (let index = 0; index < rows.length; index++) {
                    const row = rows[index]
                    if (row.children && row.children.length > 0) {
                        recurseChildren(row.children)
                    }
                    if (row.pathStr === loadCurrent.pathStr) {
                        row.children = prepareRows({ rows: data.rows, parent: loadCurrent, groupings })
                        row.clientData.downloaded = true
                        row.clientData.loading = false
                    }
                }
                return rows
            }
            if(blockId){
                state.blocks[blockId].tableData.data = recurseChildren(state.blocks[blockId].tableData.data)
            }else{
                state.tableData.data = recurseChildren(state.tableData.data)
            }
        },

        setReportTableData: (state, action: PayloadAction<any>) => {
            state.tableReportData.data = prepareRows({ rows: action.payload.rows, groupings: action.payload.groupings })
            state.tableReportData.total = action.payload.total
        },
        setReportClearTableData: (state) => {
            state.tableReportData.data = []
            state.tableReportData.total = {}
        },
        setReportMetrics: (state, action: PayloadAction<any>) => {
            state.metricsReport = action.payload
        },
        setReportMetricsChecked: (state, action: PayloadAction<any>) => {
            state.metricsReportChecked = action.payload
        },
    }
})

export const tableSliceActions = tableSlice.actions
export const tableSliceReducer = tableSlice.reducer
