import React, { useEffect, useState } from "react";

import {
  ClearOutlined,
  CloseOutlined,
  DownOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Modal, Space, Tooltip, Tree } from "antd";
import { ReactSortable } from "react-sortablejs";

import { getCookie } from "../../../../../helpers/utils/cookies";
import { createMetricsReportsNames } from "../../../../../helpers/utils/data";
import {
  getMetricsParentKey,
  loopSearch,
  prepareData,
} from "../../../../../helpers/utils/reports";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../helpers/utils/ui";
import { unApi } from "../../../../../shared/common/api/endpoints/reports/unApi";
import CreateMetricsModal, {
  staticMetrics,
} from "../../../../../shared/common/components/reports/CreateMetricsModal";
import EditMetricsModal from "../../../../../shared/common/components/reports/EditMetricsModal";
import MetricItem from "../../../../../shared/common/components/reports/MetricItem";
import { listKeysJsonUniversal } from "../../../../../shared/common/constants/metrics";
import { useActions } from "../../../../../shared/common/hooks/useActions";
import { useTypedSelector } from "../../../../../shared/common/hooks/useTypedSelector";
import { useForceUpdate } from "../../../../../shared/common/hooks/useForceUpdate";
import { useButtonContext } from "../../../../../shared/common/components/reports/dashboards/components/RemoveMetric";
type Props = {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  title: string;
  selected: any[];
  handleListChange: (newState: any[]) => void;
  setSelected: React.Dispatch<React.SetStateAction<any[]>>;
  dataItems: any[];
  listKeys: any[];
};
const ModalSettings = ({
  isVisible,
  setVisible,
  title,
  selected,
  handleListChange,
  setSelected,
  dataItems,
  listKeys,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");

  const [editMetricsState, setEditMetricsState] = useState<{
    visible: boolean;
    id: null | number;
    type: string | null;
  }>({
    visible: false,
    id: null,
    type: null,
  });
  const { setIsNotParamsFilterVisible, setIsCreateMetricsVisible, setMetricsChecked } =
    useActions();
  const { isCreateMetricsVisible } = useTypedSelector((state) => state.reports);
  const [list, setList] = useState<any[]>(selected);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState(selected?.map((el) => el.key));
  const [data, setData] = useState(dataItems);
  const { metricsChecked } = useTypedSelector((state) => state.table);

  const [isDisabledSelected, setisDisabledSelected] = useState(false);
  const forceUpdate = useForceUpdate();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const isNumberDisplay = getCookie("chosenDisplay") === "number";
  useEffect(() => {
    setIsNotParamsFilterVisible(isCreateMetricsVisible);
  }, [isCreateMetricsVisible]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value !== "") {
      const reg = new RegExp(value, "i");
      const _expandedKeys: any = listKeys
        .map((item: any) => {
          if (item.name.search(reg) > -1) {
            return getMetricsParentKey(item.key, dataItems);
          }
          return null;
        })
        .filter(
          (item: any, i: number, self: any) => item && self.indexOf(item) === i
        );
      setExpandedKeys(_expandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    } else {
      setExpandedKeys([]);
      setSearchValue("");
      setAutoExpandParent(false);
    }
  };

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);

  };

  const onCheck = (_checkedKeys: any, e: any) => {
    if (isNumberDisplay && title === "Метрики") {
      const latestIndex = _checkedKeys?.length - 1;
      setCheckedKeys([_checkedKeys[latestIndex]]);
    
      return;
    }
    setCheckedKeys(_checkedKeys);
    if (_checkedKeys.length > 0) {
      if (e.checked) {
        // setSelected((prev)=>[...prev,e.node])
        setList((prev) => [...prev, e.node]);
      } else {
        setList((prev) =>
          prev.filter((el: { key: string }) => el.key !== e.node.key)
        );
      }
    } else {
      setList([]);
    }
   
  };
  const handleRemove = (key: string, label: string) => {
    console.log("Удаляем элемент с ключом:", key, "и меткой:", label);

    // Удаляем из list
    setList((prev) => prev.filter((el: { key: string }) => el.key !== key));

    // Удаляем из checkedKeys
    setCheckedKeys((prev) => prev.filter((el) => el !== key));


  };


  const confirmCreatedMetricsRemoving = async (nodeData: any) => {

    try {
      await unApi.removeCustomMetric(nodeData.id, location.pathname);
      const removeMetric = (prev: any) => {
        const updatedData = [...prev];
        const newCreatedMetrics = updatedData[updatedData.length - 1].children;
        updatedData[updatedData.length - 1].children = newCreatedMetrics.filter(
          (el: any) => el.dataKey !== nodeData.dataKey
        );
        return updatedData;
      };
      setData(removeMetric);
      setSelected((prev: any) => {
        return prev.filter((el: any) => el.dataKey !== nodeData.dataKey);
      });
      setList((prev: any) => {
        return prev.filter((el: any) => el.dataKey !== nodeData.dataKey);
      });
      // if(selectedSchool?.id){
      //     setTimeout(()=>getCreatedMetrics(selectedSchool.id),150)
      // }
      setEditMetricsState({ id: null, visible: false, type: null });
      showSuccessMessage("Метрика успешно удалена.");
    } catch (err) {
      showErrorMessage("Произошла ошибка во время удаления метрики.");
      console.log(err);
    }
    const { isSecondButtonActive, toggleSecondButton } = useButtonContext();
  };
  const handleEditMetric = (nodeData: any) => {
    setEditMetricsState({ visible: true, id: nodeData.id, type: null });
  };
  const onCancel = () => {
    setVisible(false);
  };

  const onOk = () => {
    setSelected(list);
    isNumberDisplay && handleListChange(list);
    setVisible(false);
  };
  const clearCheckboxes = () => {
    setSelected([]);
    setCheckedKeys([]);
    !isDisabledSelected && setisDisabledSelected(true);
    forceUpdate();
  };
  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={onCancel}
      width={"60%"}
      onOk={onOk}
      okText="Применить"
      className="modal-settings"
      destroyOnClose
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
        <Col span={13}>
          <Input
            style={{ marginBottom: 8, marginTop: "8px" }}
            placeholder={"Поиск " + "метрики"}
            onChange={onChange}
            allowClear
          />
          <Tree.DirectoryTree
            onExpand={onExpand}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            blockNode
            checkable
            switcherIcon={<DownOutlined />}
            showIcon={false}
            selectable={false}
            treeData={loopSearch(data, searchValue)}
            titleRender={(nodeData: any) => {
              return nodeData.disabled ? (
                <Tooltip
                  placement="top"
                  color="#fff"
                  zIndex={9999}
                  overlayClassName="page-title-tooltip"
                >
                  {nodeData.title}
                </Tooltip>
              ) : (
                <>
                  {nodeData.description ? (
                    <>
                      {nodeData.disableCheckbox ? (
                        <Tooltip
                          placement="top"
                          title={
                            <div className="page-title-tooltip-text">
                              Контактные данные пользователей не доступны.
                              Обратитесь к администратору, чтобы получить к ним
                              доступ.
                            </div>
                          }
                          color="#fff"
                          zIndex={9999}
                          overlayClassName="page-title-tooltip"
                        >
                          <span className="group-item-icon-wrapper">
                            {nodeData.title}
                            <span style={{ marginRight: 10, marginLeft: 5 }}>
                              <Tooltip
                                placement="bottom"
                                title={
                                  <div
                                    className="page-title-tooltip-text"
                                    dangerouslySetInnerHTML={{
                                      __html: nodeData.description,
                                    }}
                                  ></div>
                                }
                                color="#fff"
                                zIndex={9999}
                                overlayClassName="page-title-tooltip"
                              >
                                <QuestionCircleOutlined
                                  style={{ color: "#ccc", fontSize: 16 }}
                                />
                              </Tooltip>
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <MetricItem
                          nodeData={nodeData}
                          handleEditMetric={handleEditMetric}
                          confirmCreatedMetricsRemoving={
                            confirmCreatedMetricsRemoving
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {nodeData.disableCheckbox ? (
                        <Tooltip
                          placement="top"
                          title={
                            <div className="page-title-tooltip-text">
                              Контактные данные пользователей не доступны.
                              Обратитесь к администратору, чтобы получить к ним
                              доступ.
                            </div>
                          }
                          color="#fff"
                          zIndex={9999}
                          overlayClassName="page-title-tooltip"
                        >
                          <span>{nodeData.title}</span>
                        </Tooltip>
                      ) : (
                        <MetricItem
                          nodeData={nodeData}
                          handleEditMetric={handleEditMetric}
                          confirmCreatedMetricsRemoving={
                            confirmCreatedMetricsRemoving
                          }
                        />
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
          <Space style={{ marginTop: "20px" }}>
            {title === "Метрики" && (
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => setIsCreateMetricsVisible(true)}
              >
                Создать метрику
              </Button>
            )}
            <div>
              <Button
                type="default"
                icon={<ClearOutlined />}
                onClick={() => clearCheckboxes()}
              >
                Очистить выбор
              </Button>
            </div>
          </Space>
        </Col>
        <Col span={11} style={{ paddingTop: "6px" }}>
          <ReactSortable
            list={list.map((item: any) => ({ ...item, chosen: true }))}
            className="modalSortable__container"
            setList={(newState) => {
              setList(newState);
            }}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
          >
             
            {list.length > 0 &&
              list.map((item: any) => (
                <div className="drag-item" key={item.key}>
                  <span className="drag-handler">
                    <EllipsisOutlined rotate={90} />
                    <span
                      className="drag-label"
                      style={{
                        backgroundColor: item?.background ? item.background : "unset",
                        width: "100%",
                      }}
                    >
                      {item.label || item.name} {/* Здесь выводим лейбл или имя */}
                    </span>
                  </span>
                  <div
                    onClick={() => {
                  

                  
                      if (metricsChecked && Array.isArray(metricsChecked)) {
                        const metricToRemove = metricsChecked.find((metric) => metric.label === item.label);
                        if (metricToRemove) {
                       
                          const updatedMetrics = metricsChecked.filter((metric) => metric.label !== item.label);
                          setMetricsChecked({ data: updatedMetrics });
                        } else {
                          console.log("Метрика с таким лейблом не найдена.");
                        }
                      } else {
                       <div></div>
                      }
                      handleRemove(item.key, item.label); // Передаем и key, и label
                    }}
                    className="drag-remove"
                  >
                    <CloseOutlined />
                  </div>

                </div>
              ))}





          </ReactSortable>
        </Col>
      </div>
     
      {isCreateMetricsVisible && (
        <CreateMetricsModal
          title={
            createMetricsReportsNames[
            (getCookie("dashboardPathname") ||
              location.pathname) as keyof typeof staticMetrics
            ]
          }
        />
      )}

      {editMetricsState.visible && (
        <EditMetricsModal
          confirmCreatedMetricsRemoving={confirmCreatedMetricsRemoving}
          id={editMetricsState.id}
          visible={editMetricsState.visible}
          setEditState={setEditMetricsState}
        />
      )}
    </Modal>
    
  );
};
export default ModalSettings;
