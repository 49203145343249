import { FC, useState } from "react"

import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons"
import { Button, Input } from "antd"
import { Link, useNavigate } from "react-router-dom"

import { ReportMenu } from "./reportMenu/reportMenu"
import { Tag } from "../../../../../app/providers/redux/slices/reports/params/reports/types"
import {
  parseTableData,
  handleReportPeriod,
} from "../../../../../helpers/utils/reports"
import { routeNames } from "../../../constants/routes"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import styles from "../styles/TabContent.module.scss"
import { TabType } from "../types"
import { filterByParams } from "../utils/filter-reports"
import { getLinkPathMenu } from "../../../../../helpers/utils/functions"

export type ContentTab = "all" | "my" | "schools" | "scan";

export type TabContentProps = {
  mainTab:
    | "dashboards"
    | "reports"
    | "overview"
    | "ads"
    | "bd"
    | "access"
    | "tools"
    | "settings"
    | "import"
    | "suppliers"
    | "education";
  subTab: "all" | "favorites";
  activeContentTab: ContentTab;
  onContentTabChange: (key: "all" | "my" | "schools" | "scan") => void;
  data: Array<{
    id: number;
    name: string;
    type: string;
    is_favorite: boolean;
    category?: string;
    tags?: any;
    values?: any;
    report_id: any;
    hash: any;
  }>;
  activeReportTab?:
    | "registrations"
    | "orders"
    | "payments"
    | "webinars"
    | "ads"
    | "bd"
    | "access"
    | "tools"
    | "settings"
    | "import"
    | "suppliers"
    | "education";
  activeTagTab?: "manager" | "traffic" | "funnels" | "contractors";
  activeTab?: TabType;
  onItemClick?: () => void;
  tags: {
    dashboards: Tag[];
    reports: Tag[];
  };
  staticTags?: Tag[];
  activeMainTab?:
    | "overview"
    | "dashboards"
    | "reports"
    | "ads"
    | "bd"
    | "access"
    | "tools"
    | "settings"
    | "import"
    | "suppliers"
    | "education";
};

export type ItemTypee = {
  id: any;
  name: string;
  type: string;
  access_id: number;
  is_favorite: boolean;
  category?: string;
  tags?: Array<{ id: number; name: string }>;
  values?: any;
  report_id?: any;
  hash?: any;
};

const getLinkStyle = (isHover: boolean) => ({
  fontSize: "20px",
  padding: "10px 20px ",
  paddingBottom: "10px",
  cursor: "pointer",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: isHover ? "0px 2px 5px -5px rgba(34, 60, 80, 0.6)" : "none",
  background: isHover ? "#f5f5f5" : "none",
  borderTopLeftRadius: "30px",
  borderBottomLeftRadius: "30px",
})

const TabContent: FC<TabContentProps> = ({
  mainTab,
  subTab,
  activeContentTab,
  onContentTabChange,
  data,
  onItemClick,
  activeTab,
  tags,
}) => {
  const navigate = useNavigate()
  const [ hoveredItem, setHoveredItem ] = useState<number | null>(null)
  const [ searchQuery, setSearchQuery ] = useState("")
  const [ sortOrder, setSortOrder ] = useState<"asc" | "desc">("asc")

  const { savedReports, savedDashboards, userAccess, role } = useTypedSelector(
    (state) => ({ 
		savedReports: state.reports.savedReports,
		savedDashboards: state.reports.savedDashboards,
		userAccess: state.currentSchool.userAccess,
		role: state.currentUser.role
	})
  )

  const {
    setDetails,
    setChartType,
    setGroupings,
    setMetrics,
    setGroupingsChecked,
    setMetricsChecked,
    setFiltersTable,
    setSortTable,
    setPeriod,
    setCurrentReport,
    setMetricsFiltersUpdate,
    setCurrentSegment,
    setAttributes,
    setSegmentId,
    setMetaTableFetching,
    getCurrentDashboard,
  } = useActions()

  const tabButtons = [
    { key: "all", label: "Все" },
    { key: "my", label: "Мои" },
    { key: "schools", label: "Школы" },
    { key: "scan", label: "SCAN" },
  ]

  const handleTabChange = (activeKey: string) => {
    onContentTabChange(activeKey as "all" | "my" | "schools" | "scan")
  }

  const handleSort = () => {
    setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"))
  }

  const currentData = mainTab === "reports" ? savedReports : savedDashboards
  const getFilteredData = (contentTab: ContentTab) => {
    return currentData
      .filter((item) =>
        filterByParams(
          item,
          activeTab,
          subTab,
          mainTab,
          tags,
          contentTab,
          searchQuery
        )
      )
      .sort((a, b) =>
        sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      )
  }

  const handleItemClick = (item: ItemTypee) => {
    const report = data.find((rep: any) => rep.id === item.id)
    if (!report) return
    setCurrentReport(report)

    const handledPeriod = handleReportPeriod(report.values.period)
    setPeriod({
      start: handledPeriod.start,
      end: handledPeriod.end,
      interval: handledPeriod.interval,
      select: "",
    })

    const {
      details,
      chartType,
      groupings,
      metrics,
      groupsChecked,
      metricsChecked,
      filtersTable,
      sortTable,
      filtersReq,
      segment,
    } = report.values

    setDetails(details)
    setChartType(chartType)
    setGroupings({ data: groupings })
    setMetrics({ data: parseTableData(metrics) })
    setFiltersTable(filtersTable)
    setSortTable(sortTable)
    setAttributes(filtersReq)

    const isSegment = segment && Object.keys(segment).length > 0
    setCurrentSegment(isSegment ? segment : null)
    setSegmentId(isSegment ? segment.id : null)

    setGroupingsChecked(groupsChecked)
    setMetricsChecked({ data: metricsChecked })
    setMetaTableFetching({ value: true })
    setMetricsFiltersUpdate(true)

    navigate(`${getRouteByReportId(item.report_id)}`, {
      state: { report_id: item.hash },
    })

    if (onItemClick) onItemClick()
  }


  const activateReport = async (id: string) => {
    getCurrentDashboard(id)
	const path = getLinkPathMenu(routeNames.dashboards, userAccess, role)
	navigate(path)

    if (onItemClick) {
      onItemClick()
    }
  }

  const getRouteByReportId = (reportId: number) => {
    switch (reportId) {
      case 1:
        return routeNames.home
      case 2:
        return routeNames.orderReports
      case 3:
        return routeNames.paymentReports
      case 4:
        return routeNames.adReports
      case 5:
        return routeNames.webinarReports
      default:
        return routeNames.home
    }
  }

  const renderBdLinks = () => {
    switch (activeTab) {
      case "platform":
        return (
          <>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportUsers}>Пользователи</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportRegs}>Регистрации</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportOrders}>Заказы</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportPayments}>Платежи</Link>
            </div>
          </>
        )
      case "webinars":
        return (
          <>
            <div className={styles.linkItem}>
              <Link to={routeNames.webinarList}>Список вебинаров</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportWebinarComments}>Комментарии</Link>
            </div>
          </>
        )
      case "visits":
        return (
          <>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportSession}>Сессии</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportHit}>Хиты</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportVisitors}>Посетители</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.reportHitStat}>Статистика по экранам</Link>
            </div>
          </>
        )
      case "ads":
        return (
          <>
            <div className={styles.linkItem}>
              <Link to={routeNames.adsConnect}>
                Кабинеты автоматического размещения
              </Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={`${routeNames.adsConnect}?tab=2`}>
                Объявления автоматического размещения
              </Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.adsSpend}>Кабинеты ручного размещения</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={`${routeNames.adsSpend}?tab=2`}>
                Объявления ручного размещения
              </Link>
            </div>
          </>
        )
      default:
        "platform"
        return null
    }
  }
  const renderToolsLinks = () => {
    switch (activeTab) {
      case "integrations":
        return (
          <>
            <div className={styles.linkItem}>
              <Link to={routeNames.schoolAdd}>GetCourse</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.adsConnect}>Рекламные кабинеты</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={`${routeNames.yandexMetrics}?tab=2`}>
                Яндекс метрика
              </Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={routeNames.webinarConnectBizon}>Бизон 365</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={""}>Амо CRM (скоро)</Link>
            </div>
            <div className={styles.linkItem}>
              <Link to={""}>Гугл таблица (скоро)</Link>
            </div>
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className={styles["tab-content"]}>
      {mainTab === "bd" ? (
        <div>{renderBdLinks()}</div>
      ) : mainTab === "settings" ? (
        <div>{renderToolsLinks()}</div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              gap: "8px",
              paddingBottom: "16px",
              paddingRight: "15px",
            }}
          >
            {tabButtons.map(({ key, label }) => (
              <Button
                key={key}
                type={activeContentTab === key ? "primary" : "default"}
                onClick={() => handleTabChange(key)}
                style={{
                  width: "100px",
                  borderRadius: "15px",
                  fontWeight: activeContentTab === key ? "600" : "400",
                  background: activeContentTab === key ? "#1890ff" : "#fff",
                  color:
                    activeContentTab === key ? "#fff" : "rgba(0, 0, 0, 0.85)",
                  borderColor: activeContentTab === key ? "#1890ff" : "#d9d9d9",
                }}
              >
                {label}
              </Button>
            ))}
          </div>
          <div>
            <div style={{ display: "flex", gap: "8px", paddingRight: "15px" }}>
              <Input
                placeholder="Поиск..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  marginBottom: "16px",
                  borderRadius: "5px",
                  height: "50px",
                }}
              />
              <Button
                onClick={handleSort}
                icon={
                  sortOrder === "asc" ? (
                    <SortDescendingOutlined style={{ fontSize: "30px" }} />
                  ) : (
                    <SortAscendingOutlined style={{ fontSize: "30px" }} />
                  )
                }
                style={{
                  marginTop: "5px",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              />
            </div>
            {getFilteredData(activeContentTab).map((item) => {
              return (
                <div
                  key={item.id}
                  style={getLinkStyle(hoveredItem === item.id)}
                  onMouseEnter={() => setHoveredItem(item.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <div
					style={{ flexBasis: "100%"}}
                    onClick={() => {
                      if (mainTab === "reports") {
                        handleItemClick(item)
                      } else if (mainTab === "dashboards") {
                        activateReport(item.id.toString())
                      }
                    }}
                  >
                    {item.name} {item.is_favorite && "★"}
                  </div>
                  <ReportMenu
                    itemId={item.id}
                    isDashboard={mainTab === "dashboards"}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default TabContent
