import axios, { AxiosResponse } from "axios";

import {
  addMetric,
  AddPayment,
  AddRK,
  ConnectReferals,
  CreateYandexTask,
  EditAccural,
  EditMetric,
  EditYandexTask,
  GetRK,
  PaymentAdd,
  PaymentUpdate,
  SchoolUpdate,
  SendToProcessing,
  TariffAdd,
  UpdateOrder,
  UpdatePartner,
  UpdatePayment,
  UpdateReferal,
  UpdateRK,
  UserUpdate,
} from "../../../../../app/types/unApi";
import { axiosConfigAuth } from "../../middleware";
import {
  API_ADS_ADD_MANUAL_RK,
  API_ADS_ADD_RK_MANUAL,
  API_ADS_GET_DATA,
  API_ADS_GET_RK,
  API_ADS_GET_RK_LIST,
  API_ADS_UPDATE_RK_NAME,
  API_ATTACH_TAGS,
  API_CRM_SCHOOL_INFO,
  API_CRM_SCHOOL_UPDATE,
  API_CRM_TARIFF_ADD,
  API_DASHBOARDS_GET_BY_ID,
  API_DASHBOARDS_GET_SAVED,
  API_DELETE_TAGS,
  API_DETACH_TAGS,
  API_GET_EXPORTS,
  API_GET_FILTER_LIST,
  API_GET_FILTER_LIST_EXTRA,
  API_GET_TAGS,
  API_GET_USER_INFO,
  API_ORDER_ADD,
  API_ORDER_INFO,
  API_ORDER_UPDATE,
  API_ORDERS_STATUS_UPDATE,
  API_PAYMENT_ADD,
  API_PAYMENT_INFO,
  API_PAYMENT_UPDATE,
  API_REFERAL_ADD_PAYMENT,
  API_REFERAL_CONNECT,
  API_REFERAL_DELETE,
  API_REFERAL_DELETE_ACCURAL,
  API_REFERAL_GET_INFO,
  API_REFERAL_GET_TABLE_PARTNER,
  API_REFERAL_TABLE_ACCURALS,
  API_REFERAL_TABLE_DELETE_PAYMENT,
  API_REFERAL_TABLE_GET_PAYMENT,
  API_REFERAL_TABLE_PARTNERS,
  API_REFERAL_TABLE_PAYMENTS,
  API_REFERAL_TABLE_REFERALS,
  API_REFERAL_TABLE_UPDATE,
  API_REFERAL_TABLE_UPDATE_ACCURAL,
  API_REFERAL_TABLE_UPDATE_COMMISSION,
  API_REFERAL_TABLE_UPDATE_PARTNER,
  API_REFERAL_TABLE_UPDATE_PAYMENT,
  API_REGS_MASS_REMOVING,
  API_REGS_PROCESSING,
  API_RENAME_TAGS,
  API_REPORT_ADD_FAVORITE,
  API_REPORT_GET_EXTRA_FIELDS,
  API_REPORT_REMOVE_FAVORITE,
  API_REPORTS_CREATE_CUSTOM_METRIC,
  API_REPORTS_EDIT_CUSTOM_METRIC,
  API_REPORTS_GET_CUSTOM_METRIC,
  API_REPORTS_GET_CUSTOM_METRICS,
  API_REPORTS_REMOVE_CUSTOM_METRIC,
  API_REQ_EXPORT_TABLE,
  API_TABLE_ALL_DATA,
  API_TAG_ADD,
  API_UN_REPORT_CHART,
  API_UN_REPORT_DELETE_REPORT,
  API_UN_REPORT_GET_REPORT_DATA,
  API_UN_REPORT_GET_REPORTS,
  API_UN_REPORT_GET_SAVED_REPORTS,
  API_UN_REPORT_SET_REPORT,
  API_UN_REPORT_TABLE,
  API_UN_REPORT_UPDATE_REPORT,
  API_UN_SEGMENT_GET_SEGMENT_DATA,
  API_UPDATE_USER_INFO,
  API_UPLOAD_EXCEL_SOURCES,
  API_WEBINAR_REPORT_CHART,
  API_YANDEX_ADD_GOAL,
  API_YANDEX_ADD_TASK,
  API_YANDEX_GET_GOALS,
  API_YANDEX_GET_METRICS,
  API_YANDEX_GET_TASK,
  API_YANDEX_GET_TASKS,
  API_YANDEX_GET_TASKS_DATA,
  API_YANDEX_GET_TASKS_OBJECTS,
  API_YANDEX_REMOVE_TASK,
  API_YANDEX_TASK_EDIT,
} from "../../urls";
import JSONbig from "json-bigint";
export const unApi = {
  getAllDataTable: (school_id: number): Promise<AxiosResponse> => {
    return axios.get(`${API_TABLE_ALL_DATA}/${school_id}`);
  },
  getExtraFields: (type: number): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORT_GET_EXTRA_FIELDS}/${type}`,
      axiosConfigAuth()
    );
  },
  updateOrderStatus: (data: UpdateOrder): Promise<AxiosResponse> => {
    return axios.post(API_ORDERS_STATUS_UPDATE, data, axiosConfigAuth());
  },
  sendToProcessing: (data: SendToProcessing): Promise<AxiosResponse> => {
    return axios.post(API_REGS_PROCESSING, data, axiosConfigAuth());
  },
  massRemoving: (data: SendToProcessing): Promise<AxiosResponse> => {
    return axios.post(API_REGS_MASS_REMOVING, data, axiosConfigAuth());
  },
  updateTableCommission: (payment_id: number): Promise<AxiosResponse> => {
    const data = {
      payment_id,
    };
    return axios.post(
      API_REFERAL_TABLE_UPDATE_COMMISSION,
      data,
      axiosConfigAuth()
    );
  },
  updateTablePartner: (data: UpdatePartner): Promise<AxiosResponse> => {
    return axios.post(
      API_REFERAL_TABLE_UPDATE_PARTNER,
      data,
      axiosConfigAuth()
    );
  },
  getTablePartnerInfo: (partner_id: number): Promise<AxiosResponse> => {
    const data = {
      partner_id,
    };
    return axios.post(API_REFERAL_GET_TABLE_PARTNER, data, axiosConfigAuth());
  },
  updateTableReferal: (data: UpdateReferal): Promise<AxiosResponse> => {
    return axios.post(API_REFERAL_TABLE_UPDATE, data, axiosConfigAuth());
  },
  getTableReferalInfo: (id: {
    referal_id: number;
    partner_id: number;
  }): Promise<AxiosResponse> => {
    const data = {
      ...id,
    };
    return axios.post(API_REFERAL_GET_INFO, data, axiosConfigAuth());
  },
  deleteTableReferal: (referal_id: number): Promise<AxiosResponse> => {
    const data = {
      referal_id,
    };
    return axios.post(API_REFERAL_DELETE, data, axiosConfigAuth());
  },
  updateTablePayment: (data: UpdatePayment): Promise<AxiosResponse> => {
    return axios.post(
      API_REFERAL_TABLE_UPDATE_PAYMENT,
      data,
      axiosConfigAuth()
    );
  },
  getTablePaymentInfo: (payment_id: number): Promise<AxiosResponse> => {
    const data = {
      payment_id,
    };
    return axios.post(API_REFERAL_TABLE_GET_PAYMENT, data, axiosConfigAuth());
  },
  deleteTablePayment: (payment_id: number): Promise<AxiosResponse> => {
    const data = {
      payment_id,
    };
    return axios.post(
      API_REFERAL_TABLE_DELETE_PAYMENT,
      data,
      axiosConfigAuth()
    );
  },
  editTableAccurals: (data: EditAccural): Promise<AxiosResponse> => {
    return axios.post(
      API_REFERAL_TABLE_UPDATE_ACCURAL,
      data,
      axiosConfigAuth()
    );
  },
  deleteTableAccurals: (calculation_id: number): Promise<AxiosResponse> => {
    const data = {
      calculation_id,
    };
    return axios.post(API_REFERAL_DELETE_ACCURAL, data, axiosConfigAuth());
  },
  addPaymentTable: (data: AddPayment): Promise<AxiosResponse> => {
    return axios.post(API_REFERAL_ADD_PAYMENT, data, axiosConfigAuth());
  },
  getTablePartners: (): Promise<AxiosResponse> => {
    return axios.get(API_REFERAL_TABLE_PARTNERS, axiosConfigAuth());
  },
  getTablePayments: (): Promise<AxiosResponse> => {
    return axios.get(API_REFERAL_TABLE_PAYMENTS, axiosConfigAuth());
  },
  getTableAccurals: (): Promise<AxiosResponse> => {
    return axios.get(API_REFERAL_TABLE_ACCURALS, axiosConfigAuth());
  },
  getTableReferals: (): Promise<AxiosResponse> => {
    return axios.get(API_REFERAL_TABLE_REFERALS, axiosConfigAuth());
  },
  connectReferals: (data: ConnectReferals): Promise<AxiosResponse> => {
    return axios.post(API_REFERAL_CONNECT, data, axiosConfigAuth());
  },
  updateUserInfo: (data: UserUpdate): Promise<AxiosResponse> => {
    return axios.post(API_UPDATE_USER_INFO, data, axiosConfigAuth());
  },
  getUserInfo: (user_id: number): Promise<AxiosResponse> => {
    const data = {
      user_id,
    };
    return axios.post(API_GET_USER_INFO, data, axiosConfigAuth());
  },
  uploadExcelSources: (data: any): Promise<AxiosResponse> => {
    return axios.post(API_UPLOAD_EXCEL_SOURCES, data, axiosConfigAuth());
  },
  getRKList: (values: any): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ADS_GET_RK_LIST, data, axiosConfigAuth());
  },
  updateRK: (values: UpdateRK): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ADS_UPDATE_RK_NAME, data, axiosConfigAuth());
  },
  manualAddRK: (values: AddRK): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ADS_ADD_MANUAL_RK, data, axiosConfigAuth());
  },
  addRK: (values: AddRK): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ADS_ADD_RK_MANUAL, data, axiosConfigAuth());
  },
  getRK: (values: GetRK): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ADS_GET_DATA, data, axiosConfigAuth());
  },
  getYandexMetrics: (schoolId: number): Promise<AxiosResponse> => {
    return axios.get(
      `${API_YANDEX_GET_METRICS}/${schoolId}`,
      axiosConfigAuth()
    );
  },
  getYandexTasks: (schoolId: number): Promise<AxiosResponse> => {
    return axios.get(
      `${API_YANDEX_GET_TASKS_DATA}/${schoolId}`,
      axiosConfigAuth()
    );
  },
  removeYandexTask: (taskId: number): Promise<AxiosResponse> => {
    return axios.get(`${API_YANDEX_REMOVE_TASK}/${taskId}`, axiosConfigAuth());
  },
  getTaskTypes: (): Promise<AxiosResponse> => {
    return axios.get(`${API_YANDEX_GET_TASKS}`, axiosConfigAuth());
  },
  getTaskById: (taskId: number): Promise<AxiosResponse> => {
    return axios.get(`${API_YANDEX_GET_TASK}/${taskId}`, axiosConfigAuth());
  },
  addYandexGoal: (values: {
    counter_id: number;
    gools_name: string;
  }): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_YANDEX_ADD_GOAL, data, axiosConfigAuth());
  },
  addYandexTask: (values: CreateYandexTask): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_YANDEX_ADD_TASK, data, axiosConfigAuth());
  },
  editYandexTask: (values: EditYandexTask): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_YANDEX_TASK_EDIT, data, axiosConfigAuth());
  },
  getTaskObjects: (): Promise<AxiosResponse> => {
    return axios.get(`${API_YANDEX_GET_TASKS_OBJECTS}`, axiosConfigAuth());
  },
  getGoals: (counterId: number): Promise<AxiosResponse> => {
    return axios.get(`${API_YANDEX_GET_GOALS}/${counterId}`, axiosConfigAuth());
  },
  addTariff: (values: TariffAdd): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_CRM_TARIFF_ADD, data, axiosConfigAuth());
  },
  updateSchoolInfo: (values: SchoolUpdate): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_CRM_SCHOOL_UPDATE, data, axiosConfigAuth());
  },
  getSchoolInfo: (school_id: number): Promise<AxiosResponse> => {
    const data = {
      school_id,
    };
    return axios.post(API_CRM_SCHOOL_INFO, data, axiosConfigAuth());
  },
  updatePayment: (values: PaymentUpdate): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_PAYMENT_UPDATE, data, axiosConfigAuth());
  },
  addPayment: (values: PaymentAdd): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_PAYMENT_ADD, data, axiosConfigAuth());
  },
  getPaymentInfo: (payment_id: number): Promise<AxiosResponse> => {
    const data = {
      payment_id,
    };
    return axios.post(API_PAYMENT_INFO, data, axiosConfigAuth());
  },
  updateOrderInfo: (values: {
    order_id: number;
    order_status: number;
  }): Promise<AxiosResponse> => {
    const data = {
      ...values,
    };
    return axios.post(API_ORDER_UPDATE, data, axiosConfigAuth());
  },
  getOrderInfo: (order_id: number): Promise<AxiosResponse> => {
    const data = {
      order_id,
    };
    return axios.post(API_ORDER_INFO, data, axiosConfigAuth());
  },
  addOrder: (
    school_id?: number,
    tariff_id?: number,
    body?: { school_id: number; tariff_id: number; manual: number }
  ): Promise<AxiosResponse> => {
    const data = {
      school_id,
      tariff_id,
    };
    if (body) {
      return axios.post(API_ORDER_ADD, body, axiosConfigAuth());
    } else {
      return axios.post(API_ORDER_ADD, data, axiosConfigAuth());
    }
  },

  getFilterList: (
    schoolId: number,
    filterId: string,
    str?: string
  ): Promise<AxiosResponse> => {
    const isExtraFields = filterId.includes("extra");
    const data = {
      school_id: schoolId,
      [isExtraFields ? "id" : "name"]: filterId,
      str: str || "",
    };

    return axios.post(
      isExtraFields ? API_GET_FILTER_LIST_EXTRA : API_GET_FILTER_LIST,
      data,
      axiosConfigAuth()
    );
  },

  getReports: (
    schoolId: string | number,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_UN_REPORT_GET_REPORTS}/${schoolId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },

  getSavedReports: (
    schoolId: string | number,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_UN_REPORT_GET_SAVED_REPORTS}/${schoolId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },


  setFavorite: (
    id: string | number,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORT_ADD_FAVORITE}/${id}`,
      axiosConfigAuth({ Pathname: pathName })
    )
  },

  removeFavorite: (
    id: string | number,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORT_REMOVE_FAVORITE}/${id}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },


  getDashboards: (
    schoolId: string | number,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_DASHBOARDS_GET_SAVED}/${schoolId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  getCurrentDashboard: (id: string): Promise<AxiosResponse> => {
    return axios.get(
      `${API_DASHBOARDS_GET_BY_ID}/${id}`,
      axiosConfigAuth({ Pathname: location.pathname })
    );
  },

  createReport: (
    schoolId: string | number,
    values: any,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      API_UN_REPORT_SET_REPORT,
      { school_id: schoolId, values },
      axiosConfigAuth({ Pathname: pathName })
    );
  },

  editReport: (
    schoolId: string | number,
    id: string,
    values: any,
    pathName: string, // Обязательный параметр
    access_id?: any   // Необязательный параметр теперь в конце
  ): Promise<AxiosResponse> => {
    return axios.post(
      API_UN_REPORT_UPDATE_REPORT,
      { school_id: schoolId, id, access_id, values },
      axiosConfigAuth({ Pathname: pathName })
    );
  },


  deleteReport: (
    schoolId: string | number,
    id: string,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_UN_REPORT_DELETE_REPORT}/${id}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },

  getWebinarChartData: (
    data: any,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      API_WEBINAR_REPORT_CHART,
      data,
      axiosConfigAuth({
        Pathname: pathName,
      })
    );
  },

  getUniversalReportTableData: (
    data: any,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      API_UN_REPORT_TABLE,
      data,
      axiosConfigAuth({
        Pathname: pathName,
      })
    );
  },

  getUniversalReportChartData: (
    data: any,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      API_UN_REPORT_CHART,
      data,
      axiosConfigAuth({
        Pathname: pathName,
      })
    );
  },

  getTableReportData: (
    url: string,
    data: any,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      url,
      data,
      axiosConfigAuth({
        Pathname: pathName,
      })
    );
  },

  getExports: (id: string): Promise<AxiosResponse> => {
    return axios.post(API_GET_EXPORTS, { school_id: id }, axiosConfigAuth());
  },

  getExportTable: (values: any, pathname: string): Promise<AxiosResponse> => {
    return axios.post(
      API_REQ_EXPORT_TABLE,
      values,
      axiosConfigAuth({ pathname: pathname })
    );
  },

  getReportDataFromHash: (hash: string): Promise<AxiosResponse> => {
    return axios.get(
      `${API_UN_REPORT_GET_REPORT_DATA}/${hash}`,
      axiosConfigAuth()
    );
  },

  getSegmentDataFromHash: (
    hash: string,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_UN_SEGMENT_GET_SEGMENT_DATA}/${hash}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  getCustomMetrics: (
    schoolId: number | string,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORTS_GET_CUSTOM_METRICS}/${schoolId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  getCustomMetric: (
    metricId: number | string,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORTS_GET_CUSTOM_METRIC}/${metricId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  addCustomMetric: (
    data: addMetric,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      `${API_REPORTS_CREATE_CUSTOM_METRIC}`,
      data,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  editCustomMetric: (
    data: EditMetric,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.post(
      `${API_REPORTS_EDIT_CUSTOM_METRIC}`,
      data,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  removeCustomMetric: (
    metricId: number | string,
    pathName: string
  ): Promise<AxiosResponse> => {
    return axios.get(
      `${API_REPORTS_REMOVE_CUSTOM_METRIC}/${metricId}`,
      axiosConfigAuth({ Pathname: pathName })
    );
  },
  // addTag: (data: { school_id: number; kind_id: number; tag: string }): Promise<AxiosResponse> => {
  //   return axios.post(API_TAG_ADD, data, axiosConfigAuth());
  // },
  getTags: (): Promise<AxiosResponse> => {
    return axios.get(API_GET_TAGS, axiosConfigAuth());
  },
  deleteTags: (id: number): Promise<AxiosResponse> => {
    return axios.delete(`${API_DELETE_TAGS}/${id}`, axiosConfigAuth());
  },
  updateTag: (tagId: number, tagName: string): Promise<AxiosResponse> => {
    return axios.post(
      API_RENAME_TAGS,
      { tag_id: tagId, tag: tagName },
      axiosConfigAuth()
    );
  },
  attachTags: (reportId: number, tagIds: number[]): Promise<AxiosResponse> => {
    return axios.post(
      API_ATTACH_TAGS,
      { report_id: reportId, tag_ids: tagIds },
      axiosConfigAuth()
    );
  },
  createTag: (schoolId: any, kindId: number, tagName: string): Promise<AxiosResponse> => {
    return axios.post(
      API_TAG_ADD,
      {
        school_id: schoolId,
        kind_id: kindId,
        tag: tagName
      },
      axiosConfigAuth()
    );
  },
  detachTags: (reportId: number, tagIds: number[]): Promise<AxiosResponse> => {
    return axios.post(
      API_DETACH_TAGS,
      { report_id: reportId, tag_ids: tagIds },
      axiosConfigAuth()
    );
  },
};
