import { FC, useEffect, useRef, useState } from "react"

import { LoadingOutlined, SaveOutlined } from "@ant-design/icons"
import { Button, Dropdown, Form, Input, MenuProps, Modal, Spin } from "antd"
import axios from "axios"
import moment from "moment/moment"
import { useLocation } from "react-router-dom"

import {
  formatPeriod,
  getAdGroupings,
  getFiltersWithoutSegment,
} from "../../../../../helpers/utils/functions"
import {
  handleReportPeriod,
  parseTableData,
} from "../../../../../helpers/utils/reports"
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../helpers/utils/ui"
import { unApi } from "../../../api/endpoints/reports/unApi"
import { tgApi } from "../../../api/endpoints/tgApi"
import { axiosConfigAuth } from "../../../api/middleware"
import { API_UN_REPORT_EXEL } from "../../../api/urls"
import { STATIC_LINK_DRAWER } from "../../../constants/staticLinkConstants"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { ReportMenu } from "../../nav/components/reportMenu/reportMenu"
import { listKeysGroupings } from "../GroupingsAD"

const UniversalReportTitle: FC = () => {
  const location = useLocation()
  const { setAppReportOpen, setDrawerLink } = useActions()
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
  const {
    reports,
    currentReport: storeReport,
    isLoading,
  } = useTypedSelector((state) => state.reports)
  const period = useTypedSelector((state) => state.period)
  const { details } = useTypedSelector((state) => state.details)
  const { filters, currentSegment } = useTypedSelector((state) => state.filter)
  const { attributes } = useTypedSelector((state) => state.attributes)
  const { chartType } = useTypedSelector((state) => state.chart)
  const {
    metrics,
    groupings,
    groupsChecked,
    metricsChecked,
    filtersTable,
    sortTable,
  } = useTypedSelector((state) => state.table)
  const { isCompare } = useTypedSelector((state) => state.meta)

  const {
    setFilters,
    setDetails,
    setChartType,
    setGroupings,
    setMetrics,
    setFiltersTable,
    setSortTable,
    setPeriod,
    setCurrentReport,
    setMetricsFiltersUpdate,
    setCurrentSegment,
    setAttributes,
    getReports,
    postReport,
    putReport,
    setMetaTableFetching,
    setComparePeriod,
    setAllDataFetching,
    // setFavorite
  } = useActions()
  const [ isModalVisible, setIsModalVisible ] = useState(false)
  const [ isModalCreateVisible, setIsModalCreateVisible ] = useState(false)
  const [ isModalXLSXVisible, setIsModalXLSXVisible ] = useState(false)
  const [ reportName, setReportName ] = useState("")
  const [ excelName, setExcelName ] = useState("")
  const [ isDisableSave, setIsDisableSave ] = useState(false)
  const [ isReportLoading, setIsReportLoading ] = useState(false)
  const { savedReports } = useTypedSelector((state) => state.reports)
  const currentReport = savedReports.find((el: any) => el.id === storeReport.id)
    ? savedReports.find((el: any) => el.id === storeReport.id)
    : { ...storeReport, is_favorite: false, name: "Без Имени" }

  const reportParam = new URLSearchParams(location.search).get("report_id")
  useEffect(() => {
    if (location.search && location.search.includes("report_id")) {
      setIsDisableSave(true)
    } else {
      setIsDisableSave(false)
    }
  }, [ location ])

  useEffect(() => {
    const reportHash = location.state?.report_id // Получаем хэш из состояния
    if (reportHash) {
      setReportFromUrl(reportHash) // Загружаем отчет, если хэш есть
    }
  }, [ location.state ])

  useEffect(() => {
    const reportParam = new URLSearchParams(location.search).get("report_id")

    if (reportParam) {
      setReportFromUrl(reportParam)
    }
  }, [ location.search ])

  useEffect(() => {
    if (reportParam) {
      setReportFromUrl(reportParam)
    }
  }, [ reportParam ])

  useEffect(() => {
    if (selectedSchool?.id) {
      getReports(selectedSchool.id)
    }
  }, [])

  const handleOpenHelpFrame = () => {
    setDrawerLink(STATIC_LINK_DRAWER.reports)
    setAppReportOpen(true)
  }

  const save = () => {
    const current_un_period_selection = period.select
    let values: any = {
      ...currentReport.values,
      period: {
        start: moment(period.start).format("YYYY-MM-DD"),
        end: moment(period.end).format("YYYY-MM-DD"),
        interval: period.interval || "day",
      },
      details,
      chartType,
      groupings,
      metrics,
      groupsChecked,
      metricsChecked,
      filtersTable,
      sortTable,
      attributes,
      current_un_period_selection: current_un_period_selection
        ? current_un_period_selection
        : "",
      segment: null,
      filtersReq: attributes,
    }

    if (Object.keys(currentSegment || {}).length > 0) {
      values.segment = currentSegment
      values.filters = getFiltersWithoutSegment(
        filters,
        currentSegment?.values?.filters
      )
    } else {
      values.segment = {}
      values.filters = filters
      values.attributes = attributes
    }

    if (selectedSchool?.id) {
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days
      }
      putReport(selectedSchool.id, { id: currentReport.id, values })
      showSuccessMessage("Отчёт сохранен")
    }
  }

  const saveAs = () => {
    if (selectedSchool?.id) {
      const current_un_period_selection = period.select
      let values: any = {
        name: reportName,
        period: {
          start: moment(period.start).format("YYYY-MM-DD"),
          end: moment(period.end).format("YYYY-MM-DD"),
          interval: period.interval || period.select,
          select: period.select || period.interval,
        },
        details,
        chartType,
        groupings,
        metrics,
        groupsChecked,
        metricsChecked,
        filtersTable,
        sortTable,
        attributes,
        filtersReq: attributes,
        current_un_period_selection: current_un_period_selection
          ? current_un_period_selection
          : "",
        segment: null,
      }

      if (Object.keys(currentSegment || {}).length > 0) {
        values.segment = currentSegment
        values.filters = getFiltersWithoutSegment(
          filters,
          currentSegment?.values?.filters
        )
      } else {
        let _filters = filters
        if (!Array.isArray(filters)) {
          _filters = []
        }
        values.filters = _filters
        values.attributes = attributes
      }
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days
      }
      window.history.replaceState({}, document.title, window.location.pathname)
      postReport(selectedSchool.id, { values })
      setIsModalVisible(false)
      setReportName("")
    }
  }

  const create = () => {
    if (selectedSchool?.id) {
      const current_un_period_selection = period.select
      let values: any = {
        name: reportName,
        period: {
          start: moment(period.start).format("YYYY-MM-DD"),
          end: moment(period.end).format("YYYY-MM-DD"),
          interval: period.interval || period.select,
          select: period.select || period.interval,
        },
        details,
        chartType,
        groupings,
        metrics,
        groupsChecked,
        metricsChecked,
        filtersTable,
        sortTable,
        filtersReq: attributes,
        current_un_period_selection: current_un_period_selection
          ? current_un_period_selection
          : "",
        segment: null,
      }

      if (Object.keys(currentSegment || {}).length > 0) {
        values.segment = currentSegment
        values.filters = getFiltersWithoutSegment(
          filters,
          currentSegment?.values?.filters
        )
      } else {
        let _filters = filters
        if (!Array.isArray(filters)) {
          _filters = []
        }
        values.filters = _filters
        values.attributes = attributes
      }
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days
      }
      const reportData = {
        access_id: 1,
        values,
      }
      postReport(selectedSchool.id, reportData)
      setIsModalCreateVisible(false)
      setReportName("")
    }
  }

  const manualActivateReport = (report: any) => {
    const metrics = parseTableData(report.values.metrics)
    let filters = report.values.filters

    if (!Array.isArray(report.values.filters)) {
      filters = []
    }

    // const handledPeriod = handleReportPeriod(report.values.period)
    const reportPeriod = report.values?.period || {}
    const handledPeriod = handleReportPeriod(reportPeriod)

    setCurrentReport(report)
    setPeriod({
      start: handledPeriod.start,
      end: handledPeriod.end,
      interval: handledPeriod.interval,
      select: "",
    })
    setComparePeriod({
      index: 2,
      period: {
        start: handledPeriod.start,
        end: handledPeriod.end,
        interval: handledPeriod.interval,
      },
    })
    setDetails(report.values.details)
    setChartType(report.values.chartType)
    location.pathname.includes("ad-reports")
      ? setGroupings({
          data: getAdGroupings(report.values.groupings, listKeysGroupings),
        })
      : setGroupings({ data: report.values.groupings })
    setMetrics({ data: metrics })
    setFiltersTable(report.values.filtersTable)
    setSortTable(report.values.sortTable)
    setAttributes(report.values.filtersReq)

    setTimeout(() => {
      setMetaTableFetching({ value: true })
      setMetricsFiltersUpdate(true)
      setAllDataFetching({ value: true })
    }, 200)

    const { segment } = report.values
    const isSegment = segment && Object.keys(report.values.segment).length > 0

    if (isSegment) {
      setCurrentSegment(segment)
      setFilters([ ...segment.values.filters, ...filters ])
    } else {
      setCurrentSegment(null)
      setFilters(filters)
    }

    setAllDataFetching({ value: true })
    setTimeout(() => {
      setMetaTableFetching({ value: true })
      setMetricsFiltersUpdate(true)
    }, 15)
  }

  const saveXSLX = () => {
    const format = "YYYY-MM-DD"
    const _period = {
      ...formatPeriod({ start: period.start, end: period.end }, format),
    }
    const data = {
      name: excelName,
      period: _period,
      filters: attributes,
      groupings,
      metrics,
    }

    axios
      .post(API_UN_REPORT_EXEL, data, axiosConfigAuth())
      .then(() => {
        showSuccessMessage("Сохранено")
      })
      .catch(() => {
        showErrorMessage("Ошибка при сохранении")
      })

    setIsModalXLSXVisible(false)
    setExcelName("")
  }

  const setReportFromUrl = async (reportHash: string) => {
    setIsReportLoading(true) // Активируем загрузку
    try {
      const response = await unApi.getReportDataFromHash(reportHash)
      if (response.status === 200 && response.data[0]) {
        // manualActivateReport(response.data[0]);
        manualActivateReport(currentReport)
      }
    } catch (e) {
      console.error(e)
      showErrorMessage("Ошибка загрузки отчёта")
    } finally {
      setIsReportLoading(false) // Выключаем загрузку
    }
  }

  useEffect(() => {
    if (!location.pathname.includes("/webinar/quality")) {
      const reportsId: number[] = []
      reports.forEach((report: { id: number }) => {
        (async () => {
          const isExist = await tgApi.isExistTask(report.id)
          if (isExist) {
            reportsId.push(report.id)
          }
        })()
      })
    }
  }, [ reports ])

  const savingItemsDropDown: MenuProps["items"] = [
    {
      key: "1",
      label: Object.keys(currentReport).length > 0 && !isDisableSave && (
        <span onClick={save}>Сохранить</span>
      ),
    },
    {
      key: "2",
      label: <span onClick={() => setIsModalVisible(true)}>Сохранить как</span>,
    },
  ]

  const getReportPrefix = (reportId: number) => {
    switch (reportId) {
      case 1:
        return "Отчёт по регистрациям: "
      case 2:
        return "Отчёт по заказам: "
      case 3:
        return "Отчёт по платежам: "
      case 4:
        return "Отчёт по рекламе: "
      case 5:
        return "Отчёт по вебинарам: "
      default:
        return "Отчёт: "
    }
  }

  return (
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div style={{ display: "flex" }}>
          <div style={{ 
                display: "flex", 
                alignItems: "baseline",
                fontSize: "20px",
                fontWeight: "bold" 
          }}>
            <div
              style={{
                marginRight: "15px",
              }}
            >
              {isReportLoading ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : currentReport?.values?.name ? (
                `${getReportPrefix(currentReport.report_id)}${
                  currentReport.values.name
                }`
              ) : (
                "Отчёт не выбран"
              )}
            </div>
            <Button
              onClick={handleOpenHelpFrame}
              style={{
                background: "#35c45b",
                color: "#fff",
                borderRadius: "15px",
                padding: "5px 10px 28px 10px",
                textAlign: "center",
              }}
            >
              Инструкция
            </Button>
            {currentReport.id && (
              <ReportMenu isDashboard={false} itemId={currentReport.id} />
            )}
          </div>
          {!isCompare && (
            <div style={{ marginLeft: "auto" }}>
              <Dropdown
                disabled={isCompare}
                menu={{ items: savingItemsDropDown }}
                trigger={[ "click" ]}
              >
                <Button icon={<SaveOutlined />} disabled={isCompare} />
              </Dropdown>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 0,
            width: "100%",
          }}
        >
          {reports.length === 0 ? (
            <div>
              <Button
                disabled={isCompare}
                onClick={() => setIsModalCreateVisible(true)}
                type="primary"
              >
                Создать отчет
              </Button>
            </div>
          ) : null}
          <Modal
            title="Сохранить отчет как"
            open={isModalVisible}
            onOk={() =>
              reportName
                ? saveAs()
                : showErrorMessage("Введите название отчета")
            }
            onCancel={() => setIsModalVisible(false)}
            okText="Сохранить"
          >
            <Form>
              <Form.Item
                name="reportName"
                label="Название отчета"
                rules={[
                  { required: true, message: "Введите название отчета" },
                  { min: 1, message: "Введите название отчета" },
                ]}
              >
                <Input
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  size="large"
                  placeholder="Введите название отчета"
                  allowClear
                />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Создать отчет"
            open={isModalCreateVisible}
            onOk={create}
            onCancel={() => setIsModalCreateVisible(false)}
            okText="Сохранить"
          >
            <Input
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              size="large"
              placeholder="Введите название отчета"
              allowClear
            />
          </Modal>
          <Modal
            title="Скачать XLSX таблицы"
            open={isModalXLSXVisible}
            onOk={saveXSLX}
            onCancel={() => setIsModalXLSXVisible(false)}
            okText="Сохранить"
          >
            <Input
              value={excelName}
              onChange={(e) => setExcelName(e.target.value)}
              size="large"
              placeholder="Введите название файла"
              allowClear
            />
          </Modal>
        </div>
      </Spin>
  )
}

export default UniversalReportTitle
