import {FC, useEffect} from "react"

import { Space } from "antd"

import Attribution from "./attribution"
import Details from "./details"
import styles from "./Params.module.scss"
import {useTypedSelector} from "../../../../shared/common/hooks/useTypedSelector";
import {IAttributionItem, IDetailItem} from "../../../../app/types/filter";
import UniversalFilter from "./filter"
import {getCookie} from "../../../../helpers/utils/cookies";


interface IParamsProps {
    withAttribution?: boolean;
    attributionItems?: IAttributionItem[];
    withDetails?: boolean;
    detailsItems?: IDetailItem[];
    withCompare?: boolean;
    withSegments?: boolean;
    filtersFilter?: string[];
    isFilterVisible?:boolean
}

const Params: FC<IParamsProps> = ({
                                      withAttribution = true,
                                      attributionItems,
                                      withDetails = true,
                                      detailsItems,
                                      withCompare = true,
                                      withSegments = true,
                                      filtersFilter,
                                      isFilterVisible=true
                                  }) => {
    const { isCompare,isNotParamsFilterVisible } = useTypedSelector((state) => state.meta)
    const isNumberDisplay = getCookie("chosenDisplay") === "number"
    const isChartDisplay = getCookie("chosenDisplay") === "chart"
    return (
        <Space direction="vertical">
            <Space style={{position:"relative"}} className={styles.params}>
                {withAttribution && attributionItems && (
                    <Attribution items={attributionItems} />
                )}
                {withDetails && detailsItems && !isNumberDisplay && isChartDisplay && <Details items={detailsItems} />}
            </Space>
            <div className="filter-wrapper">
                {
                    (!isNotParamsFilterVisible || !isFilterVisible) && <UniversalFilter
                        isCompare={isCompare}
                        withSegment={withSegments}
                        filtersFilter={filtersFilter}
                    />
                }
            </div>
        </Space>
    )
}

export default Params