/**
 Для добавления в элемент фильтра информационной иконки требуется добавить
 в объект элемента парметр description
 @param {string} `description`
 ВАЖНО! Использовать инф. иконку можно только в элементах массива children

 Пример:
 { name: 'Дата заказа', id: 'data_order', type: 'datetimerange', description: "..." }

 Параметр принимает в себя название иконки, список доступных
 иконок для этого модуля редактируется в файле ...
 */
const filters: any = [
  {
    name: "Пользователи",
    color: "#b481b0",
    icon: "UserOutlined",
    root: true,
    id: "user_filter",
    description:
        "Можно выбрать определённый сегмент пользователей, который будет использоваться в отчёте.",
    children: [
      {
        name: "Доп. поля пользователя",
        full_name: "Доп. поля пользователя",
        children: [],
        showListPlus: true,
        description: "Выберите доп. поля пользователя",
        id: "user_extra_field_filter",
        type: "lazylist",
      },
      {
        name: "Имеет регистрацию",
        id: "applications_users_filter",
        description: "Выберите пользователей с определённой регистрацией.",
        children: [
          {
            name: "Тип регистрации",
            full_name: "Имеет тип рег.",
            description:
                "Выберите пользователей, у которых есть определённый тип регистрации.",
            id: "applications_users_type",
            type: "list",
          },
          {
            name: "Дата регистрации",
            full_name: "Имеет дату рег.",
            description:
                "Выберите пользователей, которые зарегистрировались в определённое время.",
            id: "data_applications_users",
            type: "datetimerange",
          },
          {
            name: "Название регистрации",
            full_name: "Имеет название рег.",
            showListPlus: true,
            description:
                "Выберите пользователей, у которых есть определённое название регистрации.",
            id: "name_applications_users",
            type: "lazylist",
          },
          {
            name: "URL адрес регистрации",
            full_name: "Имеет URL адрес рег.",
            description:
                "Выберите пользователей, у которых есть регистрация на определённом адресе страницы.",
            id: "url_applications_users",
            type: "lazylist",
          },
          {
            name: "UTM - метка регистрации",
            id: "applications_users_utm_filter",
            description:
                "Выберите пользователей, у которых есть регистрации с определёнными UTM метками.",
            children: [
              {
                name: "UTM source регистрации",
                showListPlus: true,
                full_name: "Имеет UTM source рег.",
                id: "applications_users_utm_source",
                type: "lazylist",
              },
              {
                name: "UTM medium регистрации",
                showListPlus: true,
                full_name: "Имеет UTM medium рег.",
                id: "applications_users_utm_medium",
                type: "lazylist",
              },
              {
                name: "UTM campaign регистрации",
                showListPlus: true,
                full_name: "Имеет UTM campaign рег.",
                id: "applications_users_utm_campaign",
                type: "lazylist",
              },
              {
                name: "UTM group регистрации",
                showListPlus: true,
                full_name: "Имеет UTM group рег.",
                id: "applications_users_utm_group",
                type: "lazylist",
              },
              {
                name: "UTM term регистрации",
                showListPlus: true,
                full_name: "Имеет UTM term рег.",
                id: "applications_users_utm_term",
                type: "lazylist",
              },
              {
                name: "UTM content регистрации",
                showListPlus: true,
                full_name: "Имеет UTM content рег.",
                id: "applications_users_utm_content",
                type: "lazylist",
              },
            ],
          },
          {
            name: "Технологии регистрации",
            id: "applications_users_tech_filter",
            description:
                "Выберите пользователей, у которых есть регистрации с определённых устройств.",
            children: [
              {
                name: "Тип устройства регистрации",
                full_name: "Имеет тип устр-ва рег.",
                id: "applications_users_list_type",
                type: "list",
              },
              {
                name: "Модель устройства регистрации",
                full_name: "Имеет модель устр-ва рег.",
                id: "applications_users_list_device",
                type: "lazylist",
              },
              {
                name: "Операционная система регистрации",
                full_name: "Имеет ОС устр-ва рег.",
                id: "applications_users_list_os_name",
                type: "lazylist",
              },
              {
                name: "Версия операционной системы регистрации",
                full_name: "Имеет версию ОС устр-ва  рег.",
                id: "applications_users_list_os_version",
                type: "lazylist",
              },
              {
                name: "Браузер регистрации",
                full_name: "Имеет браузер устр-ва рег.",
                id: "applications_users_list_browser_name",
                type: "lazylist",
              },
              {
                name: "Версия браузера регистрации",
                full_name: "Имеет версию браузера устр-ва рег.",
                id: "applications_users_list_browser_version",
                type: "lazylist",
              },
              {
                name: "Язык регистрации",
                full_name: "Имеет язык браузера рег.",
                id: "applications_users_list_browser_language",
                type: "lazylist",
              },
            ],
          },
          {
            name: "Геолокация",
            id: "geolocation_users_filter",
            description:
                "Выберите пользователей, у которых есть регистрации с определённой геолокацией.",
            children: [
              {
                name: "Страна",
                full_name: "Имеет страну рег.",
                id: "users_list_country",
                type: "lazylist",
              },
              {
                name: "Регион",
                full_name: "Имеет регион рег.",
                id: "users_list_region",
                type: "lazylist",
              },
              {
                name: "Город",
                full_name: "Имеет город рег.",
                id: "users_list_city",
                type: "lazylist",
              },
              {
                name: "Часовой пояс",
                full_name: "Имеет часовой пояс рег.",
                id: "users_list_time_loop",
                type: "lazylist",
              },
              {
                name: "Телефонный код",
                full_name: "Имеет телефон. код рег.",
                id: "users_list_telephone_code",
                type: "lazylist",
              },
            ],
          },
          {
            name: "IP адрес",
            full_name: "Имеет IP адрес рег.",
            id: "users_ip",
            type: "text",
            description:
                "Выберите пользователей, у которых есть регистрации с определённым ip адресом.",
          },
        ],
      },
      {
        name: "Имеет заказ",
        id: "users_orders_exists_filter",
        description:
            "Выберите пользователей, у которых есть определённые заказы.",
        children: [
          {
            name: "Номер заказа",
            full_name: "Имеет номер заказа",
            description:
                "Выберите пользователя, у которого есть заказ с определённым номером.",
            id: "uoe_order_number",
            type: "number",
          },
          {
            name: "Дата заказа",
            full_name: "Имеет дату заказа",
            description:
                "Выберите пользователей, которые создавали заказ в определённое время.",
            id: "uoe_data_order",
            type: "datetimerange",
          },
          {
            name: "Предложение",
            full_name: "Имеет предлож. заказа",
            description:
                "Выберите пользователей, у которых есть заказы с определённым предложением.",
            id: "uoe_list_sentence",
            type: "lazylist",
          },
          {
            name: "Тег предложения",
            full_name: "Имеет тег предлож. заказа",
            description:
                "Выберите пользователей, у которых есть заказы с определённым тегом предложением.",
            id: "uoe_list_tags",
            type: "lazylist",
          },
          {
            name: "Тег заказа",
            full_name: "Имеет заказ с тегом",
            description:
                "Выберите пользователей, у которых есть заказы с определённым тегом заказа.",
            id: "uoe_list_tags_3",
            type: "lazylist",
          },
          {
            name: "Статус заказа",
            full_name: "Имеет статус заказа",
            description:
                "Выберите пользователей, у которых есть заказы с определённым статусом заказа.",
            id: "uoe_list_status_order",
            type: "list",
          },
          {
            name: "Менеджер заказа",
            full_name: "Имеет менеджера заказа",
            description:
                "Выберите пользователей, у которых есть заказы с определённым менеджером заказа.",
            id: "uoe_list_managers",
            type: "lazylist",
          },
          {
            name: "Сумма заказа от и до",
            full_name: "Имеет сумму заказа",
            description:
                "Выберите пользователей, у которых есть заказы с определённой суммой.",
            id: "uoe_sum_order",
            type: "numberrange",
          },
          {
            name: "UTM - метка заказа",
            id: "uoe_order_utm_filter",
            description:
                "Выберите пользователей, у которых есть заказы с определённой UTM меткой заказа.",
            children: [
              {
                name: "Имеет UTM source заказа",
                full_name: "Имеет UTM source заказа",
                id: "uoe_order_utm_source",
                type: "lazylist",
              },
              {
                name: "Имеет UTM medium заказа",
                full_name: "Имеет UTM medium заказа",
                id: "uoe_order_utm_medium",
                type: "lazylist",
              },
              {
                name: "Имеет UTM campaign заказа",
                full_name: "Имеет UTM campaign заказа",
                id: "uoe_order_utm_campaign",
                type: "lazylist",
              },
              {
                name: "Имеет UTM group заказа",
                full_name: "Имеет UTM group заказа",
                id: "uoe_order_utm_group",
                type: "lazylist",
              },
              {
                name: "Имеет UTM term заказа",
                full_name: "Имеет UTM term заказа",
                id: "uoe_order_utm_term",
                type: "lazylist",
              },
              {
                name: "Имеет UTM content заказа",
                full_name: "Имеет UTM content заказа",
                id: "uoe_order_utm_content",
                type: "lazylist",
              },
            ],
          },
          {
            name: "1-й заказ",
            id: "users_orders_first_order",
            description:
                "Выберите пользователей, у которых есть определённый первый заказ за всю историю пользователя.",
            children: [
              {
                name: "Дата заказа",
                description:
                    "Выберите пользователей, у которых первый заказ создан в определённый период.",
                full_name: "Имеет дату 1-ого заказа",
                id: "first_order_date",
                type: "datetimerange",
              },
              {
                name: "Предложение",
                description:
                    "Выберите пользователей, у которых первый заказ имеет определённое предложение.",
                full_name: "Имеет предлож. 1-ого заказа",
                id: "first_order_offer",
                type: "lazylist",
              },
              {
                name: "Тег предложения",
                description:
                    "Выберите пользователей, у которых первый заказ имеет определённый тег предложения.",
                full_name: "Имеет тег предлож. 1-ого заказа",
                id: "first_order_tag",
                type: "lazylist",
              },
              {
                name: "Тег заказа",
                description:
                    "Выберите пользователей, у которых первый заказ с определённым тегом заказа.",
                full_name: "Имеет тег заказа. 1-ого заказа",
                id: "first_order_tag_3",
                type: "lazylist",
              },
              {
                name: "Статус заказа",
                description:
                    "Выберите пользователей, у которых первый заказ имеет определённый статус заказа.",
                full_name: "Имеет статус 1-ого заказа",
                id: "first_order_status",
                type: "list",
              },
              {
                name: "Сумма заказа от и до",
                description:
                    "Выберите пользователей, у которых первый заказ имеет определённую сумму.",
                full_name: "Имеет сумму 1-ого заказа",
                id: "first_order_sum",
                type: "numberrange",
              },
            ],
          },
          {
            name: "Количество заказов",
            description:
                "Выберите пользователей, которые имеют определённое количество заказов.",
            full_name: "Имеет кол-во заказов",
            id: "count_orders",
            type: "numberrange",
          },
        ],
      },
      {
        name: "Имеет платёж",
        id: "users_payments_exists_filter",
        description:
            "Выберите пользователей, у которых есть определённые платежи.",
        children: [
          {
            name: "Дата платежа",
            description:
                "Выберите пользователей, у которых есть платёж в определённый период.",
            full_name: "Имеет дату платежа",
            id: "upe_payment_data_register",
            type: "datetimerange",
          },
          {
            name: "Сумма платежа",
            description:
                "Выберите пользователей, у которых есть определённая сумма платежа.",
            full_name: "Имеет сумму платежа",
            id: "upe_sum",
            type: "numberrange",
          },
          {
            name: "Статус платежа",
            description:
                "Выберите пользователей, у которых есть платёж с определённым статусом.",
            full_name: "Имеет статус платежа",
            id: "upe_list_status_pay",
            type: "list",
          },
          {
            name: "Тип платежа",
            description:
                "Выберите пользователей, у которых есть платёж с определённым типом платежа.",
            full_name: "Имеет тип платежа",
            id: "upe_list_type_pay",
            type: "list",
          },
          {
            name: "ID платежа",
            description:
                "Выберите пользователя, у которого есть платёж с определённым ID платежа.",
            full_name: "Имеет ID платежа",
            id: "upe_id_payments",
            type: "number",
          },
          {
            name: "Количество платежей",
            description:
                "Выберите пользователей, у которых есть определённое количество платежей.",
            full_name: "Имеет кол-во платежей",
            id: "count_payments",
            type: "numberrange",
          },
        ],
      },
      {
        name: "Участник вебинара",
        id: "users_webinar_exists_filter",
        description:
            "Выберите пользователей, которые участвовали на вебинарах.",
        children: [
          {
            name: "ID вебинара",
            description:
                "Выберите пользователей, которые участвовали на вебинаре с определённым ID вебинара GetCourse.",
            full_name: "Участник ID веб.",
            id: "uwe_webinar_id",
            type: "number",
          },
          {
            name: "Название вебинара",
            description:
                "Выберите пользователей, которые участвовали на определённом вебинаре.",
            full_name: "Участник веб.",
            id: "uwe_list_webinar",
            type: "lazylist",
          },
          {
            name: "Дата участия на вебинаре",
            description:
                "Выберите пользователей, которые участвовали в определённые даты  на вебинаре.",
            full_name: "Дата участия на веб.",
            id: "uwe_data_webinar",
            type: "datetimerange",
          },
          {
            name: "Время участия на вебинаре",
            description:
                "Выберите пользователей, которые участвовали в определённое время на вебинаре.",
            full_name: "Время участия на веб.",
            id: "uwe_time_webinar",
            type: "timerange",
          },
          {
            name: "Действия на вебинаре",
            description:
                "Выберите пользователей, которые нажимали определённые кнопки на вебинаре.",
            full_name: "Действия пользв. на веб.",
            id: "clicked_button_the_webinar",
            type: "list",
          },
          {
            name: "Технологии вебинара",
            id: "users_webinar_exists_tech_filter",
            description:
                "Выберите пользователей, которые участвовали с определённого устройства на вебинаре.",
            children: [
              {
                name: "Тип устройства участия на вебинаре",
                full_name: "Тип устр. участ. на веб.",
                id: "uwe_list_type",
                type: "list",
              },
              {
                name: "Модель устройства участия на вебинаре",
                full_name: "Модель устр. участ. на веб.",
                id: "uwe_list_device",
                type: "lazylist",
              },
              {
                name: "Операционная система участия на вебинаре",
                full_name: "ОС устр. участ. на веб.",
                id: "uwe_list_os_name",
                type: "lazylist",
              },
              {
                name: "Версия операционной системы участия на вебинаре",
                full_name: "Версия ОС устр. участ. на веб.",
                id: "uwe_list_os_version",
                type: "lazylist",
              },
              {
                name: "Браузер участия на вебинаре",
                full_name: "Браузер устр. участ. на веб.",
                id: "uwe_list_browser_name",
                type: "lazylist",
              },
              {
                name: "Версия браузера участия на вебинаре",
                full_name: "Версия устр. участ. на веб.",
                id: "uwe_list_browser_version",
                type: "lazylist",
              },
              {
                name: "Язык создания участия на вебинаре",
                full_name: "Язык брауз. устр. участ. на веб.",
                id: "uwe_list_browser_language",
                type: "lazylist",
              },
            ],
          },
          {
            name: "Количество комментариев на вебинаре от и до",
            description:
                "Выберите пользователей, которые оставили определённое количество комментариев на вебинаре.",
            full_name: "Кол-во коммент. пользв. на веб.",
            id: "uwe_count_comment",
            type: "numberrange",
          },
          {
            name: "Текст комментария на вебинаре",
            description:
                "Выберите пользователей, которые оставили определённый комментарий на вебинаре.",
            full_name: "Текст коммент. пользв. на веб.",
            id: "uwe_text_comment",
            type: "text",
          },
          {
            name: "Длительность участия на вебинаре от и до (в минутах)",
            description:
                "Выберите пользователей, которые были на вебинаре определённое время.",
            full_name: "Длительн. участ. пользв. на веб.",
            id: "uwe_duration_the_webinar",
            type: "numberrange",
          },
        ],
      },
      {
        name: "Привлечен по рекламе",
        id: "users_ads_exists_ads_filters",
        description:
            "Выберите пользователей, которые были привлечены по определённой рекламе.",
        children: [
          {
            name: "Дата рекламы",
            description:
                "Выберите пользователей, которые были привлечены в определённые даты по рекламе.",
            full_name: "Дата привлеч. пользв. по рекл.",
            id: "uae_ads_data",
            type: "datetimerange",
          },
          {
            name: "Источник рекламы",
            description:
                "Выберите пользователей, которые были привлечены по определённому источнику рекламы.",
            full_name: "Источн. рекламы привлеч. пользв.",
            id: "uae_list_ads_source",
            type: "list",
          },
          {
            name: "Рекламные кабинеты",
            description:
                "Выберите пользователей, которые были привлечены через определённых рекламный кабинет.",
            full_name: "Рекламн. кабинет привлеч. пользв.",
            id: "uae_list_ads_cabinet",
            type: "lazylist",
            useSearch: false,
          },
          {
            name: "Название кампании",
            description:
                "Выберите пользователей, которые были привлечены по определённой рекламной кампании.",
            full_name: "Кампания привлеч. пользв.",
            id: "uae_list_ads_company",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "Название группы объявлений",
            description:
                "Выберите пользователей, которые были привлечены по определённой группе объявлений.",
            full_name: "Группа объявл. привлеч. пользв.",
            id: "uae_list_ads_group",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "Название объявлений",
            description:
                "Выберите пользователей, которые были привлечены по определённму рекламному объявлению.",
            full_name: "Название объявл. привлеч. пользв.",
            id: "uae_list_ads_name",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "ID кампании",
            description:
                "Выберите пользователей, которые были привлечены по определённой рекламной кампании.",
            full_name: "ID кампании привлеч. пользв.",
            id: "uae_id_ads_company",
            type: "number",
          },
          {
            name: "ID группы объявлений",
            description:
                "Выберите пользователей, которые были привлечены по определённой группе объявлений.",
            full_name: "ID группы объявл. привлеч. пользв.",
            id: "uae_id_ads_group",
            type: "number",
          },
          {
            name: "ID объявления",
            description:
                "Выберите пользователей, которые были привлечены по определённму рекламному объявлению.",
            full_name: "ID объявл. привлеч. пользв.",
            id: "uae_id_ads",
            type: "number",
          },
        ],
      },
      {
        name: "Денег принёс",
        id: "payment_data_filter",
        description:
            "Выберите пользователей, которые оплатили общую определённую сумму за всё время.",
        children: [
          {
            name: "Денег принёс от и до",
            full_name: "",
            id: "payment_data",
            type: "numberrange",
          },
          {
            name: "Денег принёс за период",
            full_name: "",
            id: "user_payment_data_register",
            type: "datetimerange",
          },
        ],
      },
      {
        name: "Демография",
        id: "demography_filter",
        description: "Выберите пользователей по полу или возрасту.",
        visibleDescription: true,
        children: [
          { name: "Пол", full_name: "", id: "list_gender", type: "list" },
          {
            name: "Возраст",
            full_name: "",
            id: "list_ages_list",
            type: "list",
          },
        ],
      },
      {
        name: "Email",
        description: "Выберите пользователя с определённым эл. адресом.",
        full_name: "",
        id: "user_email",
        type: "text",
      },
      {
        name: "Телефон",
        description: "Выберите пользователя с определённым номером телефона.",
        full_name: "",
        id: "user_phone",
        type: "text",
      },
      {
        name: "Дата рождения",
        description: "Выберите пользователя с определённый датой рождения.",
        full_name: "",
        id: "birthday",
        type: "datetimerange",
      },
      {
        name: "ID в GetCourse",
        description: "Выберите пользователя с определённым id GetCourse.",
        full_name: "",
        id: "id_get_cource",
        type: "number",
      },
    ],
  },
  {
    name: "Регистрации",
    color: "#79a072",
    icon: "UserAddOutlined",
    root: true,
    id: "applications_filter",
    description:
        "Можно выбрать определённые регистрации, которые нужно отобразить в отчёте.",
    children: [
      {
        name: "Дата регистрации",
        description: "Выберите регистрации за определённый период регистрации.",
        full_name: "Дата рег.",
        id: "data_applications",
        type: "datetimerange",
      },
      {
        name: "Время регистрации",
        description: "Выберите регистрации созданный интервал времени.",
        full_name: "Время регистрации",
        id: "time_applications",
        type: "timerange",
      },
      {
        name: "UTM - метка регистрации",
        id: "applications_utm_filter",
        description: "Выберите регистрации с определёнными UTM метками.",
        children: [
          {
            name: "UTM source регистрации",
            showListPlus: true,
            full_name: "UTM source рег.",
            id: "applications_utm_source",
            type: "lazylist",
          },
          {
            name: "UTM medium регистрации",
            showListPlus: true,
            full_name: "UTM medium рег.",
            id: "applications_utm_medium",
            type: "lazylist",
          },
          {
            name: "UTM campaign регистрации",
            showListPlus: true,
            full_name: "UTM campaign рег.",
            id: "applications_utm_campaign",
            type: "lazylist",
          },
          {
            name: "UTM group регистрации",
            showListPlus: true,
            full_name: "UTM group рег.",
            id: "applications_utm_group",
            type: "lazylist",
          },
          {
            name: "UTM term регистрации",
            showListPlus: true,
            full_name: "UTM term рег.",
            id: "applications_utm_term",
            type: "lazylist",
          },
          {
            name: "UTM content регистрации",
            showListPlus: true,
            full_name: "UTM content рег.",
            id: "applications_utm_content",
            type: "lazylist",
          },
        ],
      },
      {
        name: "Название регистрации",
        showListPlus: true,
        description:
            "Выберите регистрации по названию регистрации:<br>" +
            "<br>" +
            "У типа регистрации:<br>" +
            "<br>" +
            "SCAN название регистарции - это название страницы, где была заполнена форма.<br>" +
            "<br>" +
            "Нулевой заказ GC - это название нулевого предложения в GC<br>" +
            "<br>" +
            "Регистрации GC - нет названий регистраций.",
        full_name: "Название рег.",
        id: "name_applications",
        type: "lazylist",
      },
      {
        name: "Статус регистрации",
        full_name: "Cтатус рег.",
        description:
            "Выберите регистрации с определённым статусом. Это статус нулевого заказа в GetCourse.",
        id: "app_statuses",
        type: "list",
      },
      {
        name: "Доп. поля регистрации",
        full_name: "Доп. поля регистрации",
        children: [],
        showListPlus: true,
        description: "Выберите доп. поля регистрации",
        id: "reg_extra_field_filter",
        type: "lazylist",
      },
      {
        name: "Тег регистрации",
        description:
            "Выберите регистрации по тегу регистрации, т.е. по тегу нулевого заказ в GC.",
        full_name: "Тег рег.",
        id: "app_tags",
        type: "lazylist",
      },
      {
        name: "Тег предложения регистрации",
        full_name: "Тег предложения рег.",
        description:
            "Выберите регистрации по тегу предложения регистрации, т.е. по тегу предложения нулевого заказ в GC.",
        id: "app_offer_tags",
        type: "lazylist",
      },
      {
        name: "URL адрес регистрации",
        description:
            "Выберите регистрации, которые были созданы на определенной странице. В поиске введите адрес страницы.",
        full_name: "URL адрес рег.",
        id: "url_applications",
        type: "lazylist",
      },
      {
        name: "Номер регистрации",
        description: "Найдите регистрацию по номеру нулевого заказа GC.",
        full_name: "Номер рег.",
        id: "applications_order_number",
        type: "number",
      },
      {
        name: "Тип регистрации",
        full_name: "Тип рег.",
        description:
            "Выберите пользователей, у которых есть определённый тип регистрации.",
        id: "type_applications",
        type: "list",
      },
      {
        name: "Источник рекламы регистрации",
        id: "users_ads_exists_ads_filter",
        description: "",
        children: [
          {
            name: "Дата рекламы",
            full_name: "Рег. от даты рекламы",
            id: "reg_ads_data",
            type: "datetimerange",
          },
          {
            name: "Источник рекламы",
            full_name: "Рег. от источника рекламы",
            id: "reg_list_ads_source",
            type: "list",
          },
          {
            name: "Рекламный кабинет",
            full_name: "Рег. от рекламного кабинета",
            id: "reg_list_ads_cabinet",
            type: "lazylist",
            useSearch: false,
          },
          {
            name: "Название кампании",
            full_name: "Рег. от названия кампании",
            id: "reg_list_ads_company",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "Название группы объявлений",
            full_name: "Рег. от названия груп. объявл.",
            id: "reg_list_ads_group",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "Название объявления",
            full_name: "Рег. от названия объявл.",
            id: "reg_list_ads_name",
            type: "lazylist",
            useSearch: true,
          },
          {
            name: "ID кампании",
            full_name: "Рег от ID кампании",
            id: "reg_id_ads_company",
            type: "number",
          },
          {
            name: "ID группы объявлений",
            full_name: "Рег. от  ID груп. объявл.",
            id: "reg_id_ads_group",
            type: "number",
          },
          {
            name: "ID объявления",
            full_name: "Рег. от ID объявл.",
            id: "reg_id_ads",
            type: "number",
          },
          {
            name: "ID объявления",
            full_name: "Рег. от ID объявл.",
            id: "reg_id_ads",
            type: "number",
          },
        ],
      },
      {
        name: "Технологии регистрации",
        id: "applications_tech_filter",
        description:
            "Выберите регистрации, которые были сделаны с определённых устройств.",
        children: [
          {
            name: "Тип устройства регистрации",
            full_name: "Тип устройства рег.",
            id: "applications_list_type",
            type: "list",
          },
          {
            name: "Модель устройства регистрации",
            full_name: "Модель устройства рег.",
            id: "applications_list_device",
            type: "lazylist",
          },
          {
            name: "Операционная система регистрации",
            full_name: "ОС рег.",
            id: "applications_list_os_name",
            type: "lazylist",
          },
          {
            name: "Версия операционной системы регистрации",
            full_name: "Версия ОС рег.",
            id: "applications_list_os_version",
            type: "lazylist",
          },
          {
            name: "Браузер регистрации",
            full_name: "Браузер рег.",
            id: "applications_list_browser_name",
            type: "lazylist",
          },
          {
            name: "Версия браузера регистрации",
            full_name: "Версия браузера рег.",
            id: "applications_list_browser_version",
            type: "lazylist",
          },
          {
            name: "Язык регистрации",
            full_name: "Язык браузера рег.",
            id: "applications_list_browser_language",
            type: "lazylist",
          },
        ],
      },
      {
        name: "Геолокация регистрации",
        id: "geolocation_filter",
        description:
            "Выберите регистрации, которые были созданы в определённой странице, регионе, городе, часовом поясе.",
        children: [
          {
            name: "Страна",
            full_name: "Страна рег.",
            id: "list_country",
            type: "lazylist",
          },
          {
            name: "Регион",
            full_name: "Регион рег.",
            id: "list_region",
            type: "lazylist",
          },
          {
            name: "Город",
            full_name: "Город рег.",
            id: "list_city",
            type: "lazylist",
          },
          {
            name: "Часовой пояс",
            full_name: "Часов. пояс рег.",
            id: "list_time_loop",
            type: "lazylist",
          },
          {
            name: "Телефонный код",
            full_name: "Телефон. код рег.",
            id: "list_telephone_code",
            type: "lazylist",
          },
        ],
      },
      {
        name: "IP адрес регистрации",
        full_name: "",
        description:
            "Выберите регистрации, которые были созданы с определённого ip адреса",
        id: "ip",
        type: "text",
      },
    ],
  },
  {
    name: "Заявки",
    color: "#426b69",
    root: true,
    icon: "CheckOutlined",
    id: "app_no_filter",
    children: [
      {
        name: "Название заявки",
        description:
            "Выберите название нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_title",
        type: "lazylist",
      },
      {
        name: "Дата заявки",
        description:
            "Выберите дату нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_date",
        type: "datetimerange",
      },
      {
        name: "Время заявки",
        description:
            "Выберите время нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_time",
        type: "timerange",
      },
      {
        name: "Статус заявки",
        description:
            "Выберите статус нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_status",
        type: "list",
      },
      {
        name: "Тег заявки",
        description:
            "Выберите тег нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_tag",
        type: "lazylist",
      },
      {
        name: "Тег предложения заявки",
        description:
            "Выберите тег предложения нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_offer_tag",
        type: "lazylist",
      },
      {
        name: "Доп. поля заявки",
        full_name: "Доп. поля заявки",
        description:"Выберите доп. поля заявки",
        children: [],
        showListPlus: true,
        id: "app_no_extra_fields",
        type: "lazylist",
      },
      {
        name: "Номер заявки",
        description:
            "Выберите номер нулевого заказа GC, который нужно отобразить в отчёте под метрикой Заявки.Это нулевые заказы которые создаются пользователем после регистрации",
        full_name: "",
        id: "app_no_number",
        type: "number",
      },
    ],
  },
  {
    name: "Заказы",
    color: "#704c5e",
    icon: "ShoppingOutlined",
    root: true,
    id: "order_filter",
    description:
        "Можно выбрать определённые заказы, которые нужно отобразить в отчёте.",
    children: [
      {
        name: "Дата заказа",
        description: "Выберите заказы по дате создания заказа.",
        full_name: "",
        id: "data_order",
        type: "datetimerange",
      }, // для добавления инфориационной иконки
      {
        name: "Номер заказа",
        description: "Выберите заказ но номеру заказа.",
        full_name: "",
        id: "order_number",
        type: "number",
      }, // используется параметр description
      {
        name: "Предложение",
        description: "Выберите заказы с определённым предложением в заказе.",
        full_name: "",
        id: "list_sentence",
        type: "lazylist",
      },
      {
        name: "Тег предложения",
        description: "Выберите заказы с определённым тегом предложения заказа.",
        full_name: "",
        id: "list_tags",
        type: "lazylist",
      },
      {
        name: "Тег заказа",
        description: "Выберите заказы с определённым тегом заказа.",
        full_name: "",
        id: "list_tags_3",
        type: "lazylist",
      },
      {
        name: "Статус заказа",
        description: "Выберите заказы с определённым статусом заказа.",
        full_name: "",
        id: "list_status_order",
        type: "list",
      },
      {
        name: "Менеджер",
        description: "Выберите заказы с закреплённым менеджером заказа.",
        full_name: "",
        id: "list_managers",
        type: "lazylist",
      },
      {
        name: "Сумма заказа",
        description: "Выберите заказы с определённой суммой заказа.",
        full_name: "",
        id: "sum_order",
        type: "numberrange",
      },
      {
        name: "Доп. поля заказа",
        full_name: "Доп. поля заказа",
        children: [],
        showListPlus: true,
        description: "Выберите доп. поля заказа",
        id: "order_extra_field_filter",
        type: "lazylist",
      },
      {
        name: "UTM - метка заказа",
        id: "order_utm_filter",
        description: "Выберите заказы с определённой UTM меткой заказа.",
        visibleDescription: true,
        children: [
          {
            name: "UTM source",
            full_name: "UTM source заказа",
            id: "order_utm_source",
            type: "lazylist",
          },
          {
            name: "UTM medium",
            full_name: "UTM medium заказа",
            id: "order_utm_medium",
            type: "lazylist",
          },
          {
            name: "UTM campaign",
            full_name: "UTM campaign заказа",
            id: "order_utm_campaign",
            type: "lazylist",
          },
          {
            name: "UTM group",
            full_name: "UTM group заказа",
            id: "order_utm_group",
            type: "lazylist",
          },
          {
            name: "UTM term",
            full_name: "UTM term заказа",
            id: "order_utm_term",
            type: "lazylist",
          },
          {
            name: "UTM content",
            full_name: "UTM content заказа",
            id: "order_utm_content",
            type: "lazylist",
          },
        ],
      },
      {
        name: "Период от регистрации до заказа (в днях)",
        full_name: "Период от рег. до заказа",
        id: "applications_users_days",
        type: "numberrange",
        description:
            "Выберите заказы, созданные в определённый период после регистрации. Например, можно выбрать заказы, созданные в течение 7 дней после регистрации. В фильтре нужно выбрать от 1 до 7. В фильтре 1 - это день регистрации. 7 - это седьмой день после регистрации. Если нужно показать заказы созданные в день регистрации, то в фильтре выбрать от 1 до 1. Если нужно показать заказы, созданные с 2 по 5 день после регистрации, укажите в фильтре от 2 до 5.",
      },
      {
        name: "Период от посещения вебинара до заказа (в днях)",
        full_name: "Период от вебинара до заказа",
        id: "period_from_visit_to_order",
        type: "numberrange",
        description:
            "Выберите заказы, созданные в определённый период после посещения вебинара. Например, можно выбрать заказы, созданные в день посещения вебинара. В фильтре нужно выбрать от 1 до 1. В фильтре 1 - это день посещения вебинара. Если нужно показать заказы созданные на следующий день после участия на вебинаре, то в фильтре нужно выбрать от 2 до 2. Если нужно показать заказы, созданные с 2 по 5 день после посещения вебинара, укажите в фильтре от 2 до 5",
      },
      {
        name: "Есть платёж по заказу",
        id: "order_pay_filter",
        description: "Выберите заказы с определённым платежом.",
        children: [
          {
            name: "Дата платежа",
            description: "Выберите заказы с определённой датой платежа.",
            full_name: "Дата платежа у заказа",
            id: "order_payment_data_register",
            type: "datetimerange",
          },
          {
            name: "Сумма платежа",
            description: "Выберите заказы с определённой суммой платежа.",
            full_name: "Сумма платежа у заказа",
            id: "order_payment_sum",
            type: "numberrange",
          },
          {
            name: "Статус платежа",
            description: "Выберите заказы с определённым статусом платежа.",
            full_name: "Статус платежа у заказа",
            id: "order_list_status_pay",
            type: "list",
          },
          {
            name: "Тип платежа",
            description: "Выберите заказы с определённым типом платежа.",
            full_name: "Тип платежа у заказа",
            id: "order_list_type_pay",
            type: "list",
          },
          {
            name: "ID платежа",
            description: "Выберите заказ с определённым ID платежа.",
            full_name: "ID платежа у заказа",
            id: "order_id_payments",
            type: "number",
          },
        ],
      },
    ],
  },
  {
    name: "Платежи",
    color: "#426b69",
    icon: "DollarOutlined",
    root: true,
    id: "pay_filter",
    description:
        "Можно выбрать определённые платежи, которые будут учитываться в отчёте.",
    children: [
      {
        name: "Дата платежа",
        description: "Выберите платежи созданные в определённый дни.",
        full_name: "",
        id: "payment_data_register",
        type: "datetimerange",
      },
      {
        name: "Сумма платежа",
        description: "Выберите платежи с определённой суммой платежа.",
        full_name: "",
        id: "sum",
        type: "numberrange",
      },
      {
        name: "Статус платежа",
        description: "Выберите платежи с определённым статусом платежа.",
        full_name: "",
        id: "list_status_pay",
        type: "list",
      },
      {
        name: "Тип платежа",
        description: "Выберите платежи с определённым типом платежа.",
        full_name: "",
        id: "list_type_pay",
        type: "list",
      },
      {
        name: "ID платежа",
        description: "Выберите платеж с определённый ID платежа.",
        full_name: "",
        id: "id_payments",
        type: "number",
      },
      {
        name: "Период от заказа до платежа (в днях)",
        full_name: "Период от заказа до платежа",
        id: "applications_payments_orders_days",
        type: "numberrange",
        description:
            "Выберите платежи, совершённые в определённый период после создания заказа. Например, можно выбрать платежи, совершённые в течение 2-х дней после создания заказа. В фильтре нужно выбрать от 1 до 2. В фильтре 1 - это день создания заказа. 2 - это следующий день после создания заказа. Если нужно показать платежи созданные в день создания заказа, то в фильтре выбрать от 1 до 1. Если нужно показать платежи, совершённые с 2 по 4 день после создания заказа, в фильтре укажите от 2 до 4.",
      },
      {
        name: "Период от регистрации до платежа (в днях)",
        full_name: "Период от рег. до платежа",
        id: "applications_payments_reg_days",
        type: "numberrange",
        description:
            "Выберите платежи, совершённые в определённый период после регистрации. Например, можно выбрать платежи, совершённые в течение 2-х дней после регистрации. В фильтре нужно выбрать от 1 до 2. В фильтре 1 - это день регистрации. 2 - это следующий день после регистрации. Если нужно показать платежи созданные в день регистрации, то в фильтре выбрать от 1 до 1. Если нужно показать платежи, совершённые с 2 по 4 день после регистрации, в фильтре укажите от 2 до 4.",
      },
    ],
  },
  {
    name: "Сессии",
    color: "#376a68",
    icon: "UserSwitchOutlined",
    root: true,
    id: "visits",
    children: [
      {
        name: "Дата и время сессии",
        full_name: "",
        id: "data_sessions",
        type: "datetimerange",
      },
      { name: "id сессии", full_name: "", id: "sessions_id", type: "text" },
      { name: "Scan id", full_name: "", id: "scan_id", type: "text" },
      {
        name: "UTM метка сессии",
        id: "utm_visits_session_list",
        children: [
          {
            name: "UTM source сессии",
            full_name: "",
            id: "sessions_utm_source",
            type: "lazylist",
          },
          {
            name: "UTM medium сессии",
            full_name: "",
            id: "sessions_utm_medium",
            type: "lazylist",
          },
          {
            name: "UTM campaign сессии",
            full_name: "",
            id: "sessions_utm_campaign",
            type: "lazylist",
          },
          {
            name: "UTM group сессии",
            full_name: "",
            id: "sessions_utm_group",
            type: "lazylist",
          },
          {
            name: "UTM term сессии",
            full_name: "",
            id: "sessions_utm_term",
            type: "lazylist",
          },
          {
            name: "UTM content сессии",
            full_name: "",
            id: "sessions_utm_content",
            type: "lazylist",
          },
        ],
      },
      {
        name: "Технология сессии",
        id: "utm_visits_techs_list",
        children: [
          {
            name: "Тип устройства",
            full_name: "",
            id: "sessions_list_type",
            type: "list",
          },
          {
            name: "Модель устройства",
            full_name: "",
            id: "sessions_list_device",
            type: "lazylist",
          },
          {
            name: "Операционная система",
            full_name: "",
            id: "sessions_list_os_name",
            type: "lazylist",
          },
          {
            name: "Версия операционной системы",
            full_name: "",
            id: "sessions_list_os_version",
            type: "lazylist",
          },
          {
            name: "Браузер",
            full_name: "",
            id: "sessions_list_browser_name",
            type: "lazylist",
          },
          {
            name: "Версия браузера",
            full_name: "",
            id: "sessions_list_browser_version",
            type: "lazylist",
          },
          {
            name: "Язык браузера",
            full_name: "",
            id: "sessions_list_browser_language",
            type: "lazylist",
          },
        ],
      },
      {
        name: "Имеет хит",
        id: "utm_visits_have_hit",
        children: [
          { name: "id хита", full_name: "", id: "has_hit_id", type: "text" },
          {
            name: "URL адрес хита",
            full_name: "",
            id: "has_hit_url",
            type: "lazylist",
          },
          {
            name: "UTM метка хита",
            id: "utm_visits_have_hit_list",
            children: [
              {
                name: "UTM source хита",
                full_name: "",
                id: "has_hits_utm_source",
                type: "lazylist",
              },
              {
                name: "UTM medium хита",
                full_name: "",
                id: "has_hits_utm_medium",
                type: "lazylist",
              },
              {
                name: "UTM campaign хита",
                full_name: "",
                id: "has_hits_utm_campaign",
                type: "lazylist",
              },
              {
                name: "UTM group хита",
                full_name: "",
                id: "has_hits_utm_group",
                type: "lazylist",
              },
              {
                name: "UTM term хита",
                full_name: "",
                id: "has_hits_utm_term",
                type: "lazylist",
              },
              {
                name: "UTM content хита",
                full_name: "",
                id: "has_hits_utm_content",
                type: "lazylist",
              },
            ],
          },
        ],
      },
      { name: "Email", full_name: "", id: "sessions_user_email", type: "text" },
      {
        name: "ID в GetCourse",
        full_name: "",
        id: "sessions_getcource_id",
        type: "number",
      },
    ],
  },
  {
    name: "Хиты",
    color: "#c63535",
    icon: "ScanOutlined",
    root: true,
    id: "hits",
    children: [
      { name: "id хита", full_name: "", id: "hit_id", type: "text" },
      {
        name: "URL адрес хита",
        full_name: "",
        id: "hit_url",
        type: "lazylist",
      },
      {
        name: "UTM метка хита",
        id: "utm_hits_list",
        children: [
          {
            name: "UTM source хита",
            full_name: "",
            id: "hits_utm_source",
            type: "lazylist",
          },
          {
            name: "UTM medium хита",
            full_name: "",
            id: "hits_utm_medium",
            type: "lazylist",
          },
          {
            name: "UTM campaign хита",
            full_name: "",
            id: "hits_utm_campaign",
            type: "lazylist",
          },
          {
            name: "UTM group хита",
            full_name: "",
            id: "hits_utm_group",
            type: "lazylist",
          },
          {
            name: "UTM term хита",
            full_name: "",
            id: "hits_utm_term",
            type: "lazylist",
          },
          {
            name: "UTM content хита",
            full_name: "",
            id: "hits_utm_content",
            type: "lazylist",
          },
        ],
      },
    ],
  },
  {
    name: "Посещения страницы",
    color: "#c63535",
    icon: "ScanOutlined",
    root: true,
    id: "visits_page",
    children: [
      {
        name: "URL адрес хита",
        full_name: "",
        id: "hit_url",
        type: "lazylist",
      },
      {
        name: "Дата хита",
        description: "Выберите определённый период.",
        full_name: "Дата хита",
        id: "data_hits",
        type: "datetimerange",
      },
    ],
  },
  {
    name: "Просмотр страницы после регистрации",
    color: "#c63535",
    icon: "ScanOutlined",
    root: true,
    id: "visits_page",
    children: [
      {
        name: "URL адрес страницы",
        full_name: "",
        id: "hit_url",
        type: "lazylist",
      },
      {
        name: "Дата просмотра страницы после рег",
        description: "Выберите определённый период.",
        full_name: "Дата просмотра",
        id: "data_hits",
        type: "datetimerange",
      },
    ],
  },
  {
    name: "Просмотры страниц",
    color: "#c63535",
    icon: "ScanOutlined",
    root: true,
    id: "visits_page",
    children: [
      {
        name: "URL адрес страницы",
        full_name: "",
        id: "hit_url",
        type: "lazylist",
      },
      {
        name: "Дата просмотра страницы",
        description: "Выберите определённый период.",
        full_name: "Дата просмотра",
        id: "data_hits",
        type: "datetimerange",
      },
    ],
  },
  {
    name: "Вебинары",
    color: "#415eb0",
    icon: "VideoCameraOutlined",
    root: true,
    id: "webinar_filter",
    description:
        "Можете выбрать определённые вебинары для отображения в отчёте.",
    children: [
      {
        name: "ID вебинара",
        description: "Выберите вебинар с определённым ID вебинара.",
        full_name: "",
        id: "list_webinar_id",
        type: "lazylist",
      },
      {
        name: "Название вебинара",
        description:
            "Выберите название вебинара. Из Bizon365 названия вебинаров подтягиваются корректные. Из GC не удаётся получить название вебинара, если оно скрыто в настройках комнаты. Зайдите в список вебинаров, по id вебинара GC найдите нужный вебинар и поменяйте название.",
        full_name: "",
        id: "list_webinar",
        type: "lazylist",
      },
      {
        name: "Дата проведения вебинара",
        description: "Выберите вебинары по дате проведения вебинара.",
        full_name: "",
        id: "data_webinar",
        type: "datetimerange",
      },
      {
        name: "Время проведения вебинара",
        description:
            "Выберите вебинары по времени проведения вебинара. SCAN учитывает посещение страницы вебинара. Если вебинар начинается в 19:00, а человек зашёл на страницу проведения вебинара в 17:00 Scan будет учитывать этого пользователя как участник вебинара. Если вы зададите время трансляции, то в отчёт попадут только люди, кто был на трансляции в заданное время.",
        full_name: "",
        id: "time_webinar",
        type: "timerange",
      },
      {
        name: "Период от регистрации до участия на вебинаре (в днях)",
        full_name: "Период участия на веб. после рег. (дни)",
        description:
            "Выберите участников вебинара, кто посетил вебинар в определённый период после регистрации. Например, можно выбрать участников, кто был на вебинаре течение 2-х дней после регистрации. В фильтре нужно выбрать от 1 до 2. В фильтре 1 - это день регистрации. 2 - это следующий день после регистрации. Если нужно показать участников в день регистрации, то в фильтре выберите от 1 до 1. Если нужно показать участников, кто был с 2 по 4 день после регистрации, в фильтре укажите от 2 до 4.",
        id: "webinar_reg_days",
        type: "numberrange",
      },

      {
        name: "Статус вкладки",
        description:
            "Выберите участников вебинара, кто смотрел вебинар с активной или неактивной вкладки браузера ноутбука или компьютера.",
        full_name: "",
        id: "tab_status_webinar",
        type: "list",
      },
      {
        name: "Действия на вебинаре",
        description:
            "Выберите участников вебинара кто нажимал на определённый кнопки на вебинаре.",
        full_name: "",
        id: "uwe_clicked_button_the_webinar",
        type: "list",
      },
      {
        name: "Технология участия на вебинаре",
        id: "webinar_tech_filter",
        description:
            "Выберите участников вебинара кто участвовал на вебинаре с определённого устройства.",
        children: [
          {
            name: "Тип устройства участия на вебинаре",
            full_name: "Тип устр. участия на веб.",
            id: "webinar_list_type",
            type: "list",
          },
          {
            name: "Модель устройства участия на вебинаре",
            full_name: "Модель устр. участия на веб.",
            id: "webinar_list_device",
            type: "lazylist",
          },
          {
            name: "Операционная система участия на вебинаре",
            full_name: "ОС участия на веб.",
            id: "webinar_list_os_name",
            type: "lazylist",
          },
          {
            name: "Версия операционной системы участия на вебинаре",
            full_name: "Версия ОС участия на веб.",
            id: "webinar_list_os_version",
            type: "lazylist",
          },
          {
            name: "Браузер участия на вебинаре",
            full_name: "Браузер участия на веб.",
            id: "webinar_list_browser_name",
            type: "lazylist",
          },
          {
            name: "Версия браузера участия на вебинаре",
            full_name: "Версия браузера участия на веб.",
            id: "webinar_list_browser_version",
            type: "lazylist",
          },
          {
            name: "Язык участия на вебинаре",
            full_name: "Язык браузера участия на веб.",
            id: "webinar_list_browser_language",
            type: "lazylist",
          },
        ],
      },
    ],
  },
  {
    name: "Реклама",
    color: "#a3333d",
    icon: "DingdingOutlined",
    root: true,
    id: "ads_filter",
    description:
        "Можно выбрать определённую рекламу для вывода в отчётах. Работает только с группировками из раздела: Реклама.",
    children: [
      {
        name: "Дата рекламы",
        description: "Выберите определённый день рекламы.",
        full_name: "",
        id: "ads_data",
        type: "datetimerange",
      },
      {
        name: "Источник рекламы",
        description: "Выберите определённый источник рекламы.",
        full_name: "",
        id: "list_ads_source",
        type: "list",
      },
      {
        name: "Рекламный кабинет",
        description: "Выберите определённый рекламный кабинет.",
        full_name: "",
        id: "list_ads_cabinet",
        type: "lazylist",
        useSearch: false,
      },
      {
        name: "Название кампании",
        description: "Выберите определённое название кампании.",
        full_name: "",
        id: "list_ads_company",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название группы объявлений",
        description: "Выберите определённую группу объявлений.",
        full_name: "",
        id: "list_ads_group",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название объявления",
        description: "Выберите определённое объявление.",
        full_name: "",
        id: "list_ads_name",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "ID кампании",
        description: "Выберите определённую компания по её ID.",
        full_name: "",
        id: "id_ads_company",
        type: "number",
      },
      {
        name: "ID группы объявлений",
        description: "Выберите определённую группу объявлений по его ID.",
        full_name: "",
        id: "id_ads_group",
        type: "number",
      },
      {
        name: "ID объявления",
        description: "Выберите определённое объявление по его ID.",
        full_name: "",
        id: "id_ads",
        type: "number",
      },
      {
        name: "Период от рекламы до посещения (в днях)",
        description:
            "Период от размещения рекламы до перехода на сайт. Для рекламы, у которых день посещения соответствует дню рекламы, то укажите от 1 до 1. Если анализируете рекламные посевы, когда день посещения может быть позже дня размещения рекламы, оставьте поля пустыми",
        full_name: "",
        id: "period_from_ads_to_visit",
        type: "numberrange",
      },
    ],
  },
  {
    name: "Реклама (АР)",
    color: "#a3333d",
    icon: "DingdingOutlined",
    root: true,
    id: "ads_filter",
    description:
        "Можно выбрать определённую рекламу для вывода в отчётах. Работает только с группировками из раздела: Реклама.",
    children: [
      {
        name: "Дата рекламы",
        description: "Выберите определённый день рекламы.",
        full_name: "",
        id: "ads_data",
        type: "datetimerange",
      },
      {
        name: "Источник рекламы",
        description: "Выберите определённый источник рекламы.",
        full_name: "",
        id: "list_ads_source",
        type: "list",
      },
      {
        name: "Рекламный кабинет",
        description: "Выберите определённый рекламный кабинет.",
        full_name: "",
        id: "list_ads_cabinet",
        type: "lazylist",
        useSearch: false,
      },
      {
        name: "Название кампании",
        description: "Выберите определённое название кампании.",
        full_name: "",
        id: "list_ads_company",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название группы объявлений",
        description: "Выберите определённую группу объявлений.",
        full_name: "",
        id: "list_ads_group",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название объявления",
        description: "Выберите определённое объявление.",
        full_name: "",
        id: "list_ads_name",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "ID кампании",
        description: "Выберите определённую компания по её ID.",
        full_name: "",
        id: "id_ads_company",
        type: "number",
      },
      {
        name: "ID группы объявлений",
        description: "Выберите определённую группу объявлений по его ID.",
        full_name: "",
        id: "id_ads_group",
        type: "number",
      },
      {
        name: "ID объявления",
        description: "Выберите определённое объявление по его ID.",
        full_name: "",
        id: "id_ads",
        type: "number",
      },
    ],
  },
  {
    name: "Реклама (РР)",
    color: "#a3333d",
    icon: "DingdingOutlined",
    root: true,
    id: "advert_ads_filter",
    description:
        "Можно выбрать определённую рекламу для вывода в отчётах. Работает только с группировками из раздела: Реклама.",
    children: [
      {
        name: "Дата рекламы",
        description: "Выберите определённый день рекламы.",
        full_name: "",
        id: "advert_ads_data",
        type: "datetimerange",
      },
      {
        name: "Источник рекламы",
        description: "Выберите определённый источник рекламы.",
        full_name: "",
        id: "advert_list_ads_source",
        type: "list",
      },
      {
        name: "Рекламный кабинет",
        description: "Выберите определённый рекламный кабинет.",
        full_name: "",
        id: "advert_list_ads_cabinet",
        type: "lazylist",
        useSearch: false,
      },
      {
        name: "Название кампании",
        description: "Выберите определённое название кампании.",
        full_name: "",
        id: "advert_list_ads_company",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название группы объявлений",
        description: "Выберите определённую группу объявлений.",
        full_name: "",
        id: "advert_list_ads_group",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "Название объявления",
        description: "Выберите определённое объявление.",
        full_name: "",
        id: "advert_list_ads_name",
        type: "lazylist",
        useSearch: true,
      },
      {
        name: "ID кампании",
        description: "Выберите определённую компания по её ID.",
        full_name: "",
        id: "advert_id_ads_company",
        type: "number",
      },
      {
        name: "ID группы объявлений",
        description: "Выберите определённую группу объявлений по его ID.",
        full_name: "",
        id: "advert_id_ads_group",
        type: "number",
      },
      {
        name: "ID объявления",
        description: "Выберите определённое объявление по его ID.",
        full_name: "",
        id: "advert_id_ads",
        type: "number",
      },
    ],
  },
  {
    name: "Реклама (АР-РК)",
    color: "#a3333d",
    icon: "DingdingOutlined",
    root: true,
    id: "ads_filter",
    description:
        "Можно выбрать определённую рекламу для вывода в отчётах. Работает только с группировками из раздела: Реклама.",
    children: [
      {
        name: "Источник рекламы",
        description: "Выберите определённый источник рекламы.",
        full_name: "",
        id: "list_ads_source",
        type: "list",
      },
      {
        name: "Рекламный кабинет",
        description: "Выберите определённый рекламный кабинет.",
        full_name: "",
        id: "list_ads_cabinet",
        type: "lazylist",
        useSearch: false,
      },
    ],
  },
  {
    name: "Реклама (РР-РК)",
    color: "#a3333d",
    icon: "DingdingOutlined",
    root: true,
    id: "advert_ads_filter",
    description:
        "Можно выбрать определённую рекламу для вывода в отчётах. Работает только с группировками из раздела: Реклама.",
    children: [
      {
        name: "Источник рекламы",
        description: "Выберите определённый источник рекламы.",
        full_name: "",
        id: "advert_list_ads_source",
        type: "list",
      },
      {
        name: "Рекламный кабинет",
        description: "Выберите определённый рекламный кабинет.",
        full_name: "",
        id: "advert_list_ads_cabinet",
        type: "lazylist",
        useSearch: false,
      },
    ],
  },
  {
    name: "Школы (CRM)",
    color: "#79a072",
    icon: "UserAddOutlined",
    root: true,
    id: "schools_filter",
    children: [
      { name: "id школы", full_name: "", id: "crm_school_id", type: "text" },
      {
        name: "Дата подключения",
        full_name: "",
        id: "crm_school_created_at",
        type: "datetimerange",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "crm_school_name",
        type: "lazylist",
      },
      {
        name: "Поддомен GC",
        full_name: "",
        id: "crm_school_domain",
        type: "text",
      },
      {
        name: "Основной домен",
        full_name: "",
        id: "crm_school_main_domain",
        type: "text",
      },
      {
        name: "Статус школы в сервисе",
        full_name: "",
        id: "crm_school_status",
        type: "list",
      },
      {
        name: "Дата завершения доступа",
        full_name: "",
        id: "crm_school_date",
        type: "datetimerange",
      },
      {
        name: "Выручка за последние 30 дней",
        full_name: "",
        id: "income_last_30_days",
        type: "numberrange",
      },
      {
        name: "Кол-во дней до конца доступа",
        full_name: "",
        id: "number_days_access",
        type: "numberrange",
      },
      {
        name: "Последняя активность в школе",
        full_name: "",
        id: "last_activity",
        type: "datetimerange",
      },
      {
        name: "Email сотрудника",
        full_name: "",
        id: "employee_email",
        type: "text",
      },
    ],
  },
  {
    name: "Запросы (CRM)",
    color: "#79a072",
    icon: "UserAddOutlined",
    root: true,
    id: "schools_filter",
    children: [
      {
        name: "id школы",
        full_name: "",
        id: "crm_request_school_id",
        type: "text",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "crm_request_school_name",
        type: "lazylist",
      },
      {
        name: "Дата запроса",
        full_name: "",
        id: "crm_request_date",
        type: "datetimerange",
      },
      {
        name: "id ошибки",
        full_name: "",
        id: "crm_request_error_id",
        type: "text",
      },
      {
        name: "Наличие ошибок",
        full_name: "",
        id: "crm_request_error_status",
        type: "list",
        items: [
          {
            name: "Нет ошибок",
            id: 1,
          },
          {
            name: "Есть ошибки",
            id: 2,
          },
        ],
      },
      {
        name: "Email сотрудника",
        full_name: "",
        id: "crm_request_user_email",
        type: "text",
      },
      {
        name: "id тотал запроса",
        full_name: "",
        id: "crm_request_total_id",
        type: "text",
      },
      {
        name: "id базового запроса",
        full_name: "",
        id: "crm_request_base_id",
        type: "text",
      },
    ],
  },
  {
    name: "Пользователи (CRM)",
    color: "#b481b0",
    icon: "UserOutlined",
    root: true,
    id: "user_filter",
    children: [
      {
        name: "Дата регистрации",
        full_name: "",
        id: "crm_user_created_at",
        type: "datetimerange",
      },
      {
        name: "Тип пользователя",
        full_name: "",
        id: "crm_user_role",
        type: "list",
      },
      { name: "Email", full_name: "", id: "crm_user_email", type: "text" },
      { name: "Телефон", full_name: "", id: "crm_user_phone", type: "text" },
      {
        name: "Телеграм",
        full_name: "",
        id: "crm_user_telegram",
        type: "text",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "crm_user_list_of_schools",
        type: "list",
      },
      {
        name: "id школы",
        full_name: "",
        id: "crm_user_school_id",
        type: "number",
      },
    ],
  },
  {
    name: "Заказы (CRM)",
    color: "#704c5e",
    icon: "ShoppingOutlined",
    root: true,
    id: "order_filter",
    children: [
      {
        name: "Дата заказа",
        full_name: "",
        id: "crm_data_order",
        type: "datetimerange",
        description: "",
      },
      {
        name: "Номер заказа",
        full_name: "",
        id: "crm_order_number",
        type: "number",
        description: "",
      },
      {
        name: "Тариф",
        full_name: "",
        id: "crm_order_tariff_list",
        type: "list",
        description: "",
      },
      {
        name: "Статус заказа",
        full_name: "",
        id: "crm_orders_status",
        type: "list",
        description: "",
      },
      {
        name: "id школы",
        full_name: "",
        id: "crm_order_school_id",
        type: "number",
        description: "",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "crm_order_school_name",
        type: "list",
        description: "",
      },
      {
        name: "id пользователя",
        full_name: "",
        id: "crm_order_user_id",
        type: "number",
        description: "",
      },
      {
        name: "Email",
        full_name: "",
        id: "crm_order_email",
        type: "text",
        description: "",
      },
    ],
  },
  {
    name: "Платежи (CRM)",
    color: "#426b69",
    icon: "DollarOutlined",
    root: true,
    id: "pay_filter",
    children: [
      {
        name: "Дата платежа",
        full_name: "",
        id: "crm_payment_created_date",
        type: "datetimerange",
        description: "",
      },
      {
        name: "id школы",
        full_name: "",
        id: "crm_payment_school_id",
        type: "number",
        description: "",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "crm_payment_school_name",
        type: "list",
        description: "",
      },
      {
        name: "Тип платежа",
        full_name: "",
        id: "crm_payment_type",
        type: "list",
        description: "",
      },
      {
        name: "Статус платежа",
        full_name: "",
        id: "crm_payment_status",
        type: "list",
        description: "",
      },
      {
        name: "Счёт пополнения",
        full_name: "",
        id: "crm_payment_check",
        type: "list",
        description: "",
      },
    ],
  },

  {
    name: "Активность (CRM)",
    color: "#6b4248",
    icon: "DollarOutlined",
    root: true,
    id: "activity_filter",
    children: [
      {
        name: "Дата посещения",
        full_name: "",
        id: "date_of_visit",
        type: "datetimerange",
        description: "",
      },
      {
        name: "id школы",
        full_name: "",
        id: "activity_school_id",
        type: "number",
        description: "",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "activity_school_name",
        type: "list",
        description: "",
      },
      {
        name: "id пользователя",
        full_name: "",
        id: "activity_user_id",
        type: "number",
        description: "",
      },
      {
        name: "Email",
        full_name: "",
        id: "activity_user_email",
        type: "text",
        description: "",
      },
      {
        name: "ip адрес",
        full_name: "",
        id: "activity_ip_address",
        type: "text",
        description: "",
      },
      {
        name: "Тип пользователя",
        full_name: "",
        id: "activity_role",
        type: "list",
        description: "",
      },
    ],
  },
  {
    name: "Детализация (CRM)",
    color: "#426b47",
    icon: "DollarOutlined",
    root: true,
    id: "details_filter",
    children: [
      {
        name: "id школы",
        full_name: "",
        id: "detail_school_id",
        type: "number",
        description: "",
      },
      {
        name: "Название школы",
        full_name: "",
        id: "detail_school_name",
        type: "list",
        description: "",
      },
      {
        name: "Дата детализации",
        full_name: "",
        id: "detail_date",
        type: "datetimerange",
        description: "",
      },
      {
        name: "Название операции",
        full_name: "",
        id: "operation_name_list",
        type: "list",
        description: "",
      },
      {
        name: "Сумма основного счёта",
        full_name: "",
        id: "main_balance",
        type: "numberrange",
        description: "",
      },
      {
        name: "Сумма бонусного счёта",
        full_name: "",
        id: "bonus_balance",
        type: "numberrange",
        description: "",
      },
      {
        name: "Общий баланс",
        full_name: "",
        id: "overall_balance",
        type: "numberrange",
        description: "",
      },
    ],
  },
  {
    name: "Комментарии (CRM)",
    color: "#5e8dc6",
    icon: "UserAddOutlined",
    root: true,
    id: "webinarcomments_filter",
    children: [{ name: "id школы", full_name: "", id: "", type: "text" }],
  },
];
export const calendarTypes = [
  "today",
  "yesterday",
  "thisweek",
  "lastweek",
  "thismonth",
  "lastmonth",
  "thisquart",
  "lastquart",
  "thisyear",
  "lastyear",
  "last7days",
  "last14days",
  "last30days",
  "last365days",
];
export function updateFilters() {
  let filtersData = structuredClone(filters);
  const filter = filtersData[1].children.find(
      (el: any) => el.id === "period_from_advert_to_reg"
  );
  if (location.pathname === "/users/ad-reports" && !filter) {
    const adFilter = {
      name: "Период от рекламы до регистрации (дни)",
      full_name: "Период от рек. до регистрации",
      id: "period_from_advert_to_reg",
      type: "numberrange",
      description: "",
    };
    filters[1].children.push(adFilter);
  }

  return filtersData;
}
export let data = updateFilters();
type StringObject = {
  [key: string]: string;
};

let groupingsToFilters: StringObject = {
  reg_number: "applications_order_number",
  order_offers: "list_sentence",
  reg_url: "url_applications",
  reg_utm_source: "applications_utm_source",
  reg_utm_medium: "applications_utm_medium",
  reg_utm_campaign: "applications_utm_campaign",
  reg_utm_content: "applications_utm_content",
  reg_utm_term: "applications_utm_term",
  reg_utm_group: "applications_utm_group",
  reg_tag: "app_tags",
  reg_statuses:"app_statuses",
  app_status:"app_status",
  app_offer_tags:"app_offer_tags",
  reg_device_type: "applications_list_type",
  reg_device_model: "applications_list_device",
  reg_device_os: "applications_list_os_name",
  reg_device_os_ver: "applications_list_os_version",
  reg_browser: "applications_list_browser_name",
  reg_browser_ver: "applications_list_browser_version",
  reg_browser_lang: "applications_list_browser_language",
  reg_title: "name_applications",
  reg_geo_country: "list_country",
  reg_geo_region: "list_region",
  reg_geo_city: "list_city",
  reg_geo_tz: "list_time_loop",
  order_tag: "list_tags",
  order_tag_3: "list_tags_3",
  order_status: "list_status_order",
  order_manager: "list_managers",
  order_utm_source: "order_utm_source",
  order_utm_medium: "order_utm_medium",
  order_utm_campaign: "order_utm_campaign",
  order_utm_content: "order_utm_content",
  order_utm_term: "order_utm_term",
  order_utm_group: "order_utm_group",
  payment_status: "list_status_pay",
  payment_type: "list_type_pay",
  web_name: "list_webinar",
  ads_source: "list_ads_source",
  ads_cabinet: "list_ads_cabinet",
  ads_campaign: "list_ads_company",
  ads_adset: "list_ads_group",
  ads_adv: "list_ads_name",
  user_email: "user_email",
  user_id: "id_get_cource",
  user_gender: "list_gender",
  user_age: "list_ages_list",
  order_number: "order_number",
  payment_id: "id_payments",
  ads_adv_id: "id_ads",
  ads_adset_id: "id_ads_group",
  ads_campaign_id: "id_ads_company",
};
const addSuffixToGroupings = (suffix: string) => {
  let finalObject: StringObject = {};
  for (const key in groupingsToFilters) {
    const newKey = key + suffix;
    finalObject[newKey] = groupingsToFilters[key];
  }
  return finalObject;
};
switch (window.location.pathname) {
  case "/users/order-reports": {
    groupingsToFilters = addSuffixToGroupings("_2");
    break;
  }
  case "/users/payment-reports": {
    groupingsToFilters = addSuffixToGroupings("_3");
    break;
  }
  case "/users/webinar-reports": {
    groupingsToFilters = addSuffixToGroupings("_4");
    break;
  }
}
export { groupingsToFilters };

