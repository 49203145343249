import { Dispatch, FC, SetStateAction, useRef, useState } from "react"

import { AnyAction } from "@reduxjs/toolkit"
import { Button, Input, InputRef, Modal, Select } from "antd"
import { useDispatch } from "react-redux"
import { ThunkDispatch as TDispatch } from "redux-thunk"

import {
  createTagRequest,
  detachTagsFromReport,
  fetchAttachTagsToReport,
  renameReportOrDashboard,
  updateItemAccess,
} from "../../../../../../app/providers/redux/slices/reports/params/reports/reportsSlice"
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../helpers/utils/ui"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import AddReportTG from "../../../reports/un-title/components/modals/AddReportTG/AddReportTG"
import { STATIC_TAGS } from "../constants/tags"
import { ItemTypee } from "../TabContent"
import { TagAttachModal, TagAttachModalProps } from "../TagAttachModal"

export type ThunkDispatch = TDispatch<any, undefined, AnyAction>;

export const enum ModalKey {
  Favorite = "Favorite",
  Rename = "Rename",
  CopyLink = "CopyLink",
  CopyData = "CopyData",
  Tags = "Tags",
  Add = "Add",
  SendTG = "SendTG",
  Accesses = "Accesses",
  RemoveAction = "RemoveAction",
}

interface ModalBaseProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

interface EditModalProps {
  isDashboard: boolean;
  item: ItemTypee;
}
interface CreateTagModalProps {
  isDashboard: boolean;
}
interface AddReportTGModalProps {
  report: {
    visible: boolean;
    title: string;
    id: number;
  };
}
interface InternalTagAttachModalProps {
  isDashboard: TagAttachModalProps["reportType"];
  item: ItemTypee;
}
interface AccesModalProps {
  isDashboard: boolean;
  item: ItemTypee;
}

export const AccesModal: FC<ModalBaseProps & AccesModalProps> = ({
  visible,
  setVisible,
  isDashboard,
  item,
}) => {
  const schoolId = useTypedSelector(
    (state) => state.currentSchool.selectedSchool?.id
  )
  const dispatch: ThunkDispatch = useDispatch()

  const handleCloseAccessModal = () => setVisible(false)
  const handleUpdateAccess = async (newAccessId: number) => {
    if (!item.id || !schoolId) return
    if (!item) return

    try {
      await dispatch(
        updateItemAccess({
          schoolId,
          id: item.id,
          accessId: newAccessId,
          itemType: isDashboard ? "dashboard" : "report",
          values: item.values,
        })
      ).unwrap()

      showSuccessMessage("Доступ успешно обновлен")
      handleCloseAccessModal
    } catch (error) {
      showErrorMessage("Не удалось обновить доступ")
    }
  }

  return (
    <Modal
      title="Управление доступом"
      open={visible}
      onCancel={handleCloseAccessModal}
      footer={null}
      width={300}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Button
          type={item.access_id === 2 ? "primary" : "default"}
          onClick={() => handleUpdateAccess(2)}
        >
          Всем сотрудникам
        </Button>
        <Button
          type={item.access_id === 1 ? "primary" : "default"}
          onClick={() => handleUpdateAccess(1)}
        >
          Только мне
        </Button>
      </div>
    </Modal>
  )
}

export const EditModal: FC<ModalBaseProps & EditModalProps> = ({
  visible,
  setVisible,
  item,
  isDashboard,
}) => {
  const dispatch: ThunkDispatch = useDispatch()
  const reportNameRef = useRef<InputRef>(null)
  const schoolId = useTypedSelector(
    (state) => state.currentSchool.selectedSchool?.id
  )

  const edit = () => {
    if (schoolId) {
      const newName = reportNameRef.current?.input?.value
        ? reportNameRef.current?.input?.value
        : ""

      dispatch(
        renameReportOrDashboard({
          schoolId,
          id: item.id,
          newName,
          isDashboard,
        })
      )
        .unwrap()
        .then(() => {
          setVisible(false)
        })
        .catch(() => {
          showErrorMessage("Ошибка при переименовании")
        })
    }
  }

  return (
    <Modal
      title="Переименовать"
      open={visible}
      onOk={edit}
      onCancel={() => {
        setVisible(false)
      }}
    >
      <Input ref={reportNameRef} placeholder="Введите новое имя" />
    </Modal>
  )
}

export const CreateTagModal: FC<ModalBaseProps & CreateTagModalProps> = ({
  visible,
  setVisible,
  isDashboard,
}) => {
  const [ kindId, setKindId ] = useState(() => (isDashboard ? 1 : 2))

  const dispatch: ThunkDispatch = useDispatch()
  const reportTagRef = useRef<InputRef>(null)
  const schoolId = useTypedSelector(
    (state) => state.currentSchool.selectedSchool?.id
  )

  const handleCreateTag = () => {
    if (!schoolId) {
      showErrorMessage("Школа не выбрана")
      return
    }

    const tagName = reportTagRef.current?.input?.value
    if (!tagName) {
      showErrorMessage("Введите название тега")
      return
    }

    dispatch(
      createTagRequest({
        schoolId,
        kindId,
        tagName,
      })
    )
      .unwrap()
      .then(() => {
        showSuccessMessage("Тег успешно создан")
        setVisible(false)
      })
      .catch((error: any) => {
        showErrorMessage(error.message || "Ошибка создания тега")
      })
  }

  return (
    <Modal
      title="Создать новый тег"
      open={visible}
      onOk={handleCreateTag}
      onCancel={() => setVisible(false)}
      okText="Создать"
      cancelText="Отмена"
    >
      <Input
        ref={reportTagRef}
        placeholder="Введите название тега"
        style={{ marginBottom: 16 }}
      />
      <Select
        defaultValue={1}
        style={{ width: "100%" }}
        onChange={(value: 1 | 2) => setKindId(value)}
      >
        <Select.Option value={1}>Для отчетов</Select.Option>
        <Select.Option value={2}>Для дашбордов</Select.Option>
      </Select>
    </Modal>
  )
}

export const AddReportTGModal: FC<ModalBaseProps & AddReportTGModalProps> = ({
  visible,
  report,
  setVisible,
}) => {
  return (
    <AddReportTG
      isVisible={visible}
      setVisible={setVisible}
      report={report}
      setRefetch={() => null}
    />
  )
}

export const InternalTagAttachModal: FC<
  ModalBaseProps & InternalTagAttachModalProps
> = ({ isDashboard, item, visible, setVisible }) => {
  const dispatch: ThunkDispatch = useDispatch()
  const { getSavedDashboards, getSavedReports } = useActions()
  const { tags, schoolId } = useTypedSelector((state) => ({
    schoolId: state.currentSchool.selectedSchool?.id,
    tags: state.reports.tags,
  }))

  const getAttachedTags = () => {
    if (!item) return []

    if (Array.isArray(item.tags)) {
      return item.tags.map((t: any) => ({
        id: t.id || Number(t[0]),
        name: t.name || String(t[1]),
      }))
    }

    if (item.tags && typeof item.tags === "object") {
      return Object.entries(item.tags).map(([ id, name ]) => ({
        id: Number(id),
        name: String(name),
      }))
    }

    return []
  }

  const handleAttachTags = async (tagIds: number[]) => {
    if (!item.id) return

    try {
      await dispatch(
        fetchAttachTagsToReport({
          reportId: item.id,
          tagIds,
        })
      ).unwrap()
      
      if (schoolId) {
        if (isDashboard) {
          getSavedDashboards(schoolId)
        } else {
          getSavedReports(schoolId)
        }
      }
    } catch (error) {
      showErrorMessage("Не удалось привязать теги")
    }
  }

  const handleDetachTags = async (tagIds: number[]) => {
    if (!item.id) return

    try {
      await dispatch(
        detachTagsFromReport({
          reportId: item.id,
          tagIds,
        })
      ).unwrap()

      if (schoolId) {
        if (isDashboard) {
          getSavedDashboards(schoolId)
        } else {
          getSavedReports(schoolId)
        }
      }
    } catch (error) {
      showErrorMessage("Не удалось отвязать теги")
    }
  }

  return (
    <TagAttachModal
      visible={visible}
      onClose={() => setVisible(false)}
      reportId={item.id}
      reportName={item?.name || "Без названия"}
      activeMainTab={isDashboard ? "dashboards" : "reports"}
      reportType={isDashboard ? "dashboards" : "reports"}
      attachedTags={getAttachedTags()}
      staticTags={STATIC_TAGS}
      allTags={isDashboard ? tags.dashboards : tags.reports}
      onAttach={handleAttachTags}
      onDetach={handleDetachTags}
    />
  )
}
