import {
  FC,
  MouseEventHandler,
  useState,
} from "react"

import {
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LockOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
  TagOutlined,
} from "@ant-design/icons"
import {
  Button,
  Popconfirm,
  Popover,
} from "antd"
import { useDispatch } from "react-redux"

import { AccesModal, AddReportTGModal, EditModal, InternalTagAttachModal, ModalKey, ThunkDispatch } from "./modals"
import {
  copyReportOrDashboard,
  deleteReportOrDashboard,
  toggleFavorite,
} from "../../../../../../app/providers/redux/slices/reports/params/reports/reportsSlice"
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../helpers/utils/ui"
import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { ItemTypee } from "../TabContent"

const modalButtonStyle = {
  border: "none",
  background: "none",
  cursor: "pointer",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s",
  ":hover": {
    color: "blue",
  },
}

interface PopoverContentProps {
  itemId: ItemTypee["id"];
  isDashboard: boolean;
}

interface MenuItem {
  key: ModalKey;
  text: string;
  Icon: any;
  onClick?: MouseEventHandler<HTMLElement>;
  modal?: any;
  modalProps?: any;
}

const MenuItem: FC<MenuItem> = ({
  text,
  Icon,
  onClick,
}) => {
  return (
    <Button
      style={modalButtonStyle}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
      key={text}
    >
      <Icon style={{ fontSize: 15, marginRight: "8px" }} />
      <span style={{ fontSize: 15 }}>{text}</span>
    </Button>
  )
}

const renderMenuItem = (props: MenuItem) => (
  <MenuItem {...props} key={props.key} />
)

const SuperPermission = new Set([ "admin", "superadmin" ])

export const ReportMenu: FC<PopoverContentProps> = ({ itemId, isDashboard }) => {
  const dispatch: ThunkDispatch = useDispatch()
  const {
    setReportFavorite,
    setDashboardFavorite,
    getSavedDashboards,
    getSavedReports,
    setSavedDashboards,
    setSavedReports,
  } = useActions()

  const {
    savedReports,
    savedDashboards,
    schoolId,
    currentUserRole,
    isCompare,
  } = useTypedSelector((state) => ({
    savedDashboards: state.reports.savedDashboards,
    savedReports: state.reports.savedReports,
    schoolId: state.currentSchool.selectedSchool?.id,
    currentUserRole: state.currentUser.role,
    isCompare: state.meta.isCompare,
  }))

  const [ modalVisible, setModalVisible ] = useState<
    Partial<Record<ModalKey, boolean>>
  >({
    [ModalKey.Rename]: false,
    [ModalKey.Tags]: false,
    [ModalKey.SendTG]: false,
    [ModalKey.Accesses]: false,
  })

  const currentArray = isDashboard ? savedDashboards : savedReports
  const item: ItemTypee = currentArray.find(
    (el: ItemTypee) => el.id === itemId
  )

  const handleToggleFavorite: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation()
    const newFavoriteStatus = !item.is_favorite
    const setFavorite =
      isDashboard ? setDashboardFavorite : setReportFavorite

    setFavorite({
      id: item.id,
      isFavorite: newFavoriteStatus,
    })

    dispatch(toggleFavorite({ id: item.id, isFavorite: item.is_favorite }))
      .unwrap()
      .catch(() => {
        setFavorite({
          id: item.id,
          isFavorite: newFavoriteStatus,
        })
      })
  }
  const handeCopyReport: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation()

    const reportLink = `${process.env.REACT_APP_BASE_URL}${location.pathname}?report_id=${item.hash}`
    navigator.clipboard
      .writeText(reportLink)
      .then(() => {
        showSuccessMessage("Ссылка скопирована")
      })
      .catch(() => {
        showErrorMessage("Ошибка при копировании ссылки отчета")
      })
  }
  const handleCopyReportOrDashboard: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation()

    if (!schoolId) {
      showErrorMessage("Школа не выбрана")
      return
    }
    const newName = `${item.name} (копия)`

    dispatch(
      copyReportOrDashboard({
        schoolId,
        id: item.id,
        isDashboard,
        newName,
        values: item.values,
        pathName: location.pathname,
      })
    )
      .unwrap()
      .then(() => {
        if (isDashboard) {
          getSavedDashboards(schoolId)
        } else {
          getSavedReports(schoolId)
        }
      })
      .catch(() => {
        showErrorMessage(
          isDashboard
            ? "Ошибка копирования дашборда"
            : "Ошибка копирования отчёта"
        )
      })
  }
  const handeConfirmRemove = () => {
    if (!isCompare && schoolId) {

      if (isDashboard) {
        setSavedDashboards([ item ])
      } else {
        setSavedReports([ item ])
      }

      dispatch(
        deleteReportOrDashboard({
          schoolId,
          id: item.id,
          isDashboard: isDashboard,
        })
      )
        .unwrap()
        .catch(() => {
          if (isDashboard) {
            getSavedDashboards(schoolId)
          } else {
            getSavedReports(schoolId)
          }
          showErrorMessage("Не удалось удалить")
        })
    }
  }
  const handleRemoveReport = (id: any) => {
    if (!isCompare && schoolId) {

      if (isDashboard) {
        setSavedDashboards(savedDashboards.filter((d) => d.id !== id))
      } else {
        setSavedReports(savedReports.filter((r) => r.id !== id))
      }

      dispatch(
        deleteReportOrDashboard({
          schoolId,
          id: id,
          isDashboard: isDashboard,
        })
      )
        .unwrap()
        .catch(() => {
          if (isDashboard) {
            getSavedDashboards(schoolId)
          } else {
            getSavedReports(schoolId)
          }
          showErrorMessage("Не удалось удалить")
        })
    }
  }

  const Actions: MenuItem[] = [
    {
      key: ModalKey.Favorite,
      text: item.is_favorite ? "Убрать из избранного" : "В избранное",
      Icon: item.is_favorite ? StarFilled : StarOutlined,
      onClick: handleToggleFavorite,
    },
    {
      key: ModalKey.Rename,
      text: "Переименовать",
      Icon: EditOutlined,
      onClick: () =>
        setModalVisible((prev) => ({ ...prev, [ModalKey.Rename]: true })),
      modal: EditModal,
      modalProps: {
        item,
        isDashboard,
      },
    },
    {
      key: ModalKey.CopyLink,
      text: "Копировать ссылку",
      Icon: EditOutlined,
      onClick: handeCopyReport,
    },
    {
      key: ModalKey.CopyData,
      text: isDashboard ? "Копировать дашборд" : "Копировать отчёт",
      Icon: EditOutlined,
      onClick: handleCopyReportOrDashboard,
    },
    {
      key: ModalKey.Tags,
      text: "Теги",
      Icon: TagOutlined,
      onClick: () =>
        setModalVisible((prev) => ({ ...prev, [ModalKey.Tags]: true })),
      modal: InternalTagAttachModal,
      modalProps: {
        isDashboard,
        item,
      },
    },
    isDashboard
      ? {
          key: ModalKey.Add,
          text: "Добавить в сводку",
          Icon: PlusOutlined,
          onClick: () => null,
        }
      : {
          key: ModalKey.SendTG,
          text: "Отправка в TG",
          Icon: ClockCircleOutlined,
          onClick: () =>
            setModalVisible((prev) => ({ ...prev, [ModalKey.SendTG]: true })),
          modal: AddReportTGModal,
          modalProps: {
            report: {
              visible: true,
              title: item.name,
              id: item.id,
            },
          },
        },
  ]

  const ActionsSuperUser: MenuItem[] = [
    {
      key: ModalKey.Accesses,
      text: "Управление доступом",
      Icon: LockOutlined,
      onClick: () =>
        setModalVisible((prev) => ({ ...prev, [ModalKey.Accesses]: true })),
      modal: AccesModal,
      modalProps: {
        item,
        isDashboard,
      },
    },
  ]

  const removeAction = {
    key: ModalKey.RemoveAction,
    text: "Удалить",
    Icon: DeleteOutlined,
    onClick: handleRemoveReport,
  }

  const ActionsMap = Actions.map(renderMenuItem)
  const ActionsSuperUserMap = SuperPermission.has(currentUserRole)
    ? ActionsSuperUser.map(renderMenuItem)
    : null

  const PopoverProps = Object.values(modalVisible).some(Boolean)
    ? {
        open: false,
      }
    : {}

  return (
    <>
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {ActionsMap}
            {ActionsSuperUserMap}
            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={handeConfirmRemove}
              okText="Удалить"
              cancelText="Отмена"
              placement="top"
            >
              {renderMenuItem(removeAction)}
            </Popconfirm>
          </div>
        }
        placement="bottomRight"
        style={{ borderRadius: "10px" }}
        {...PopoverProps}
      >
        <div
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={(e) => e.stopPropagation()}
        >
          <EllipsisOutlined />
        </div>
      </Popover>
      {[ ...Actions, ...ActionsSuperUser ].map((Action) => {
        const Modal = Action.modal
        return Modal ? (
          <Modal
            visible={modalVisible[Action.key]}
            setVisible={(visible: boolean) =>
              setModalVisible((prev) => ({ ...prev, [Action.key]: visible }))
            }
            {...Action.modalProps}
          />
        ) : null
      })}
    </>
  )
}
