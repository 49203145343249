import React, { FC, useEffect, useMemo, useRef, useState } from "react"


import { Column, Gauge, Pie , Funnel } from "@ant-design/plots"
import moment from "moment"

import BubbleChartType from "./components/bubble-chart/BubbleChart"
import ButtonsType from "./components/ButtonsType"
import ChartLabel from "./components/ChartLabel"
import { useModalState } from "./ContextModal"
import { IChartData } from "../../../../../app/providers/redux/slices/reports/chart/types"
import {
    convertYToNumber, endsWithNumber,
    getAverageSeries, getPieTooltip, getPlanSeries,
    handleCustomMetricGroup, handleEmptyDescription, isNumeric
} from "../../../../../helpers/utils/functions"
import {
    appendDetails,
    buildSeriesForBubbleChart,
    calculateMaxMinBubbleChart,
    checkMetricsGroups,
    filterAxisShow,
    findRowRecurse,
    formatCellData,
    formatConvertData,
    formatMoneyData,
    getGroupDataForBarChart,
    predictDotsCount,
} from "../../../../../helpers/utils/reports"
import { showErrorMessage } from "../../../../../helpers/utils/ui"
import { unApi } from "../../../api/endpoints/reports/unApi"
import { groupMapping } from "../../../constants/reports"
import { useActions } from "../../../hooks/useActions"
import { useTitles } from "../../../hooks/useTitles"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import Loading from "../../loader/Loader"
import ChartWrap from "../ChartWrap"
import { getTitleYaxis } from "../cohort/components/utils"


type ChartGroups = {
    [key: string]: string[];

};
type Props = {
    blockId: string
    date: string
    isShow: boolean
}


const ChartUniversal: FC<Props> = ({ blockId, date, isShow = false }) => {
    const titles = useTitles()
    const period = useTypedSelector((state) => state.period)
    const { detailsDashboard: detailsDashbo } = useTypedSelector((state) => state.details)
    const details = useTypedSelector((state) => blockId ? state.details.detailsDashboardBlocks[blockId] : state.details.details)
    const detailsDashboard = isShow ? detailsDashbo : details
    const { isAllBlocksFetching } = useTypedSelector((state) => state.meta)
    const { filters } = useTypedSelector((state) => blockId ? state.filter.blocks[blockId] ?? { filters: [] } : state.filter)
    const filtersStorageValue = JSON.parse(localStorage.getItem("dashboardFilters") || "") || []

    const { filters: commonFilter } = useTypedSelector((state) => blockId ? isShow ? state.filter : filtersStorageValue : filtersStorageValue)
    const commonFilters = commonFilter?.length === 0 && isShow ? filtersStorageValue : commonFilter
    const { attributesDashboard: attrDashboard } = useTypedSelector((state) => state.attributes)
    const attributes = useTypedSelector((state) => blockId ? state.attributes.attributesDashboardBlocks[blockId] : state.attributes.attributes)
    const attributesDashboard = isShow ? attrDashboard : attributes
    const {
        metrics,
        groupings,
        groupsChecked,
        metricsChecked,
        totalChecked,
        tableData,
        percentages,
        sortTable,
    } = useTypedSelector((state) => blockId ? state.table.blocks[blockId] : state.table)
    const { chartType, series, cumulativeChart } = useTypedSelector((state) => blockId ? state.chart.blocks[blockId] : state.chart)

    const { modalState2 } = useModalState()
    const {
        isCompare,
        isChartLoading,
        currency,
        isChartCompareFetching,
        isChartFetching,
    } = useTypedSelector((state) => blockId ? state.meta.blocks[blockId] : state.meta)
    const { compareFilters, comparePeriod } = useTypedSelector(
        (state) => state.compare
    )

    const {
        setSeries,
        setLabels,
        setMetaChartLoading,
        setTotalChecked,
        setGroupingsChecked,
        setMetricsChecked,
        setMetaChartFetching,
        setDetails,
        setDashboardDetails,
        setMetaCompareChartFetching,
        setTableData,
        setAllBlocksFetching,
        setMetaFilterUpdate,
        setAllDataFetching
    } = useActions()

    const [ data, setData ] = useState<any>({})
    let [ _metricsChecked, _setMetricsChecked ] = useState<any>([])
    const [ compareLoading, setCompareLoading ] = useState([
        { time: 0, loading: false },
    ])
    const [ selectedGroupingType, setSelectedGroupingType ] = useState<any>(null)
    const [ checkedMetrics, setCheckedMetrics ] = useState<any>([])
    const [ prevChartType, setPrevChartType ] = useState("line")
    const [ errorMessage, setErrorMessage ] = useState(
        "Нет данных, соответствующих условиям отчёта"
    )
    const [ yaxis, setYaxis ] = useState<any[]>([])

    const seriesForBubbleChart = useMemo(
        () =>
            buildSeriesForBubbleChart(
                groupsChecked,
                totalChecked,
                chartType,
                tableData,
                metricsChecked
            ),
        [
            metricsChecked,
            chartType,
            groupsChecked,
            totalChecked,
            tableData,
            series,
            series.length,
        ]
    )

    useEffect(() => {
        setPrevChartType(chartType)

        if (prevChartType === "bubble") {
            setTotalChecked()
            setGroupingsChecked({ data: [], blockId })

            setMetricsChecked({ data: [ _metricsChecked[0] ], blockId })
            _setMetricsChecked([ _metricsChecked[0] ])
        }
    }, [ chartType ])

    useEffect(() => {
        if (!isChartLoading) {
            let loadingOff = false

            compareLoading.forEach((item) => {
                if (item.loading) {
                    loadingOff = true
                }
            })

            if (loadingOff) {
                const newLoadingData = compareLoading.map((item) => ({
                    time: item.time,
                    loading: false,
                }))

                setCompareLoading(newLoadingData)
            }
        }

        if (isChartLoading) {
            let setLoadingOff = true

            compareLoading.forEach((item) => {
                if (item.loading) {
                    setLoadingOff = false
                }
            })

            if (setLoadingOff && !compareLoading[compareLoading.length - 1].loading) {
                setMetaChartLoading({ value: false, blockId })
            }
        }
    }, [ isChartLoading, compareLoading ])

    useEffect(() => {
        let disableLoading = true

        compareLoading.forEach((item) => {
            if (item.loading) {
                disableLoading = false
            }
        })

        if (disableLoading) {
            setMetaChartLoading({ value: false, blockId })
        }
    }, [ compareLoading ])

    useEffect(() => {
        if (isCompare) {
            let _metrics = metricsChecked
            let _newMetrics: any = []

            _metrics.forEach((_metric: any) => {
                let newMetricLabel = _metric.label
                if (typeof _metric.label === "object") {
                    newMetricLabel = _metric.label.props.children[0]
                }

                let _m = { name: `${_metric.name?.startsWith("_") ? _metric.name?.slice(0, -1) : _metric.name}_2`, label: `${newMetricLabel} (2)` }

                let isAdd = !_metrics.find(
                    (_metricTemp: any) => _metricTemp.name === _m.name
                )

                if (isAdd) {
                    _newMetrics.push(_m)
                }
            })

            _setMetricsChecked(_metrics.concat(_newMetrics))
        } else {
            _setMetricsChecked(metricsChecked)
        }

        setMetaChartFetching({ value: true, blockId })
    }, [ isCompare, metricsChecked ])
    const fetchCompareData = () => {
        const time = Date.now()

        setMetaChartLoading({ value: true, blockId })
        setSeries({ data: [], blockId })
        setCompareLoading((prevState) => [
            ...prevState,
            { time: time, loading: true },
        ])

        const { compareFilters1, compareFilters2 } = compareFilters
        const { comparePeriod1, comparePeriod2 } = comparePeriod

        const format = "YYYY-MM-DD"
        const groupings_names = groupings.map((col: any) => col.dataKey)
        let selected_groups_path = groupsChecked.map((item: any) => item.ids)
        selected_groups_path = selected_groups_path.filter((el) => !el.includes("total"))
        selected_groups_path.push([ "total" ])
        const selected_metrics_names = _metricsChecked.map(
            (item: any) => item.name
        )

        const _comparePeriod1 = {
            start: moment(comparePeriod1.start).format(format),
            end: moment(comparePeriod1.end).format(format),
        }
        const _comparePeriod2 = {
            start: moment(comparePeriod2.start).format(format),
            end: moment(comparePeriod2.end).format(format),
        }
        const data = {
            comparePeriod1: _comparePeriod1,
            comparePeriod2: _comparePeriod2,
            compareFilters1: compareFilters1,
            compareFilters2: compareFilters2,
            attribution: {

                attribution: (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.attribution,
                type_applications: (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.type_applications,
            },
            details: detailsDashboard === "blocks" ? details : detailsDashboard,
            groupings_names,
            selected_groups_path,
            selected_metrics_names,
        }

        unApi
            .getUniversalReportChartData(data, location.pathname)
            .then(function (response) {
                let data = response.data
                if (data.status) {
                    setData({})
                } else {
                    setData(data.data)
                }

                setErrorMessage("Нет данных, соответствующих условиям отчёта")
                if (response.status === 504) {
                    setErrorMessage(
                        "Запрос выполняется слишком долго, из-за большого количества данных. Можно уменьшить период отчёта, удалить не нужные метрики или фильтры."
                    )
                    setData({})
                }

                setCompareLoading((prevState) => [
                    ...prevState.filter((val) => val.time !== time),
                    { time: time, loading: false },
                ])
            })
            .catch(function (error) {
                console.log("error = ", error)

                setCompareLoading((prevState) => [
                    ...prevState.filter((val) => val.time !== time),
                    { time: time, loading: false },
                ])
            }).finally(()=>{
                setAllBlocksFetching({ value: false })
                setMetaFilterUpdate({ value: false }) 
                setAllDataFetching({ value: false })
            })
    }

    const fetchData = (metricsChecked: any) => {
        const time = Date.now()

        setMetaChartLoading({ value: true, blockId })
        setSeries({ data: [], blockId })
        setCompareLoading((prevState) => [
            ...prevState,
            { time: time, loading: true },
        ])

        const format = "YYYY-MM-DD"
        const _period = {
            start: moment(period.start).format(format),
            end: moment(period.end).format(format),
        }
        const groupings_names = groupings.map((col: any) => col.dataKey)

        let selected_groups_path = groupsChecked.map((item: any) => item.ids)
        selected_groups_path = selected_groups_path.filter((el) => !el.includes("total"))
        selected_groups_path.push([ "total" ])
        const selected_metrics_names = metricsChecked.map((item: any) => item.name).filter((name: string) => !/[А-Яа-яЁё]/.test(name))

        if (chartType === "bubble") {
            const data: any = []
            setData(data)
            setMetaChartLoading({ value: false, blockId })
            return
        }

        if (!details || selected_metrics_names.length === 0) {
            setCompareLoading((prevState) => [
                ...prevState.filter((val) => val.time !== time),
                { time: time, loading: false },
            ])
            return
        }

        let reqData = {
            period: _period,
            details: detailsDashboard === "blocks" ? details : detailsDashboard,
            filters: [ ...filters, ...(isShow ? commonFilters : []) ],
            attribution: {
                attribution:
                    typeof (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.attribution === "string"
                        ? [ (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard).attribution ]
                        : (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.attribution,
                type_applications:
                    typeof (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.type_applications === "string"
                        ? [ (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard).type_applications ]
                        : (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.type_applications,
            },
            groupings_names,
            selected_groups_path,
            selected_metrics_names,
        }
        const dotsCount = predictDotsCount(
            reqData.period,
            reqData.selected_groups_path.length,
            reqData.selected_metrics_names.length,
            reqData.details
        )

        if (dotsCount > 1000) {
            if (dotsCount > 3000) {
                const newDetails = "m"

                reqData.details = newDetails
                detailsDashboard === "blocks" ? setDetails(newDetails) : setDashboardDetails(newDetails)
            } else {
                const newDetails = appendDetails(reqData.details)

                reqData.details = newDetails
                detailsDashboard === "blocks" ? setDetails(newDetails) : setDashboardDetails(newDetails)
            }
        }

        unApi
            .getUniversalReportChartData(reqData, location.pathname)
            .then(function (response) {
                if (response.status === 200) {
                    let respData = response.data
                    if (respData.status) {
                        setData({})
                    } else {
                        setData(respData.data)
                    }

                    setErrorMessage("Нет данных, соответствующих условиям отчёта")
                } else if (response.status === 504) {
                    setErrorMessage(
                        "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
                    )
                    setData({})
                }

                setCompareLoading((prevState) => [
                    ...prevState.filter((val) => val.time !== time),
                    { time: time, loading: false },
                ])
            })
            .catch(function (error) {
                console.log("error = ", error)

                if (error.response) {
                    if (error.response.status === 504) {
                        setErrorMessage(
                            "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить не нужные метрики или фильтры."
                        )
                    }
                }

                setData({})
                setCompareLoading((prevState) => [
                    ...prevState.filter((val) => val.time !== time),
                    { time: time, loading: false },
                ])
            }).finally(()=>{
                setAllBlocksFetching({ value: false })
                setMetaFilterUpdate({ value: false }) 
                setAllDataFetching({ value: false })
            })
    }
    const fetchChartData = () => {
        if (metricsChecked.length === 0) {
            return
        }

        if (isCompare) {
            if (!isChartCompareFetching) return
            fetchCompareData()
        } else {
            fetchData(_metricsChecked)
        }

        setMetaCompareChartFetching({ value: false, blockId })
        setMetaChartFetching({ value: false, blockId })
    }
    const loadRows = (loadCurrent?: any) => {

        let __attribution = (attributesDashboard?.attribution === "blocks" ? attributes : attributesDashboard)?.attribution
        let __type_app = (attributesDashboard?.type_applications === "blocks" ? attributes : attributesDashboard)?.type_applications
        let __period = period


        const format = "YYYY-MM-DD"
        const _period = {
            start: moment(__period.start).format(format),
            end: moment(__period.end).format(format),
        }
        const groupings_names = groupings.map((col: any) => col.dataKey)
        const metrics_names = metrics.map((col) => col.dataKey)
        const expanded_ids = loadCurrent ? loadCurrent.clientData.path : []

        let data = {
            period: _period,
            filters: [ ...filters, ...(isShow ? commonFilters : []) ],
            attribution: {
                attribution:
                    typeof __attribution === "string" ? [ __attribution ] : __attribution,
                type_applications:
                    typeof __type_app === "string" ? [ __type_app ] : __type_app,
            },
            groupings_names,
            metrics_names,
            expanded_ids,
        }


        window.localStorage.setItem("prevRequestState", JSON.stringify(data))
        unApi
            .getUniversalReportTableData(data, location.pathname)
            .then((response) => {
                const rowsWithIdAsString = response.data?.rows?.map((row: any) => {
                    if (row.id) {
                        return { ...row, id: row.id.toString() }
                    }
                    return row
                })

                const data = { ...response.data, rows: rowsWithIdAsString }

                console.log("<<= RESPONSE <<=\n", data, "\n<<= RESPONSE <<=")
                if (data.success) {
                    setTableData({ ...data, groupings, blockId })
                } else {
                    if (response.status === 504) {
                        showErrorMessage(
                            "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
                        )
                    }

                    console.log("Ошибка")
                }
            })
            .catch((error) => {
                console.log("error = ", error)
                if (error?.response?.status !== 401) {
                    showErrorMessage("Ошибка при получении данных")
                }
                if (error.response) {
                    if (error.response.status === 504) {
                        showErrorMessage(
                            "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
                        )
                    }
                }

                setData([])
            }).finally(()=>{
                setAllBlocksFetching({ value: false })
                setMetaFilterUpdate({ value: false }) 
                setAllDataFetching({ value: false })
            })
    }

    useEffect(() => {
        fetchChartData()
        loadRows()
    }, [
        chartType,
        period,
        isChartFetching,
        isChartCompareFetching,
        groupsChecked,
        totalChecked,
        _metricsChecked,
        ...(isShow ? commonFilters : [])
    ])
    useEffect(() => {
        if (isAllBlocksFetching) {
            fetchChartData()
            loadRows()
        }
    }, [
        isAllBlocksFetching,
        details,
    ])

    const [ chartGroups, setChartGroups ] = useState<ChartGroups>({
        firstGroup: [],
        secondGroup: [],
        thirdGroup: [],
        fourGroup: [],
        fifthGroup: [],
        sixthGroup: [],
        seventhGroup: [],
        eightGroup: [],
        ninethGroup: [],
        tenthGroup: [],
        eleventhGroup: [],
        twelfthGroup: [],
        thirteenth: [],
        fourteenth: [],
        customMetricGroup: []
    })


    useEffect(() => {
        const groupings_names = groupings.map(
            (col: any) => col.dataKey.split("_")[0]
        )

        if (groupings_names.length > 0) {
            let group = groupings_names[0]

            if (group) {
                setSelectedGroupingType(group)
            } else {
                setSelectedGroupingType(null)
            }
        } else {
            setSelectedGroupingType(null)
        }
    }, [ groupings ])
    useEffect(() => {
        if (!data) {
            setLabels({ data: [], blockId })
            setSeries({ data: [], blockId })
            return
        }

        if (
            _metricsChecked.length === 0 ||
            ([ "line", "area" ].includes(chartType) && Object.keys(data).length === 0)
        ) {
            setLabels({ data: [], blockId })
            setSeries({ data: [], blockId })
            return
        }

        setCheckedMetrics([])
        let newSeries: any = []
        let newGroupsChecked = [ ...groupsChecked, ...totalChecked ]

        let metricFirstName = ""
        let sumSeries = 0
        let newYaxis: any = []
        switch (chartType) {
            case "pie":
                metricFirstName = _metricsChecked[0].name

                newSeries = groupsChecked.map((group: any) => {
                    const findRow = findRowRecurse(tableData.data, group.pathStr)
                    if (!findRow)
                        return {
                            group: group.label,
                            value: 0,
                        }

                    const val = findRow[metricFirstName]
                    return {
                        group: group.label,
                        value: Number(val),
                    }
                })

         
                sumSeries = newSeries.reduce((acc: number, item: any) => acc + item.value, 0)

              
                const remainingValue = tableData.total[metricFirstName] - sumSeries
                newSeries = [
                    {
                        group: "Остальные",
                        value: Math.max(remainingValue, 0), 
                    },
                    ...newSeries,
                ]

                setSeries({ data: newSeries, blockId })
                break

            case "tunnel":
                const colors = [ "#339dff", "#00b379", "#ffc233", "#d52f40", "#5d3faa" ]
                const metricColorMap = new Map()
                let colorIndex = 0

                newSeries = []


                const filteredGroups = _metricsChecked.length === 1 ? [ newGroupsChecked[0] ] : newGroupsChecked

                _metricsChecked.forEach((metric: any) => {
                    let _label = metric.label
                    if (typeof metric.label === "object") {
                        _label = metric.label.props.children[0]
                    }

          
                    if (!metricColorMap.has(_label)) {
                        metricColorMap.set(_label, colors[colorIndex % colors.length])
                        colorIndex++
                    }

                    filteredGroups.forEach((group) => {
                        let val = getGroupDataForBarChart(
                            isCompare,
                            tableData,
                            percentages,
                            metric,
                            group
                        )

                        newSeries.push({
                            key: group.label, 
                            metric: _label, 
                            value: val,
                            color: metricColorMap.get(_label), 
                        })
                    })
                })

        
                newSeries = newSeries.map((item: any) => ({
                    ...item,
                    key: item.key.replace(/&nbsp;/g, " "),
                }))

                setSeries({ data: newSeries, blockId })
                break
            case "speedometer":
                newSeries = []

       
                if (_metricsChecked.length > 1) {
                    _metricsChecked = [ _metricsChecked[0] ]
                }


                if (newGroupsChecked.length > 1) {
                    newGroupsChecked = [ newGroupsChecked[0] ]
                }

        
                if (_metricsChecked.length === 0) {
                    console.warn("Нет доступных метрик для спидометра")
                    break
                }

        
                const selectedMetric = _metricsChecked[0]  
                const selectedGroup = newGroupsChecked[0]  
              
                let _label = typeof selectedMetric.label === "object" ? selectedMetric.label.props.children[0] : selectedMetric.label

                let val = getGroupDataForBarChart(
                    isCompare,
                    tableData,
                    percentages,
                    selectedMetric,
                    selectedGroup
                )

                newSeries.push({
                    metric: _label,
                    value: val
                })

              
                _metricsChecked.forEach((metric: any) => {
                    let modifiedLabel = metric.label

                    if (typeof metric.label === "object") {
                        modifiedLabel = metric.label.props.children[0]
                    }

                    newGroupsChecked.forEach((group) => {
                        const metricLabel = metric && typeof metric.label === "string" ? metric.label : "Метрика"
                        const groupLabel = group && typeof group.label === "string" ? group.label : "группа"
                        const name = `${metricLabel} (${groupLabel})`
                        if (!data[group.pathStr] || !data[group.pathStr][metric.name])
                            return

                        let dataResult = [
                            ...data[group.pathStr][metric.name].map((item: any) => {
                                return { ...item, category: name }
                            }),
                        ]

                        if (percentages.includes(metric.name)) {
                            dataResult = dataResult.map((item, index) => {
                                const y = parseFloat(
                                    String((item.y / data.total[metric.name][index].y) * 100)
                                ).toFixed(2)
                                return { ...item, y }
                            })
                        }

                
                        if (chartType === "speedometer") {
                            newSeries.push({
                                name: name,
                                data: dataResult,
                                dataKey: metric.name,
                                plan: metric?.plan, 
                                label: metric?.label,
                                base_metric_name: metric?.base_metric_name,
                                metric_formula: metric?.metric_formula,
                            })
                        } else {
                            newSeries.push({ name, data: dataResult })
                        }
                    })
                })

                setSeries({ data: newSeries, blockId })
                break

            case "bar":
                _metricsChecked.forEach((metric: any) => {
                    let _label = metric.label
                    if (typeof metric.label === "object") {
                        _label = metric.label.props.children[0]
                    }

                    newGroupsChecked.forEach((group) => {
                        let grp = group.label
                        let val = getGroupDataForBarChart(
                            isCompare,
                            tableData,
                            percentages,
                            metric,
                            group
                        )

                        newSeries = [
                            ...newSeries,
                            {
                                // key: isCompare ? _label.replace(" (2)", "") : _label,
                                key: _label,
                                group: grp,
                                value: val,
                            },
                        ]
                    })
                })
                newSeries = newSeries.map((item: any) => {
                    let regex = /&nbsp;/g
                    const modifiedGroup = item.group.replace(regex, " ")
                    return { ...item, group: modifiedGroup }
                })
                console.log(tableData, newSeries, "series")
                setSeries({ data: newSeries, blockId })
                break

            default:
                // _series = []
                _metricsChecked.forEach((metric: any) => {
                    let modifiedLabel = metric.label

                    if (typeof metric.label === "object") {
                        modifiedLabel = metric.label.props.children[0]
                    }

                    newGroupsChecked.forEach((group) => {
                        const metricLabel = metric && typeof metric.label === "string" ? metric.label : "Метрика"
                        const groupLabel = group && typeof group.label === "string" ? group.label : "группа"
                        const name = `${metricLabel} (${groupLabel})`
                        if (!data[group.pathStr] || !data[group.pathStr][metric.name])
                            return

                        let dataResult = [
                            ...data[group.pathStr][metric.name].map((item: any) => {
                                return { ...item, category: name }
                            }),
                        ]

                        if (percentages.includes(metric.name)) {
                            dataResult = dataResult.map((item, index) => {
                                const y = parseFloat(
                                    String((item.y / data.total[metric.name][index].y) * 100)
                                ).toFixed(2)
                                return { ...item, y }
                            })
                        }

                        if (chartType === "line") {
                            setCheckedMetrics((prevState: any) => {
                                const newMetrics = prevState
                                newMetrics.push(metric.name)
                                return newMetrics
                            })

                            const newCheckedMetrics = checkedMetrics
                            if (
                                !newCheckedMetrics.find((value: any) => value === metric.name)
                            ) {
                                newCheckedMetrics.push(metric.name)
                            }
                            const _group = checkMetricsGroups(
                                metric,
                                name,
                                group.label,
                                newCheckedMetrics
                            )
                            newYaxis.push({
                                seriesName: _group.label,
                                show: _group.show,
                                opposite: true,
                                isCustomMetric: metric.name.startsWith("_"),
                                dataKey: metric.name
                            })
                            newSeries.push({ name: name, data: dataResult, dataKey: metric.name, plan: metric?.plan, label: metric?.label, base_metric_name: metric?.base_metric_name, metric_formula: metric?.metric_formula })
                        } else {
                            newSeries.push({ name, data: dataResult })
                        }
                    })
                })
                if (isCompare) {

                    setYaxis((prev) => prev.map((el, index) => {
                        if (index === 0) {
                            return { ...el, show: true }
                        }
                        return { ...el, show: false }
                    }))
                } else {
                    const minNegativeValue = Math.min(...newSeries.flatMap(({ data }: any) => data.map((el: any) => el.y)))
                    let maxForMinNegativeValue = Number.NEGATIVE_INFINITY
                    if (minNegativeValue < 0) {
                        newSeries.forEach(({ data }: any) => {
                            const minInSeries = Math.min(...data.map((el: any) => el.y))
                            if (minInSeries === minNegativeValue) {
                                const maxInSeries = Math.max(...data.map((el: any) => el.y))
                                maxForMinNegativeValue = Math.max(maxForMinNegativeValue, maxInSeries)
                            }
                        })
                    }
                    let rate = minNegativeValue / maxForMinNegativeValue

                    const maxValue = Math.max(...newSeries.flatMap(({ data }: any) => data.map((el: any) => parseInt(el.y))))

                    const findLargestYForDataKey = (seriesName: string, newSeries: any) => {
                        const series: any = newSeries.find((item: any) => item.name === seriesName)
                        if (series) {
                            const { dataKey } = series
                            const groupToUpdate = dataKey.startsWith("_") ? handleCustomMetricGroup(series, cumulativeChart) : Object.keys(groupMapping).find(
                                (groupName) => {
                                    const cleanedDataKey = endsWithNumber(dataKey) ? dataKey.slice(0, dataKey.length - 2) : dataKey
                                    return !cumulativeChart ? groupMapping[groupName].includes(cleanedDataKey) : groupMapping[groupName].includes(dataKey.replace(/_cumulative/g, ""))
                                }
                            )

                            if (groupToUpdate) {
                                const filteredSeries = newSeries.filter((item: any) => item.dataKey === dataKey)
                                let largestY: any = null

                                filteredSeries.forEach((item: any) => {
                                    const largestYInData = Math.max(...item.data.map((entry: any) => Number(entry.y)))
                                    if (largestY === null || largestYInData > largestY) {
                                        largestY = largestYInData
                                    }
                                })

                                const cleanedSeriesName = seriesName.replace(/\([^)]+\)/g, "").trim()
                                setChartGroups((prev) => {
                                    const updatedGroup = Array.isArray(prev[groupToUpdate]) ? [ ...prev[groupToUpdate] ] : []
                                    if (!updatedGroup.includes(cleanedSeriesName)) {
                                        updatedGroup.push(cleanedSeriesName)
                                    }
                                    return {
                                        ...prev,
                                        [groupToUpdate]: updatedGroup,
                                    }
                                })

                                return largestY
                            }
                        }
                    }

                    const yaxisesArr = filterAxisShow(newYaxis).map((axis: any) => {
                        const largestY = findLargestYForDataKey(axis.seriesName, newSeries)

                        const isShow = () => {
                            const cleanedSeriesName = axis.seriesName.replace(/\([^)]+\)/g, "").trim()

                            const test = Object.values(chartGroups).flatMap((group) => {
                                if (group.includes(cleanedSeriesName)) {
                                    return group.filter((seriesName) => seriesName !== axis.seriesName)
                                }
                            })
                            return cleanedSeriesName === test.filter(Boolean)[0]
                        }
                        if (largestY) {
                            const minValue = minNegativeValue < 0 ? (rate * largestY) * 1.2 : 0
                            return {
                                ...axis,
                                labels: {
                                    offsetX: 0,
                                    formatter: (valueInitial: any) => {
                                        let value = +valueInitial
                                        if (isCompare) {
                                            return value
                                        }
                                        let roundedValue

                                        if (value <= 100) {
                                            roundedValue = Math.ceil((value * 1.01)) / 10 * 10
                                        } else if (value <= 1000) {
                                            roundedValue = Math.ceil((value * 1.01)) / 10 * 10
                                        } else if (value <= 10000) {
                                            roundedValue = Math.ceil((value * 1.01)) / 1000 * 1000
                                        } else {
                                            roundedValue = Math.ceil((value * 1.01)) / 10000 * 10000
                                        }
                                        return formatCellData(roundedValue.toFixed())
                                    }
                                },


                                max: largestY,
                                min: isNaN(minValue) ? 0 : minValue,
                                tickAmount: 6,
                            }
                        }
                        const keywordsToCheck = [ "Доля", "CR", "CTR" ]
                        if (keywordsToCheck.some(keyword => axis.seriesName.includes(keyword))) {
                            return {
                                ...axis,
                                labels: {
                                    formatter: (value: any) => {
                                        if (isCompare) {
                                            return value
                                        }

                                        return formatCellData(value.toFixed())
                                    }
                                },

                                max: 100,
                                min: 0,
                                tickAmount: 6,
                            }
                        }
                        const minValue = minNegativeValue < 0 ? rate * largestY : 0
                        return {
                            ...axis,
                            labels: {
                                formatter: (value: any) => {
                                    if (isCompare) {
                                        return value
                                    }

                                    return formatCellData(value.toFixed())
                                }
                            },

                            show: isShow(),
                            max: maxValue,
                            min: isNaN(minValue) ? 0 : minValue,
                            tickAmount: 6,
                        }
                    })
                    const seenSeriesNames: any = {}

                    yaxisesArr.forEach((item: any) => {
                        if (item.show && seenSeriesNames[item.seriesName]) {
                            item.show = false
                        } else {
                            seenSeriesNames[item.seriesName] = true
                        }
                    })
                    setYaxis(yaxisesArr)

                    if (groupsChecked.length > 1) {
                        setYaxis((prev) => [ ...prev, {
                            seriesName: `${_metricsChecked[0] && typeof _metricsChecked[0].label === "object" ? "" : _metricsChecked[0]?.label ?? ""} (Итого и среднее выбранных)`,
                            labels: {
                                formatter: (value: any) => {
                                    if (isCompare) {
                                        return value
                                    }

                                    return formatCellData(value)
                                }
                            },
                            tickAmount: 6,
                            show: false,
                        } ])
                        tableData.totalChosen.active.length > 0 ? newSeries.unshift(getAverageSeries(newSeries, _metricsChecked)) : null
                    } else if (groupsChecked.length === 1) {
                        _metricsChecked.forEach((metric: { name: string, label: string }) => {

                            const series = newSeries.filter((el: { name: string, dataKey: string }) => el.dataKey === metric.name && !el.name.includes("(Итого и среднее)"))
                            const seriesNameArr = [ metric ]
                            if (seriesNameArr.length === 1) {
                                setYaxis((prev) => [ ...prev, {
                                    seriesName: `${seriesNameArr[0].label} (Итого и среднее выбранных)`,
                                    labels: {
                                        formatter: (value: any) => {
                                            if (isCompare) {
                                                return value
                                            }

                                            return formatCellData(value)
                                        }
                                    },
                                    tickAmount: 6,
                                    show: false,
                                } ])
                            }

                            tableData.totalChosen.active.length > 0 ? newSeries.unshift(getAverageSeries(series, seriesNameArr)) : null
                        })
                    }
                    let planSeries: any[] = []
                    newSeries.forEach((el: any) => {
                        if (isNumeric(el?.plan)) {
                            const plan = getPlanSeries(el, details, el?.dataKey.endsWith("cumulative"))
                            planSeries.push(plan)
                        }
                    })
                    newSeries = newSeries.filter((el: any, index: number, self: any[]) =>
                        index === self.findIndex((t) => (
                            t.dataKey === el.dataKey && t.name === el.name
                        ))
                    )
                    planSeries = planSeries.filter((el: any, index: number, self: any[]) =>
                        index === self.findIndex((t) => (
                            t.dataKey === el.dataKey
                        ))
                    )

                    setSeries(newSeries)
                    setSeries({ data: convertYToNumber([ ...newSeries, ...planSeries ]), blockId })
                    break
                }
        }
    }, [
        chartType,
        data,
        tableData,
        groupsChecked,
        _metricsChecked,
        percentages,
        totalChecked,
    ])

    useEffect(() => {
        if (_metricsChecked.length > 1 && [ "pie", "area" ].includes(chartType)) {
            setMetricsChecked({ data: _metricsChecked.slice(-1), blockId })
        }
    }, [ chartType, _metricsChecked ])

    const compareTooltipFormatter = (
        value: number,
        dataRowIndex: number,
        seriesIndex: any,
        series: any
    ) => {
        let _value = value

        const seriesName = series[seriesIndex].name
        const compareSeries = series.find((item: any) => item.name === seriesName)

        if (compareSeries) {
            if (typeof compareSeries.data[0] === "number") {
                _value = compareSeries.data[0]
            } else {
                const _currentDate = compareSeries.data[dataRowIndex]

                if (_currentDate) {
                    _value = _currentDate.x
                }
            }
        }

        return _value
    }

    const customTooltip = ({ seriesIndex, dataPointIndex }: any) => {
        const value = series[seriesIndex].data[dataPointIndex].y
        const seriesName = series[seriesIndex].name

        if (isCompare) {
            return `
                <div class="arrow_box">
                    <span> ${compareTooltipFormatter(
                value,
                dataPointIndex,
                seriesIndex,
                series
            )} </span>
                    <span> 
                        ${seriesName}: ${value}
                    </span>
                </div>
            `
        } else {
            const seriesName = yaxis[seriesIndex].seriesName.toLowerCase()
            let data = formatCellData(value)

            if (seriesName.includes("сумма")) {
                data = formatMoneyData(value, currency)
            }

            if (seriesName.includes("конверсия")) {
                data = formatConvertData(value)
            }

            return `
                <div class="arrow_box">
                    <span> ${compareTooltipFormatter(
                value,
                dataPointIndex,
                seriesIndex,
                series
            )} </span>
                    <span> ${seriesName}: ${data} </span>
                </div>
            `
        }
    }

    const bubbleTooltip = (title: string, items: any[]) => {
        let seriesData
        let groupName

        try {
            seriesData = items
            groupName = items[2].value
        } catch (e) {
            seriesData = null
        }

        if (seriesData) {
            return `
                <div class="arrow_box">
                    <b> ${groupName} </b>
                    <hr />
                    <span> ${_metricsChecked[0].label}: ${seriesData[0].value} </span>
                    <span> ${_metricsChecked[1].label}: ${seriesData[1].value} </span>
                    <span> ${_metricsChecked[2].label}: ${seriesData[3].value} </span>
                </div>
            `
        } else {
            return `
                <div class="arrow_box">
                    <span> Нет данных </span>
                </div>
            `
        }
    }

    const offsetsBubbleChart = useMemo(
        () => calculateMaxMinBubbleChart(seriesForBubbleChart),
        [ seriesForBubbleChart ]
    )


    const lineChartTooltip = {
        enabled: true,
        followCursor: false,
        shared: true,
        intersect: false,
        custom: customTooltip,
    }

    const otherChartTooltip = {
        enabled: true,
        y: {
            formatter: (value: any) => {
                return formatCellData(value)
            },
        },
    }

    const ChooseChartType = () => {

        const generalOptions = {
            chart: {
                animations: { enabled: false, easing: "linear", dynamicAnimation: { speed: 1000 } },
                toolbar: { show: false },
                zoom: { enabled: false }
            },
            dataLabels: { enabled: false },
            legend: { show: false },
            noData: { text: handleEmptyDescription(metricsChecked, groupsChecked, errorMessage) },
            xaxis: { type: "category" },
            annotations: { yaxis: [ { y: 0, borderColor: "#262626" } ] },
            stroke: {
                width: 1.7, // Здесь измените значение, чтобы сделать линию тоньше. Например, 1 для тонкой линии.
                curve: "smooth", // Если хотите сделать линию плавной.
            },
        }


        const updatedSeries = useMemo(() => {
            return series.map((item) => {
                const updatedItem = { ...item }
                if (typeof updatedItem.group === "string") {
                    updatedItem.group = updatedItem.group.replace(/<\/?[^>]+(>|$)/g, "")
                    updatedItem.group = updatedItem.group.replace(/&nbsp;/g, " ")
                    if (chartType === "bar") {
                        updatedItem.group = updatedItem.group.replace(/\B(?=(\d{3})+(?!\d))/g, "")
                    }
                } else {
                    updatedItem.group = ""
                }


                // Убираем неразрывные пробелы и span-теги
                updatedItem.group = updatedItem?.group
                    ?.replace(/&nbsp;/g, " ")
                    ?.replace(/<span[^>]*>.*?<\/span>/gi, "")

                // Если тип графика "bar", форматируем group для числового отображения
                const regex = /\B(?=(\d{3})+(?!\d))/g
                if (chartType === "bar" && typeof updatedItem.group === "string") {
                    updatedItem.group = updatedItem.group.replace(regex, "")
                }

                // Преобразуем value в число или задаем 0 по умолчанию
                updatedItem.value = updatedItem.value != null && !isNaN(updatedItem.value)
                    ? parseInt(String(updatedItem.value), 10)
                    : 0

                // Возвращаем обновленный объект

                updatedItem.value = parseInt(String(updatedItem.value), 10) || 0

                return updatedItem
            })
        }, [ series, chartType ])

        const sortedSeries = useMemo(() => {
            let newSortedSeries = [ ...updatedSeries ]
            const sortValue = Object.values(sortTable)[0]
            if (sortValue === "ASC") {
                newSortedSeries = newSortedSeries.sort((a, b) => a.value - b.value)
            } else if (sortValue === "DESC") {
                newSortedSeries = newSortedSeries.sort((a, b) => b.value - a.value)
            }
            return newSortedSeries
        }, [ updatedSeries, sortTable ])

        const uniqueYaxis = useMemo(() => {
            const axisTitles = yaxis.map(axis => getTitleYaxis(chartGroups, axis))
            return [ ...new Set(axisTitles) ] // Используем Set для уникальности
        }, [ yaxis, chartGroups ])

        const colors = [ "#339dff", "#00b379", "#ffc233", "#d52f40", "#5d3faa" ]
        const colorMap = useRef(new Map()) // Храним цвета для элементов
        const assignedColors = new Map()
        const globalCounter = useRef(0) // Глобальный счетчик для цвета (не сбрасывается!)
        let localCounter = 0 // Локальный счетчик внутри `useMemo`
        const coloredSeries = updatedSeries.map((item) => {
            if (!colorMap.current.has(item.group)) {
                // Выбираем цвет циклически, но для первого элемента фиксируем #339dff
                const nextColorIndex = localCounter === 0 ? 0 : localCounter % colors.length
                colorMap.current.set(item.group, colors[nextColorIndex])
                assignedColors.set(item.group, colors[nextColorIndex]) // Запоминаем цвет
                localCounter++ // Увеличиваем счетчик
            }
            return { ...item, color: colorMap.current.get(item.group) }
        })


        const transformedSeries = [ ...(updatedSeries || []) ]
            .filter(item => item && item.value !== undefined) // Убираем undefined/null элементы
            .sort((a, b) => (b.value ?? 0) - (a.value ?? 0)) // Предотвращаем ошибку
            .slice(0, 5)


        const otherValue = updatedSeries
            .slice(5) // Берем оставшиеся
            .reduce((sum, item) => sum + item.value, 0) // Складываем их значения


        if (otherValue > 0) {
            transformedSeries.push({ group: "Другие", value: otherValue })
        }

        const config = {
            data: updatedSeries,
            xField: "metric", // Теперь X - это метрики
            yField: "value",  // Y - значения метрик
            seriesField: "key", // Группы - это отдельные категории внутри метрики
            isGroup: true, // Разделяем по группам внутри метрики
            isStack: false, // Оставляем столбцы раздельными
            label: {
                style: {
                    fill: "#000",
                    opacity: 1,
                },
            },

            tooltip: {
                formatter: (datum: any) => ({
                    name: datum.key, 
                    value: `${formatCellData(datum.value)}`,
                }),
            },

            xAxis: {
                label: {
                    autoHide: false,
                    autoRotate: true,
                },
            },
        }
        let sum = 0
        updatedSeries.forEach((el) => {

            if (el.plan !== undefined) {
                sum = el.plan

            }
        })
        const planPerDay = Number(modalState2.plan) || 1
        const reportPeriodDays = sum * sum
        const maxGaugeValue = planPerDay * reportPeriodDays

        const metricValue = updatedSeries.reduce((acc: number, item: any) => acc + item.value, 0)
        const percentCompleted = Math.round((metricValue / maxGaugeValue) * 100)


        const config2 = {
            width: 420,
            height: 420,
            legend: false,
            percent: percentCompleted / 100,
            statistic: {
                content: {
                    style: {
                        fontSize: "24px",
                        fontWeight: "bold",
                        fill: "#333",
                    },
                    formatter: () => `${percentCompleted}%`,
                },
            },
        }
        const selectedMetric = _metricsChecked[0]


        const noPlanError = selectedMetric && !selectedMetric.plan
            ? <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
                fontWeight: "bold",
                marginTop: "170px",
                textAlign: "center",
            }}>
                Вы выбрали метрику, у которой не указан план
            </div>
            : null

        const errorPlan = isNaN(percentCompleted) || percentCompleted === Infinity || percentCompleted === -Infinity
            ? <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
                fontWeight: "bold",
                marginTop: "170px",
                textAlign: "center",
            }}>
                Выберите метрику и укажите план
            </div>
            : null

        const speedometerComponent = noPlanError || errorPlan || (
            updatedSeries.length > 0
                ? <Gauge {...config2} animation={true} />
                : <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginTop: "170px",
                    textAlign: "center",
                }}>
                    Выберите метрику и укажите план
                </div>
        )

        const finalSpeedometer = speedometerComponent ?? <></>


        const variantChart: { [key: string]: React.ReactElement } = {
            pie: updatedSeries.length ? (
                <Pie
                    animation={true}
                    data={updatedSeries}
                    angleField="value"
                    colorField="group"
                    color={updatedSeries.map((item: any, index: any) => {
                        if (item.group === "Остальные") {
                            return "#6E6E6E" 
                        }
                        return coloredSeries[index]?.color || "#87CEFA" 
                    })}
                    theme={{
                        colors10: [ "#005DCA", "#4A90E2", "#87CEFA" ],
                        colors20: [ "#005DCA", "#4A90E2", "#87CEFA" ],
                    }}
                    tooltip={{
                        formatter: (datum) => ({
                            name: datum.group,
                            value: `${getPieTooltip(updatedSeries, datum.value)}`,
                        }),
                    }}
                    label={false}
                />
            ) : (
                <ChartWrap
                    type="line"
                    animation={false}
                    series={updatedSeries}
                    options={{
                        ...generalOptions,
                        tooltip: isCompare ? lineChartTooltip : otherChartTooltip,
                        yaxis,
                    }}
                    height='100%'
                />
            ),


            line: (
                <div style={{ position: "relative", height: 400 }}>
                    {/* График */}
                    <ChartWrap
                        type="line"
                        animation={false}
                        series={updatedSeries}
                        options={{
                            ...generalOptions,
                            tooltip: isCompare ? lineChartTooltip : otherChartTooltip,
                            yaxis,
                        }}
                        height="100%"
                    />

                    {/* Лейблы для осей Y */}
                    <div
                        style={{
                            position: "absolute",
                            right: 0, // размещаем лейблы справа от контейнера
                            top: 0, // выравниваем лейблы по верхнему краю
                            transform: "translateY(0)", // убираем смещение по вертикали
                            display: "flex",
                            flexDirection: "row", // горизонтальное расположение
                            justifyContent: "flex-end", // выравнивание по правому краю
                            gap: "10px", // промежутки между лейблами
                            alignItems: "flex-start", // выравнивание по верхнему краю
                        }}
                    >
                        {uniqueYaxis.map((axisTitle, index) => {
                            const parts = axisTitle.split("|").map((part) => part.trim())

                            return (
                                <h1
                                    key={index}
                                    style={{
                                        fontSize: "10px",
                                        textAlign: "end",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {parts.map((part, i) => {
                                        if (!colorMap.current.has(part)) {
                                            // Выдаем цвет по глобальному счетчику
                                            const nextColorIndex = globalCounter.current % colors.length
                                            colorMap.current.set(part, colors[nextColorIndex])
                                            globalCounter.current++ // Увеличиваем глобальный счетчик
                                        }

                                        const color = colorMap.current.get(part) // Берем цвет из памяти

                                        return (
                                            <React.Fragment key={i}>
                                                {i > 0 && <span style={{ color: "#999" }}> | </span>}
                                                <span style={{ color }}>{part}</span>
                                            </React.Fragment>
                                        )
                                    })}
                                </h1>
                            )
                        })}

                    </div>
                </div>

            ),
            area: (
                <ChartWrap
                    type="area"
                    series={sortedSeries}
                    animation={false}
                    options={{
                        ...generalOptions,
                        tooltip: otherChartTooltip,
                        chart: { ...generalOptions.chart, stacked: true },
                    }}
                    height={isShow ? "100%" : 400}

                />
            ),
            speedometer: updatedSeries.length ? (
                finalSpeedometer
            ) : (
                <ChartWrap
                    type="line"
                    animation={false}
                    series={updatedSeries}
                    options={{
                        ...generalOptions,
                        tooltip: isCompare ? lineChartTooltip : otherChartTooltip,
                        yaxis,
                    }}
                    height="100%"
                />
            ),

            tunnel: updatedSeries.length ? (
                
                <Funnel legend={false} {...config} animation={false} />
            ) : (
                <ChartWrap
                    type="line"
                    animation={false}
                    series={updatedSeries}
                    options={{
                        ...generalOptions,
                        tooltip: isCompare ? lineChartTooltip : otherChartTooltip,
                        yaxis,
                    }}
                    height="100%"
                />
            ),
            bubble: (
                <BubbleChartType
                    axisMinMax={offsetsBubbleChart}
                    tooltip={bubbleTooltip}
                    data={seriesForBubbleChart}
                    _metricsChecked={_metricsChecked}

                />
            ),
            bar: (
                <Column
                    xAxis={{
                        label: { autoHide: false, autoRotate: true },
                    }}
                    style={{ marginTop: "20px", height: isShow ? "100%" : 400 }}
                    animation={false}
                    xField={"group"}
                    yField={"value"}
                    seriesField={"key"}
                    label={{
                        position: "middle",

                        style: { fill: "#FFFFFF", opacity: 1 },
                        content: (item) => item.value.toString().replace(RegExp, " "),

                    }}
                    data={sortedSeries}
                    tooltip={{
                        formatter: (datum) => ({
                            name: datum.key,
                            value: `${formatCellData(datum.value)}`,
                        }),
                    }}
                    legend={false}
                    isGroup={true}
                    isStack={false}

                />
            ),
        }

        const getStylesForChart = () => {
            switch (chartType) {
                case "pie":
                    return { height: "99%" }
                default:
                    return { height: "96%", paddingBottom: "20px" }
            }
        }
        const memoizedVariantChart = useMemo(() => {
            return variantChart[chartType as keyof typeof variantChart]
        }, [ chartType, sortedSeries ])

        return (
            <div key={chartType} style={getStylesForChart()}>
                {memoizedVariantChart}
            </div>
        )
    }

    const renderChart = useMemo(() => {
        return (
            <>

                <div className={`universal-chart ${isShow ? "show" : ""}`} style={{ ...(isShow ? { minHeight: "unset", height: "100%" } : {}) }} id="universal_chart">
                    <Loading loading={isChartLoading} hasTip fullHeight>
                        <ButtonsType />
                        {isChartLoading ? (
                            <div style={{ height: 455 }} />
                        ) : (
                            <ChooseChartType />
                        )}
                        <ChartLabel
                            chartFilter={date || selectedGroupingType}
                            chartType={chartType}
                            width={760}
                        />
                    </Loading>
                </div>
            </>
        )
    }, [
        titles,
        isChartLoading,
        chartType,
        selectedGroupingType,
        series,
        isChartFetching,
    ])
    return renderChart
}

export default ChartUniversal

function normalizeSeriesLength(series: IChartData[]) {
    throw new Error("Function not implemented.")
}
