import { FC, useEffect, useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  InputNumber,
  Popover,
  Radio,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";

import { getCountDaysFromPeriod } from "../../../../../../helpers/utils/reports";
import { periodDescription } from "../../../../constants/tooltips";
import { useActions } from "../../../../hooks/useActions";
import useIsAccessEnded from "../../../../hooks/useIsAccessEnded";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

interface IPeriodSelectorProps {
  isCompare: boolean;
  isFixed?: boolean;
  setUpdateDashboard: () => void;
}

const PeriodSelector: FC<IPeriodSelectorProps> = ({
  isCompare,
  isFixed = false,
  setUpdateDashboard,
}) => {
  const isAccessEnded = useIsAccessEnded();
  const format = "DD.MM.YYYY";
  const startTime = { hour: 0, minute: 0, second: 0 };
  const endTime = { hour: 23, minute: 59, second: 59 };
  const period = useTypedSelector((state) => state.period);
  const { details } = useTypedSelector((state) => state.details);
  const { setPeriod, setAllDataFetching, setDetails } = useActions();
  const [ isOpenOutside, setIsOpenOutside ] = useState(false);
  const [ currentDateSelector, setCurrentDateSelector ] = useState(period.select);
  const [ currentDate, setCurrentDate ] = useState<any>({
    start: null,
    end: null,
    interval: "",
  });
  const [ nDays, setNDays ] = useState({ nStart: null, nEnd: null });
  const [ visibleDateModal, setVisibleDateModal ] = useState(false);
  let isCohort = location.pathname.includes("cohort");

  useEffect(() => {
    setCurrentDate({ start: period.start, end: period.end, interval: period.interval });
    setCurrentDateSelector(period.select);
  }, [ period ]);

  const onChangeRadio = (value: string) => {
    let startEnd: {
      [key: string]: () => {
        start: moment.Moment;
        end: moment.Moment;
        interval?: string;
      };
    } = {
      today: () => {
        const start = moment().set(startTime);
        const end = moment().set(endTime);
        return { start, end };
      },
      yesterday: () => {
        const start = moment().subtract(1, "days").set(startTime);
        const end = moment().subtract(1, "days").set(endTime);
        return { start, end };
      },
      last7days: () => {
        const start = moment().subtract(1, "weeks").set(startTime);
        const end = moment().set(endTime);
        return { start, end };
      },
      last30days: () => {
        const start = moment().subtract(29, "days").set(startTime);
        const end = moment().set(endTime);
        return { start, end };
      },
    };
    const payload = startEnd[value]();
    payload.interval = value;
    setCurrentDate({ start: payload.start, end: payload.end, interval: value });

    setCurrentDateSelector("");
    const periodData = {
      start: payload.start,
      end: payload.end,
      interval: payload.interval || period.interval,
      select: "",
    };
    setPeriod({
      ...periodData,
    });
    setUpdateDashboard()
  };

  const onChangeDate = (date: moment.Moment | null, type: string) => {
    if (!date) return;

    let start = currentDate.start ?? currentDashboard?.values?.period.start ?? period.start;
    let end = currentDate.end ?? currentDashboard?.values?.period.end ?? period.end;

    if (type === "start") {
      if (date.isAfter(end)) {
        start = date;
        end = date;
      } else {
        start = date;
      }
    } else if (type === "end") {
      if (date.isAfter(start)) {
        end = date;
      } else {
        start = date;
        end = date;
      }
    }

    setCurrentDate({ start: start, end: end, interval: "" });
  };
  const onChangeType = (value: string) => {
    let start = null;
    let end = null;
    let interval = "";
    setCurrentDateSelector(value);
    setSelectedPeriod(null);

    switch (value) {
      case "selectdates":
        setVisibleDateModal(true);
        interval = "selectdates";
        return;
      case "ndaysago":
        setIsOpenOutside(true);
        setVisibleDateModal(true);
        interval = "ndaysago";
        return;
      case "today":
        start = moment().set(startTime);
        end = moment().set(endTime);
        interval = "today";
        setSelectedPeriod(value);
        break;
      case "yesterday":
        start = moment().subtract(1, "days").set(startTime);
        end = moment().subtract(1, "days").set(endTime);
        interval = "yesterday";
        setSelectedPeriod(value);
        break;
      case "thisweek":
        start = moment().isoWeekday(1).set(startTime);
        end = moment().set(endTime);
        interval = "thisweek";
        break;
      case "lastweek":
        start = moment().subtract(1, "week").isoWeekday(1).set(startTime);
        end = moment().subtract(1, "week").isoWeekday(7).set(endTime);
        interval = "lastweek";
        break;
      case "thismonth":
        start = moment().startOf("month").set(startTime);
        end = moment().set(endTime);
        interval = "thismonth";
        break;
      case "lastmonth":
        start = moment().subtract(1, "month").startOf("month").set(startTime);
        end = moment().subtract(1, "month").endOf("month").set(endTime);
        interval = "lastmonth";
        break;
      case "thisquart":
        start = moment().startOf("quarters").set(startTime);
        end = moment().set(endTime);
        interval = "thisquart";
        break;
      case "lastquart":
        start = moment()
          .subtract(1, "quarters")
          .startOf("quarters")
          .set(startTime);
        end = moment().subtract(1, "quarters").endOf("quarters").set(startTime);
        interval = "lastquart";
        break;
      case "thisyear":
        start = moment().startOf("year").set(startTime);
        end = moment();
        interval = "thisyear";
        break;
      case "lastyear":
        start = moment().subtract(1, "year").startOf("year").set(startTime);
        end = moment().subtract(1, "year").endOf("year").set(startTime);
        interval = "lastyear";
        break;
      case "last7days":
        start = moment().subtract(6, "days").set(startTime);
        end = moment().set(endTime);
        interval = "last7days";
        setSelectedPeriod(value);
        break;
      case "last14days":
        start = moment().subtract(13, "days").set(startTime);
        end = moment().set(endTime);
        interval = "last14days";
        break;
      case "last30days":
        start = moment().subtract(29, "days").set(startTime);
        end = moment().set(endTime);
        interval = "last30days";
        break;
      case "last365days":
        start = moment().subtract(365, "days").set(startTime);
        end = moment().set(endTime);
        interval = "last365days";
        break;
      case "allthetime":
        start = moment().year(2000).set(startTime);
        end = moment().set(endTime);
        interval = "allthetime";
        break;

      default:
        start = moment().set(startTime);
        end = moment().set(endTime);
        break;
    }
    ("");
    const payload = { start, end, interval };

    if (isCohort) {
      const countDays = getCountDaysFromPeriod(payload);

      const setDetail = (value: string) => {
        setDetails(value);
      };
      switch (details) {
        case "q":
          if (countDays >= 1095) {
            setDetail("y");
          }
          break;
        case "m":
          if (countDays >= 365) {
            setDetail("q");
          }
          break;
        case "w":
          if (countDays >= 180) {
            setDetail("m");
          }
          break;
        case "d":
          if (countDays >= 180) {
            setDetail("m");
          } else {
            setDetail("w");
          }
          break;
      }
    }

    setCurrentDate({
      start: payload.start,
      end: payload.end,
      interval: payload.interval,
    });
    setPeriod({
      start: payload.start,
      end: payload.end,
      interval: payload.interval,
      select: value,
    });
    setTimeout(() => {
      setUpdateDashboard();
    }, 600);
  };

  const onApply = () => {
    let start = currentDate.start;
    let end = currentDate.end;
    if (isCohort) {
      const momentStart = moment(currentDate.start);
      const momentEnd = moment(currentDate.end);
      const countDays = momentEnd.diff(momentStart, "days");
      const setDetail = (value: string) => {
        setDetails(value);
      };
      if (countDays >= 1095) {
        setDetail("y");
      } else if (countDays >= 730 || countDays >= 365) {
        setDetail("q");
      } else if (countDays >= 180) {
        setDetail("m");
      } else {
        setDetail("w");
      }
    }

    setCurrentDateSelector("");
    let periodData = {
      start,
      end,
      select: currentDateSelector,
      interval: currentDate.interval,
      days: undefined,
    };

    if (currentDate.interval === "ndaysago" && currentDate.days) {
      periodData.days = currentDate.days;
    }

    setCurrentDate(periodData)
    setPeriod({
      ...periodData,
    });
    setVisibleDateModal(false);
    setUpdateDashboard();
  };

  const onChangeN = (type: string, value: number) => {
    let _nStart = nDays.nStart ? nDays.nStart : 0;
    let _nEnd = nDays.nEnd ? nDays.nEnd : 0;
    if (type === "nStart") _nStart = value;
    if (type === "nEnd") _nEnd = value;
    let start = moment();
    let end = moment();

    start = start.subtract(_nEnd, "days").set(startTime);
    end = end.subtract(_nStart, "days").set(endTime);
    if (start.isAfter(end)) {
      setCurrentDate({
        start: end,
        end: start,
        interval: "ndaysago",
        days: { nStart: _nStart, nEnd: _nEnd },
      });
    } else {
      setCurrentDate({
        start: start,
        end: end,
        interval: "ndaysago",
        days: { nStart: _nStart, nEnd: _nEnd },
      });
    }

    setNDays((prevVal) => ({
      ...prevVal,
      [type]: value,
    }));
  };

  const DP = ({ value, type }: any) => {
    const disabledDate = (current: any) => {
      if (!isAccessEnded) return false;

      return (
        current.isBefore(moment().subtract(7, "d")) || current.isAfter(moment())
      );
    };

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <DatePicker
        value={moment(value)}
        onChange={(date) => onChangeDate(date, type)}
        format={format}
        // disabledMinutes={true}
        allowClear={false}
        style={{ width: 120 }}
        disabled={isCompare}
        disabledDate={disabledDate}
      />
    );
  };

  const { currentDashboard } = useTypedSelector((state) => state.reports);
  const [ selectedPeriod, setSelectedPeriod ] = useState(currentDashboard?.values?.period?.interval || null);

  const handleRadioChange = (e: any) => {
    setSelectedPeriod(e.target.value);
    onChangeRadio(e.target.value);
  };

  return (
    <Space
      style={{ gap: 8, ...(isFixed ? { width: "fit-content" } : {  }), }}
    >
      {!isFixed && (
        <>
          <Radio.Group
            value={selectedPeriod}
            onChange={handleRadioChange}
            buttonStyle="solid"
            disabled={isCompare}
          >
            <Radio.Button value="today">Сегодня</Radio.Button>
            <Radio.Button value="yesterday">Вчера</Radio.Button>
            <Radio.Button value="last7days">7 дней</Radio.Button>
            <Radio.Button value="last30days" disabled={isAccessEnded}>
              {isAccessEnded && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm6-5q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6z"
                  />
                </svg>
              )}
              30 дней
            </Radio.Button>
          </Radio.Group>
          <Select
            style={{ width: "100%", minWidth: 200 }}
            value={currentDateSelector || currentDashboard?.values?.period?.select || undefined}
            placeholder="Выбрать период"
            onChange={(value) => {
              onChangeType(value);
            }}
            virtual={false}
            showSearch
            filterOption={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            disabled={isCompare}
          >
            <Select.Option value="selectdates">выбрать даты</Select.Option>
            <Select.Option value="today">сегодня</Select.Option>
            <Select.Option value="yesterday">вчера</Select.Option>
            <Select.Option value="thisweek">эта неделя</Select.Option>
            <Select.Option value="lastweek" disabled={isAccessEnded}>
              прошлая неделя
            </Select.Option>
            <Select.Option value="thismonth" disabled={isAccessEnded}>
              этот месяц
            </Select.Option>
            <Select.Option value="lastmonth" disabled={isAccessEnded}>
              прошлый месяц
            </Select.Option>
            <Select.Option value="thisquart" disabled={isAccessEnded}>
              этот квартал
            </Select.Option>
            <Select.Option value="lastquart" disabled={isAccessEnded}>
              прошлый квартал
            </Select.Option>
            <Select.Option value="thisyear" disabled={isAccessEnded}>
              этот год
            </Select.Option>
            <Select.Option value="lastyear" disabled={isAccessEnded}>
              прошлый год
            </Select.Option>
            <Select.Option value="last7days">последние 7 дней</Select.Option>
            <Select.Option value="last14days" disabled={isAccessEnded}>
              последние 14 дней
            </Select.Option>
          </Select>
        </>
      )}
      <Popover
        content={
          currentDateSelector === "ndaysago" && isOpenOutside ? (
            <Space size="small" direction="vertical">
              <Space>
                <Typography.Text type="secondary">от</Typography.Text>
                <InputNumber
                  disabled={isCompare}
                  value={nDays.nStart}
                  onChange={(val) =>
                    onChangeN("nStart", typeof val === "number" ? val : 0)
                  }
                  style={{ width: 100 }}
                />

                <Typography.Text type="secondary">до</Typography.Text>
                <InputNumber
                  disabled={isCompare}
                  value={nDays.nEnd}
                  onChange={(val) =>
                    onChangeN("nEnd", typeof val === "number" ? val : 0)
                  }
                  style={{ width: 100 }}
                />
              </Space>
              <div>
                {moment(currentDate.start ?? currentDashboard?.values?.period.start ?? period.start).format(format).toUpperCase()}
                &nbsp;&nbsp;—&nbsp;&nbsp;
                {moment(currentDate.end ?? currentDashboard?.values?.period.end ?? period.end).format(format).toUpperCase()}
              </div>
              <Button
                type="primary"
                style={{ marginTop: 10 }}
                disabled={isCompare}
                onClick={() => onApply()}
              >
                Применить
              </Button>
            </Space>
          ) : (
            <Space size="small" direction="vertical">
              <Space>
                <DP value={currentDate.start ?? currentDashboard?.values?.period.start ?? period.start} type="start" />
                <Typography.Text type="secondary">—</Typography.Text>
                <DP value={currentDate.end ?? currentDashboard?.values?.period.end ?? period.end} type="end" />
              </Space>

              <Button
                type="primary"
                disabled={isCompare}
                style={{ marginTop: 10 }}
                onClick={onApply}
              >
                Применить
              </Button>
            </Space>
          )
        }
        title="Период"
        open={visibleDateModal}
        onOpenChange={(visible) => {
          setVisibleDateModal(visible), setIsOpenOutside(false);
        }}
        trigger="contextMenu"
        placement="bottom"
      >
        <Button onClick={() => setVisibleDateModal(true)}>
          {moment(currentDate.start ?? currentDashboard?.values?.period.start ?? period.start).format(format).toUpperCase()}
          &nbsp;&nbsp;—&nbsp;&nbsp;
          {moment(currentDate.end ?? currentDashboard?.values?.period.end ?? period.end).format(format).toUpperCase()}
        </Button>
      </Popover>

      {!isFixed && <span style={{ marginLeft: 0, marginRight: 10, marginTop: 3 }}>
        <Tooltip
          placement="bottom"
          title={
            <div
              className="page-title-tooltip-text"
              dangerouslySetInnerHTML={{ __html: periodDescription }}
            ></div>
          }
          color="#fff"
          zIndex={9999}
          trigger="click"
          overlayClassName="page-title-tooltip"
        >
          <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
        </Tooltip>
      </span>}
    </Space>
  );
};

export default PeriodSelector;
