import React, { FC, useEffect, useState } from "react";

import { CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Popover, Tree, Typography } from "antd";

import Segments from "./segments";
import {
  data,
  updateFilters,
  calendarTypes,
} from "../../../../../shared/common/constants/filters";
import {
  generateListKeys,
  getParentKey,
  prepareData,
} from "../../../../../helpers/utils/reports";
import { useForceUpdate } from "../../../../../shared/common/hooks/useForceUpdate";
import FilterItemSelected from "../../../../../shared/common/components/reports/params/filter/FilterItemSelected";
import RequestButton from "../../../../../shared/common/components/reports/params/filter/RequestButton";
import { useTypedSelector } from "../../../../../shared/common/hooks/useTypedSelector";
import { useActions } from "../../../../../shared/common/hooks/useActions";
import RefreshButton from "../../../../../shared/common/components/reports/params/filter/RefreshButton";
import { useLocation } from "react-router-dom";
import { useLoadGroupingsData } from "../../../../../shared/common/hooks/useLoadGroupingsData";
import { useDispatch } from "react-redux";
import { findObjectById } from "../../../../../helpers/utils/functions";
import { getStartEndType } from "../../../../../shared/common/components/reports/params/filter/pickers/DateTimeRangePicker";

interface IUniversalFilterProps {
  isCompare: boolean;
  withSegment: boolean;
  disabled?: boolean;
  disableAttr?: boolean;
  filtersFilter?: string[];
  withLabel?: boolean;
  notParams?: boolean;
}

export const listKeys = generateListKeys(data);

const UniversalFilter: FC<IUniversalFilterProps> = ({
  isCompare,
  withSegment,
  filtersFilter,
  disabled = false,
  disableAttr = false,
  withLabel = true,
  notParams = false,
}) => {
  const forceUpdate = useForceUpdate();
  const locationState = useLocation();
  const { filters, filterParent } = useTypedSelector(
    (state) => state.filter
  )
  const [ filterVisible, setFilterVisible ] = useState(false)
  const { currentDashboard } = useTypedSelector((state) => state.reports)

  const {
    setMetaCompare,
    setFilterParent,
    setFilters,
    setCurrentSegment,
    setAllDataFetching,
    setAllBlocksFetching,
    setMetaFilterUpdate,
    setCurrentFunnel,
  } = useActions()
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState("")
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [treeData, setTreeData] = useState<any>([])
  const [btnDisabled, setBtnDisabled] = useState(true)
  useEffect(() => {
    let data_ = data
    if (location.pathname === "/users/ad-reports") {
      data_ = updateFilters()
    }
    const filteredData = filtersFilter
      ? data_.filter((item: any) => filtersFilter.includes(item.name))
      : data_
    const _data = prepareData(
      filteredData,
      searchValue,
      filters,
      disableAttr,
      "",
      "",
      "",
      "",
      [""],
      "",
      filterParent
    )
    const url = new URL(window.location.href)
    const currentParams = url.searchParams.get("params")
    const paramsObject = currentParams ? JSON.parse(currentParams) : {}
    if (paramsObject?.filters) {
      let filtersFromParams: any[] = []
      paramsObject.filters.forEach(
        (filt: { id: string; calendar: { type: string } }) => {
          let extraFields = {}
          if (calendarTypes.includes(filt?.calendar?.type)) {
            const { start, end } = getStartEndType({
              calendar: { type: filt?.calendar?.type },
            })
            extraFields = { ...extraFields, start, end }
          }
          const filterFromParam = {
            ...findObjectById(_data, filt.id),
            ...filt,
            ...extraFields,
          }
          if (!(filtersFromParams.some((item: any) => JSON.stringify(item) === JSON.stringify(filterFromParam)))) {
            filtersFromParams.push(filterFromParam)
          }
        }
      )
      setFilters([
        ...filtersFromParams,
        ...(currentDashboard?.values?.filters ?? []),
        ...(currentDashboard?.values?.segment?.values?.filters ?? []),
      ])
    } else {
      setFilters([
        ...(currentDashboard?.values?.filters ?? []),
        ...(currentDashboard?.values?.segment?.values?.filters ?? []),
      ])
    }
  }, [currentDashboard])
  useEffect(() => {
    let data_ = data
    if (location.pathname === "/users/ad-reports") {
      data_ = updateFilters()
    }
    const filteredData = filtersFilter
      ? data_.filter((item: any) => filtersFilter.includes(item.name))
      : data_
    const _data = prepareData(
      filteredData,
      searchValue,
      filters,
      disableAttr,
      "",
      "",
      "",
      "",
      [""],
      "",
      filterParent
    )

    if (_data.length === 1) {
      setAutoExpandParent(true)
      setExpandedKeys(["0-0"])
    }

    setTreeData(_data)
    forceUpdate()
  }, [
    data,
    searchValue,
    filters,
    filters.length,
    isCompare,
    filterParent,
    location.pathname,
    filtersFilter,
  ])

  useEffect(() => {
    if (!searchValue) {
      let data_ = data
      if (location.pathname === "/users/ad-reports") {
        data_ = updateFilters()
      }
      setExpandedKeys([])
      setAutoExpandParent(false)
      const filteredData = filtersFilter
        ? data_.filter((item: any) => filtersFilter.includes(item.name))
        : data_
      const _data = prepareData(
        filteredData,
        searchValue,
        filters,
        disableAttr,
        "",
        "",
        "",
        "",
        [""],
        "",
        filterParent
      )

      setTreeData(_data)
    }

    forceUpdate()
  }, [searchValue])

  useEffect(() => {
    forceUpdate()
  }, [treeData, currentDashboard, filters])

  useEffect(() => {
    if (!isCompare) {
      setMetaCompare({ value: false })
    }
  }, [isCompare])

  const onExpand = (_expandedKeys: any) => {
    setExpandedKeys(_expandedKeys)
    setAutoExpandParent(false)
  }

  const onSearch = (e: any) => {
    const { value } = e.target
    let expandedKeys = []
    if (value !== "") {
      const reg = new RegExp(value, "i")
      expandedKeys = listKeys
        .map((item: any) => {
          if (item.name.search(reg) > -1) {
            return getParentKey(item.id, treeData)
          }
          return null
        })
        .filter((item: any, i: number, self: any) => item && self.indexOf(item) === i)
    }
    setExpandedKeys(expandedKeys)
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  useEffect(() => {
    setExpandedKeys([])
    setSearchValue("")
  }, [filters])
  const onClearFilters = () => {
    setCurrentSegment(null);
    setCurrentFunnel([]);
    setFilters([]);
    setFilterParent([]);
    setBtnDisabled(true);
    setAllDataFetching({ value: true });
  };

  const handleUpdateDashboardData = () => {
    setAllBlocksFetching({ value: true })
    setAllDataFetching({ value: true })
    setTimeout(() => {
        setAllBlocksFetching({ value: false })
        setMetaFilterUpdate({ value: false }) 
    }, 2000)
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const combinedFilters = [
      ...(currentDashboard?.values?.filters ?? []),
      ...(currentDashboard?.values?.segment?.values?.filters ?? []),
    ];

    if (combinedFilters.length === 0) {

      setFilters([]);
    }
  }, [currentDashboard, dispatch]);
  const isDashboardPage = locationState.pathname === "/dashboards";
  const [hasCleared, setHasCleared] = useState(false); // Состояние для отслеживания, очищались ли фильтры

  useEffect(() => {
    // Проверяем, были ли фильтры очищены уже
    if (filters.length > 0 && !hasCleared) {
      setFilters([]); // Очищаем фильтры
      setHasCleared(true); // Устанавливаем флаг, что фильтры были очищены
    }
  }, [filters, hasCleared]);
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        {withSegment ? (
          <Segments disabled={disabled} setBtnDisabled={setBtnDisabled} isChart={true}/>
        ) : null}
      </div>
      <RefreshButton />
      {filters.length > 0 && (
        <Button
          style={{ marginRight: 5 }}
          onClick={() => onClearFilters()}
          icon={<CloseOutlined />}
          title="Очистить"
        />
      )}
      <div
        style={{
          marginLeft: withSegment ? 10 : 0,
          marginRight: isDashboardPage ? 24 : 0,
          height: !isDashboardPage ? 34 : undefined,
        }}
      >
        {withLabel && (
          <Typography.Text type="secondary">
            Условия фильтра:&nbsp;
          </Typography.Text>
        )}


        {filters.length === 0
          ? null
          : filters.map((item: any, index: number) => {
            if (item.type === "datetimerange") {
              if (!item.start && !item.end) {
                return null;
              }
            }

            let prefix = "и";
            let secondPrefix = null;
            const prevIndex = index === 0 ? 0 : index - 1;
            if (item.id === filters[prevIndex].id) {
              prefix = "или";
            }

            if (item.id.includes("not_")) {
              secondPrefix = "не";
            }

            return (
              <React.Fragment key={index}>
                {index !== 0 && (
                  <Typography.Text type="secondary">
                    &nbsp;{prefix}&nbsp;
                  </Typography.Text>
                )}
                {secondPrefix ? (
                  <b style={{ fontWeight: 600, color: "#ff0000" }}>
                    {secondPrefix}&nbsp;
                  </b>
                ) : (
                  ""
                )}
                {
                  <FilterItemSelected
                    arrayIndex={index}
                    id={item.id}
                    itemData={item}
                    full_name={item.full_name}
                    {...item}
                  />
                }
              </React.Fragment>
            );
          })}

        &nbsp;
        <Popover
          content={
            <Tree.DirectoryTree
              blockNode
              switcherIcon={<DownOutlined />}
              showIcon={false}
              selectable={false}
              treeData={
                disableAttr
                  ? treeData.filter(
                    (item: any) =>
                      item.name !== "Реклама" && item.name !== "Регистрации"
                  )
                  : treeData
              }
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
            />
          }
          title={
            <Input
              onChange={(e) => onSearch(e)}
              placeholder="Найти условие"
              allowClear
            />
          }
          trigger="click"
          placement="bottom"
          overlayClassName="filter-popover"
          open={filterVisible}
          onOpenChange={setFilterVisible}
        >
          <Button icon={<PlusOutlined />} type="primary" shape="circle" />
        </Popover>
        {!notParams && (
          <RequestButton
            setUpdateDashboard={handleUpdateDashboardData}
            btnDisabled={btnDisabled}
            setBtnDisabled={setBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default UniversalFilter;
