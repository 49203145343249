import React, { FC, useEffect, useMemo, useState } from "react"

import { Area, Column, Pie } from "@ant-design/plots"
import { Empty } from "antd"
import moment from "moment"

type ChartGroups = {
  [key: string]: string[];
};
import { useLocation } from "react-router-dom"

import BubbleChartType from "./components/bubble-chart/BubbleChart"
import { IChartData } from "../../../../../app/providers/redux/slices/reports/chart/types"
import {
  calculateDashedLines,
  convertYToNumber,
  getAverageSeries,
  getPieTooltip,
  getPlanSeries,
  handleCustomMetricGroup,
  handleEmptyDescription,
  isDifferenceGreaterThanThreshold,
  isNumeric,
  normalizeGroupingName,
} from "../../../../../helpers/utils/functions"
import {
  appendDetails,
  buildSeriesForBubbleChart,
  calculateMaxMinBubbleChart,
  checkMetricsGroups,
  filterAxisShow,
  findMaxY,
  findRowRecurse,
  formatCellData,
  formatConvertData,
  formatMoneyData,
  getGroupDataForBarChart,
  predictDotsCount,
} from "../../../../../helpers/utils/reports"
import { unApi } from "../../../api/endpoints/reports/unApi"
import { groupMapping } from "../../../constants/reports"
import { useActions } from "../../../hooks/useActions"
import { useTitles } from "../../../hooks/useTitles"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import Loading from "../../loader/Loader"
import ChartWrap from "../ChartWrap"
import {
  findOffsetX,
  findOffsetY,
  getColorTitle,
  getTitleYaxis,
} from "../cohort/components/utils"
import { TitleChart } from "../title-chart/TitleChart"
import ButtonsType from "../un-chart/components/ButtonsType"

const chartColors = [
  "#2E93fA",
  "#35be6a",
  "#546E7A",
  "#E91E63",
  "#FF9800",
  "#1979C9",
  "#D62A0D",
  "#FAA219",
]

const ChartUniversal: FC = () => {
  const titles = useTitles()
  const locationState = useLocation()
  const period = useTypedSelector((state) => state.period)
  const { details } = useTypedSelector((state) => state.details)
  const { filters } = useTypedSelector((state) => state.filter)
  const { attributes } = useTypedSelector((state) => state.attributes)
  const {
    groupings,
    groupsChecked,
    metricsChecked,
    totalChecked,
    tableData,
    percentages,
    sortTable,
  } = useTypedSelector((state) => state.table)
  const { chartType, series, cumulativeChart } = useTypedSelector(
    (state) => state.chart
  )
  const {
    isCompare,
    isChartLoading,
    currency,
    isChartCompareFetching,
    isChartFetching,
  } = useTypedSelector((state) => state.meta)
  const { compareFilters, comparePeriod } = useTypedSelector(
    (state) => state.compare
  )

  const {
    setSeries,
    setLabels,
    setMetaChartLoading,
    setTotalChecked,
    setGroupingsChecked,
    setMetricsChecked,
    setMetaChartFetching,
    setDetails,
    setMetaCompareChartFetching,
    setAllBlocksFetching,
    setMetaFilterUpdate,
    setAllDataFetching,
  } = useActions()

  const [ data, setData ] = useState<any>({})
  const [ _metricsChecked, _setMetricsChecked ] = useState<any>([])
  const [ compareLoading, setCompareLoading ] = useState([
    { time: 0, loading: false },
  ])
  const [ checkedMetrics, setCheckedMetrics ] = useState<any>([])
  const [ prevChartType, setPrevChartType ] = useState("line")
  const [ errorMessage, setErrorMessage ] = useState(
    "Нет данных, соответствующих условиям отчёта"
  )
  const [ yaxis, setYaxis ] = useState<any[]>([])

  const chartDiv = document.getElementById("universal_chart")

  const seriesForBubbleChart = useMemo(
    () =>
      buildSeriesForBubbleChart(
        groupsChecked,
        totalChecked,
        chartType,
        tableData,
        metricsChecked
      ),
    [
      metricsChecked,
      chartType,
      groupsChecked,
      totalChecked,
      tableData,
      series,
      series.length,
    ]
  )

  useEffect(() => {
    setPrevChartType(chartType)

    if (prevChartType === "bubble") {
      setTotalChecked()
      setGroupingsChecked([])

      setMetricsChecked({ data: [ _metricsChecked[0] ] })
      _setMetricsChecked([ _metricsChecked[0] ])
    }
  }, [ chartType ])

  useEffect(() => {
    if (!isChartLoading) {
      let loadingOff = false

      compareLoading.forEach((item) => {
        if (item.loading) {
          loadingOff = true
        }
      })

      if (loadingOff) {
        const newLoadingData = compareLoading.map((item) => ({
          time: item.time,
          loading: false,
        }))

        setCompareLoading(newLoadingData)
      }
    }

    if (isChartLoading) {
      let setLoadingOff = true

      compareLoading.forEach((item) => {
        if (item.loading) {
          setLoadingOff = false
        }
      })

      if (setLoadingOff && !compareLoading[compareLoading.length - 1].loading) {
        setMetaChartLoading({ value: false })
      }
    }
  }, [ isChartLoading, compareLoading ])

  useEffect(() => {
    let disableLoading = true

    compareLoading.forEach((item) => {
      if (item.loading) {
        disableLoading = false
      }
    })

    if (disableLoading) {
      setMetaChartLoading({ value: false })
    }
  }, [ compareLoading ])

  useEffect(() => {
    if (isCompare) {
      let _metrics = metricsChecked
      let _newMetrics: any = []

      _metrics.forEach((_metric: any) => {
        let newMetricLabel = _metric.label
        if (typeof _metric.label === "object") {
          newMetricLabel = _metric.label.props.children[0]
        }

        let _m = { name: `${_metric.name}_2`, label: `${newMetricLabel} (2)` }

        let isAdd = !_metrics.find(
          (_metricTemp: any) => _metricTemp.name === _m.name
        )
        // isAdd = isAdd === undefined

        if (isAdd) {
          _newMetrics.push(_m)
        }
      })

      _setMetricsChecked(_metrics.concat(_newMetrics))
    } else {
      _setMetricsChecked(metricsChecked)
    }

    setMetaChartFetching({ value: true })
  }, [ isCompare, metricsChecked ])

  const fetchCompareData = () => {
    const time = Date.now()

    setMetaChartLoading({ value: true })
    setSeries([])
    setCompareLoading((prevState) => [
      ...prevState,
      { time: time, loading: true },
    ])

    const { compareFilters1, compareFilters2 } = compareFilters
    const { comparePeriod1, comparePeriod2 } = comparePeriod

    const format = "YYYY-MM-DD"
    const groupings_names = normalizeGroupingName(groupings, "_2")
    let selected_groups_path = groupsChecked.map((item: any) => item.ids)
    selected_groups_path = selected_groups_path.filter(
      (el) => !el.includes("total")
    )
    selected_groups_path.push([ "total" ])
    const selected_metrics_names = _metricsChecked.map(
      (item: any) => item.name
    )

    const _comparePeriod1 = {
      start: moment(comparePeriod1.start).format(format),
      end: moment(comparePeriod1.end).format(format),
    }
    const _comparePeriod2 = {
      start: moment(comparePeriod2.start).format(format),
      end: moment(comparePeriod2.end).format(format),
    }

    const data = {
      comparePeriod1: _comparePeriod1,
      comparePeriod2: _comparePeriod2,
      compareFilters1: compareFilters1,
      compareFilters2: compareFilters2,
      attribution: {
        attribution: attributes?.attribution,
        type_applications: attributes?.type_applications,
      },
      details,
      groupings_names,
      selected_groups_path,
      selected_metrics_names,
    }

    unApi
      .getUniversalReportChartData(data, location.pathname)
      .then(function (response) {
        let data = response.data
        if (data.status) {
          setData({})
        } else {
          setData(data.data)
        }

        setErrorMessage("Нет данных, соответствующих условиям отчёта")
        if (response.status === 504) {
          setErrorMessage(
            "Запрос выполняется слишком долго, из-за большого количества данных. Можно уменьшить период отчёта, удалить не нужные метрики или фильтры."
          )
          setData({})
        }

        setCompareLoading((prevState) => [
          ...prevState.filter((val) => val.time !== time),
          { time: time, loading: false },
        ])
      })
      .catch(function (error) {
        console.log("error = ", error)

        setCompareLoading((prevState) => [
          ...prevState.filter((val) => val.time !== time),
          { time: time, loading: false },
        ])
      }).finally(()=>{
        setAllBlocksFetching({ value: false })
        setMetaFilterUpdate({ value: false }) 
        setAllDataFetching({ value: false })
    })
  }

  const fetchData = (metricsChecked: any) => {
    const time = Date.now()

    setMetaChartLoading({ value: true })
    setSeries([])
    setCompareLoading((prevState) => [
      ...prevState,
      { time: time, loading: true },
    ])

    const format = "YYYY-MM-DD"
    const _period = {
      start: moment(period.start).format(format),
      end: moment(period.end).format(format),
    }
    const groupings_names = normalizeGroupingName(groupings, "_2")

    let selected_groups_path = groupsChecked.map((item: any) => item.ids)
    selected_groups_path = selected_groups_path.filter(
      (el) => !el.includes("total")
    )
    selected_groups_path.push([ "total" ])
    const selected_metrics_names = metricsChecked.map((item: any) => item.name)

    if (chartType === "bubble") {
      const data: any = []
      setData(data)
      setMetaChartLoading({ value: false })
      return
    }

    if (!details || selected_metrics_names.length === 0) {
      setCompareLoading((prevState) => [
        ...prevState.filter((val) => val.time !== time),
        { time: time, loading: false },
      ])
      return
    }

    let reqData = {
      period: _period,
      details,
      filters: filters,
      attribution: {
        attribution:
          typeof attributes?.attribution === "string"
            ? [ attributes.attribution ]
            : attributes?.attribution,
        type_applications:
          typeof attributes?.type_applications === "string"
            ? [ attributes.type_applications ]
            : attributes?.type_applications,
      },
      groupings_names,
      selected_groups_path,
      selected_metrics_names,
    }

    const dotsCount = predictDotsCount(
      reqData.period,
      reqData.selected_groups_path.length,
      reqData.selected_metrics_names.length,
      reqData.details
    )

    if (dotsCount > 1000) {
      if (dotsCount > 3000) {
        const newDetails = "m"

        reqData.details = newDetails
        setDetails(newDetails)
      } else {
        const newDetails = appendDetails(reqData.details)

        reqData.details = newDetails
        setDetails(newDetails)
      }
    }

    console.log("=>> CHART REQUEST =>>\n", reqData, "\n=>> CHART REQUEST =>>")
    unApi
      .getUniversalReportChartData(reqData, location.pathname)
      .then(function (response) {
        if (response.status === 200) {
          let respData = response.data

          if (respData.status) {
            setData({})
          } else {
            setData(respData.data)
          }

          setErrorMessage("Нет данных, соответствующих условиям отчёта")
        } else if (response.status === 504) {
          setErrorMessage(
            "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить ненужные метрики или фильтры."
          )
          setData({})
        }

        setCompareLoading((prevState) => [
          ...prevState.filter((val) => val.time !== time),
          { time: time, loading: false },
        ])
      })
      .catch(function (error) {
        console.log("error = ", error)

        if (error.response) {
          if (error.response.status === 504) {
            setErrorMessage(
              "Запрос выполняется слишком долго, из-за большого количества данных. \n Можно уменьшить период отчёта, удалить не нужные метрики или фильтры."
            )
          }
        }

        setData({})
        setCompareLoading((prevState) => [
          ...prevState.filter((val) => val.time !== time),
          { time: time, loading: false },
        ])
      }).finally(()=>{
        setAllBlocksFetching({ value: false })
        setMetaFilterUpdate({ value: false }) 
        setAllDataFetching({ value: false })
    })
  }

  const fetchChartData = () => {
    if (chartType === "bar" || chartType === "pie") {
      return
    }
    if (
      (groupsChecked.length === 0 && totalChecked.length === 0) ||
      _metricsChecked.length === 0
    ) {
      return
    }

    if (isCompare) {
      if (!isChartCompareFetching) return
      fetchCompareData()
    } else {
      if (!isChartFetching) return
      fetchData(_metricsChecked)
    }

    setMetaCompareChartFetching({ value: false })
    setMetaChartFetching({ value: false })
  }

  useEffect(() => {
    fetchChartData()
  }, [
    chartType,
    isChartFetching,
    isChartCompareFetching,
    groupsChecked,
    totalChecked,
    _metricsChecked,
  ])

  const [ chartGroups, setChartGroups ] = useState<ChartGroups>({
    firstGroup: [],
    secondGroup: [],
    thirdGroup: [],
    fourGroup: [],
    fifthGroup: [],
    sixthGroup: [],
    seventhGroup: [],
    eightGroup: [],
    ninethGroup: [],
    tenthGroup: [],
    eleventhGroup: [],
    twelfthGroup: [],
    thirteenth: [],
    fourteenth: [],
    customMetricGroup: [],
  })
  useEffect(() => {
    const updatedChartGroups = { ...chartGroups }

    for (const groupKey in updatedChartGroups) {
      if (Object.prototype.hasOwnProperty.call(updatedChartGroups, groupKey)) {
        updatedChartGroups[groupKey] = updatedChartGroups[groupKey].filter(
          (item) => metricsChecked.some((metric: any) => metric.label === item)
        )
      }
    }

    setChartGroups(updatedChartGroups)
  }, [ metricsChecked ])

  useEffect(() => {
    if (!data) {
      setLabels([])
      setSeries([])
      return
    }

    if (
      (groupsChecked.length === 0 && totalChecked.length === 0) ||
      _metricsChecked.length === 0 ||
      ([ "line", "area" ].includes(chartType) && Object.keys(data).length === 0)
    ) {
      setLabels([])
      setSeries([])
      return
    }

    setCheckedMetrics([])
    let newSeries: any = []

    const newGroupsChecked = [ ...groupsChecked, ...totalChecked ]

    let metricFirstName = ""
    let sumSeries = 0
    let newYaxis: any = []
    // let _series: any = []
    // let filteredAxis

    switch (chartType) {
      case "pie":
        metricFirstName = _metricsChecked[0].name

        newSeries = groupsChecked.map((group: any) => {
          const findRow = findRowRecurse(tableData.data, group?.pathStr)
          if (!findRow)
            return {
              group: group?.label,
              value: 0,
            }

          const val = findRow[metricFirstName]
          return {
            group: group?.label,
            value: Number(val),
          }
        })

        if (newSeries.length > 0) {
          sumSeries = newSeries.reduce((x: any, y: any) => x + y)
        }
        if (sumSeries < tableData.total[metricFirstName]) {
          newSeries = [
            {
              group: "Остальные",
              value: tableData.total[metricFirstName] - sumSeries,
            },
            ...newSeries,
          ]
        }

        // setLabels(newLabels)
        setSeries(newSeries)

        break

      case "bar":
        _metricsChecked.forEach((metric: any) => {
          let _label = metric.label
          if (typeof metric.label === "object") {
            _label = metric.label.props.children[0]
          }

          newGroupsChecked.forEach((group) => {
            let grp = group?.label
            let val = getGroupDataForBarChart(
              isCompare,
              tableData,
              percentages,
              metric,
              group
            )

            newSeries = [
              ...newSeries,
              {
                // key: isCompare ? _label.replace(" (2)", "") : _label,
                key: _label,
                group: grp,
                value: val,
              },
            ]
          })
        })
        newSeries = newSeries.map((item: any) => {
          let regex = /&nbsp;/g
          const modifiedGroup = item.group?.replace(regex, " ")
          return { ...item, group: modifiedGroup }
        })
        setSeries(newSeries)
        break

      default:
        // _series = []
        _metricsChecked.forEach((metric: any) => {
          let modifiedLabel = metric.label

          if (typeof metric.label === "object") {
            modifiedLabel = metric.label.props.children[0]
          }

          newGroupsChecked.forEach((group) => {
            const name = `${metric ? metric.label : "Метрика"} (${
              group ? group?.label : "группа"
            })`
            if (!data[group?.pathStr] || !data[group?.pathStr][metric.name])
              return

            let dataResult = [
              ...data[group?.pathStr][metric.name].map((item: any) => {
                return { ...item, category: name }
              }),
            ]

            if (percentages.includes(metric.name)) {
              dataResult = dataResult.map((item, index) => {
                const y = parseFloat(
                  String((item.y / data.total[metric.name][index].y) * 100)
                ).toFixed(2)
                return { ...item, y }
              })
            }

            if (chartType === "line") {
              setCheckedMetrics((prevState: any) => {
                const newMetrics = prevState
                newMetrics.push(metric.name)
                return newMetrics
              })

              const newCheckedMetrics = checkedMetrics
              if (
                !newCheckedMetrics.find((value: any) => value === metric.name)
              ) {
                newCheckedMetrics.push(metric.name)
              }

              const _group = checkMetricsGroups(
                metric,
                name,
                group?.label,
                newCheckedMetrics
              )

              newYaxis.push({
                seriesName: _group.label,
                show: _group.show,
                opposite: true,
                isCustomMetric: metric.name.startsWith("_"),
                dataKey: metric.name,
              })
              newSeries.push({
                name: name,
                data: dataResult,
                dataKey: metric.name.substring(0, metric.name.length - 2),
                plan: metric?.plan,
                label: metric?.label,
                base_metric_name: metric?.base_metric_name,
                metric_formula: metric?.metric_formula,
              })
            } else {
              newSeries.push({ name, data: dataResult })
            }
          })
        })

        if (isCompare) {
          const maxValue = Math.max(
            ...newSeries.flatMap(({ data }: any) =>
              data.map((el: any) => parseInt(el.y))
            )
          )
          setYaxis(
            filterAxisShow(newYaxis).map((axis: any, index: number) => {
              const isVisible = axis.show
                ? findMaxY([ newSeries[index] ])
                : findMaxY([ newSeries[index] ])
              if (
                axis.seriesName.includes("Доля") ||
                axis.seriesName.includes("CR")
              ) {
                return {
                  ...axis,
                  labels: {
                    formatter: (value: any) => {
                      if (isCompare) {
                        return value.toFixed()
                      }
                      let roundedValue
                      if (value <= 100) {
                        roundedValue = Math.round(value / 10) * 10
                      } else if (value <= 1000) {
                        roundedValue = Math.round(value / 10) * 10
                      } else if (value <= 10000) {
                        roundedValue = Math.round(value / 1000) * 1000
                      } else {
                        roundedValue = Math.round(value / 10000) * 10000
                      }

                      return formatCellData(roundedValue)
                    },
                  },
                  max: 100,
                  min: 0,
                  tickAmount: 6,
                }
              }
              return {
                ...axis,
                max: isDifferenceGreaterThanThreshold(newSeries)
                  ? isVisible
                  : maxValue,
                forceNiceScale: true,
                labels: {
                  formatter: (value: any) => {
                    if (isCompare) {
                      return value.toFixed()
                    }
                    let roundedValue
                    if (value <= 100) {
                      roundedValue = Math.round(value / 10) * 10
                    } else if (value <= 1000) {
                      roundedValue = Math.round(value / 10) * 10
                    } else if (value <= 10000) {
                      roundedValue = Math.round(value / 1000) * 1000
                    } else {
                      roundedValue = Math.round(value / 10000) * 10000
                    }

                    return formatCellData(roundedValue)
                  },
                },
                min: 0,
              }
            })
          )
          setYaxis((prev) =>
            prev.map((el, index) => {
              if (index === 0) {
                return { ...el, show: true }
              }
              return { ...el, show: false }
            })
          )
        } else {
          const minNegativeValue = Math.min(
            ...newSeries.flatMap(({ data }: any) => data.map((el: any) => el.y))
          )
          let maxForMinNegativeValue = Number.NEGATIVE_INFINITY
          if (minNegativeValue < 0) {
            newSeries.forEach(({ data }: any) => {
              const minInSeries = Math.min(...data.map((el: any) => el.y))
              if (minInSeries === minNegativeValue) {
                const maxInSeries = Math.max(...data.map((el: any) => el.y))
                maxForMinNegativeValue = Math.max(
                  maxForMinNegativeValue,
                  maxInSeries
                )
              }
            })
          }
          let rate = minNegativeValue / maxForMinNegativeValue

          const maxValue = Math.max(
            ...newSeries.flatMap(({ data }: any) =>
              data.map((el: any) => parseInt(el.y))
            )
          )

          const findLargestYForDataKey = (
            seriesName: string,
            newSeries: any
          ) => {
            const series: any = newSeries.find(
              (item: any) => item.name === seriesName
            )
            if (series) {
              const { dataKey } = series
              const groupToUpdate = dataKey.startsWith("_")
                ? handleCustomMetricGroup(series, cumulativeChart)
                : Object.keys(groupMapping).find((groupName) => {
                    return !cumulativeChart
                      ? groupMapping[groupName].includes(dataKey)
                      : groupMapping[groupName].includes(
                          dataKey.replace(/_2_cumulati/g, "")
                        )
                  })

              if (groupToUpdate) {
                const filteredSeries = newSeries.filter(
                  (item: any) => item.dataKey === dataKey
                )
                let largestY: any = null

                filteredSeries.forEach((item: any) => {
                  const largestYInData = Math.max(
                    ...item.data.map((entry: any) => Number(entry.y))
                  )
                  if (largestY === null || largestYInData > largestY) {
                    largestY = largestYInData
                  }
                })
                const cleanedSeriesName = seriesName
                  .replace(/\([^)]+\)/g, "")
                  .trim()
                setChartGroups((prev) => {
                  const updatedGroup = Array.isArray(prev[groupToUpdate])
                    ? [ ...prev[groupToUpdate] ]
                    : []

                  if (!updatedGroup.includes(cleanedSeriesName)) {
                    updatedGroup.push(cleanedSeriesName)
                  }
                  return {
                    ...prev,
                    [groupToUpdate]: updatedGroup,
                  }
                })

                return largestY
              }
            }
          }

          const yaxisesArr = filterAxisShow(newYaxis).map(
            (axis: any, index: number) => {
              const largestY = findLargestYForDataKey(
                axis.seriesName,
                newSeries
              )

              const isShow = () => {
                const cleanedSeriesName = axis.seriesName
                  .replace(/\([^)]+\)/g, "")
                  .trim()

                const test = Object.values(chartGroups).flatMap((group) => {
                  if (group?.includes(cleanedSeriesName)) {
                    return group?.filter(
                      (seriesName) => seriesName !== axis.seriesName
                    )
                  }
                })

                return cleanedSeriesName === test.filter(Boolean)[0]
              }
              if (largestY) {
                const minValue =
                  minNegativeValue < 0 ? rate * largestY * 1.2 : 0
                return {
                  ...axis,
                  labels: {
                    offsetX: -20,
                    formatter: (valueInitial: any) => {
                      let value = +valueInitial
                      if (isCompare) {
                        return value
                      }
                      let roundedValue

                      if (value <= 100) {
                        roundedValue = (Math.ceil(value * 1.01) / 10) * 10
                      } else if (value <= 1000) {
                        roundedValue = (Math.ceil(value * 1.01) / 10) * 10
                      } else if (value <= 10000) {
                        roundedValue = (Math.ceil(value * 1.01) / 1000) * 1000
                      } else {
                        roundedValue =
                          (Math.ceil(value * 1.01) / 10000) * 10000
                      }
                      return formatCellData(roundedValue.toFixed())
                    },
                  },
                  title: {
                    align: "right",
                    rotate: 90,
                    text: getTitleYaxis(chartGroups, axis),
                    offsetX: findOffsetX(largestY),
                    offsetY: findOffsetY(
                      getTitleYaxis(chartGroups, axis).length
                    ),
                    floating: false,
                    style: {
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: "#fff",
                    },
                  },
                  show: isShow(),
                  max: largestY,
                  min: isNaN(minValue) ? 0 : minValue,
                  tickAmount: 6,
                }
              }
              const keywordsToCheck = [ "Доля", "CR", "CTR" ]
              if (
                keywordsToCheck.some((keyword) =>
                  axis.seriesName.includes(keyword)
                )
              ) {
                return {
                  ...axis,
                  labels: {
                    formatter: (value: any) => {
                      if (isCompare) {
                        return value
                      }

                      return formatCellData(value.toFixed())
                    },
                  },
                  title: {
                    align: "right",
                    rotate: 90,
                    text: getTitleYaxis(chartGroups, axis),
                    offsetX: findOffsetX(largestY),
                    offsetY: findOffsetY(
                      getTitleYaxis(chartGroups, axis).length
                    ),
                    floating: false,
                    style: {
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: "#fff",
                    },
                  },
                  max: 100,
                  min: 0,
                  tickAmount: 6,
                }
              }
              const minValue = minNegativeValue < 0 ? rate * largestY : 0
              return {
                ...axis,
                labels: {
                  formatter: (value: any) => {
                    if (isCompare) {
                      return value
                    }

                    return formatCellData(value.toFixed())
                  },
                },
                title: {
                  align: "right",
                  rotate: 90,
                  text: getTitleYaxis(chartGroups, axis),
                  offsetX: findOffsetX(largestY),
                  offsetY: findOffsetY(getTitleYaxis(chartGroups, axis).length),
                  floating: false,
                  style: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    color: "#fff",
                  },
                },
                show: isShow(),
                max: maxValue,
                min: isNaN(minValue) ? 0 : minValue,
                tickAmount: 6,
              }
            }
          )
          const seenSeriesNames: any = {}

          yaxisesArr.forEach((item: any) => {
            if (item.show && seenSeriesNames[item.seriesName]) {
              item.show = false
            } else {
              seenSeriesNames[item.seriesName] = true
            }
          })
          setYaxis(yaxisesArr)
        }
        if (groupsChecked.length > 1) {
          setYaxis((prev) => [
            ...prev,
            {
              seriesName: `${_metricsChecked[0].label} (Итого и среднее выбранных)`,
              labels: {
                formatter: (value: any) => {
                  if (isCompare) {
                    return value
                  }

                  return formatCellData(value)
                },
              },
              tickAmount: 6,
              show: false,
            },
          ])
          tableData.totalChosen.active.length > 0
            ? newSeries.unshift(getAverageSeries(newSeries, _metricsChecked))
            : null
        } else if (groupsChecked.length === 1) {
          _metricsChecked.forEach((metric: { name: string; label: string }) => {
            const series = newSeries.filter(
              (el: { name: string; dataKey: string }) =>
                el.dataKey === metric.name &&
                !el.name.includes("(Итого и среднее)")
            )
            const seriesNameArr = [ metric ]
            if (seriesNameArr.length === 1) {
              setYaxis((prev) => [
                ...prev,
                {
                  seriesName: `${seriesNameArr[0].label} (Итого и среднее выбранных)`,
                  labels: {
                    formatter: (value: any) => {
                      if (isCompare) {
                        return value
                      }

                      return formatCellData(value)
                    },
                  },
                  tickAmount: 6,
                  show: false,
                },
              ])
            }

            tableData.totalChosen.active.length > 0
              ? newSeries.unshift(getAverageSeries(series, seriesNameArr))
              : null
          })
        }
        let planSeries: any[] = []
        newSeries.forEach((el: any) => {
          if (isNumeric(el?.plan)) {
            const plan = getPlanSeries(
              el,
              details,
              el?.dataKey.endsWith("cumulative")
            )
            planSeries.push(plan)
          }
        })

        setYaxis((prev) => {
          const planYaxises = planSeries.map((el) => {
            const findedObj = prev.find(
              (yaxis) => yaxis.dataKey === el.dataKey
            )
            return { ...findedObj, seriesName: el.name, show: false }
          })
          return [ ...prev, ...planYaxises ]
        })
        setSeries(convertYToNumber([ ...newSeries, ...planSeries ]))
        break
    }
  }, [
    chartType,
    data,
    tableData,
    groupsChecked,
    _metricsChecked,
    percentages,
    totalChecked,
  ])

  useEffect(() => {
    if (_metricsChecked.length > 1 && [ "pie", "area" ].includes(chartType)) {
      setMetricsChecked({ data: _metricsChecked.slice(-1) })
    }
  }, [ chartType, _metricsChecked ])

  const calculateChartColors = () => {
    let colors: any[] = []

    series.forEach((axis, index) => {
      if (colors[index]) return
      const colorForCurrentAxis = axis?.name?.startsWith("План")
        ? chartColors[series.findIndex((el) => el?.dataKey === axis?.dataKey)]
        : chartColors[index]
      colors[index] =
        colorForCurrentAxis ||
        chartColors[Math.floor(Math.random() * chartColors.length)]

      series.forEach((axis2, index2) => {
        if (axis.name === axis2.name) return

        let _name = axis.name

        try {
          if (_name && typeof _name === "string" && axis2.name) {
            _name = _name.split(" (")

            let _prefix = _name[1]

            if (_name.length > 2) {
              _prefix = _name[2]
            }

            if (
              axis2.name.includes(_name[0]) &&
              axis2.name.includes("(2)") &&
              axis2.name.includes(_prefix)
            ) {
              colors[index2] = colorForCurrentAxis
            }
          }
        } catch (e) {
          console.log(e)
        }
      })
    })

    return colors
  }

  const calculateChartBarColors = () => {
    let colors: any = []

    _metricsChecked.forEach((axis: any, index: number) => {
      if (colors[index]) return

      const colorForCurrentAxis = chartColors[index]
      colors[index] =
        colorForCurrentAxis ||
        chartColors[Math.floor(Math.random() * chartColors.length)]

      _metricsChecked.forEach((axis2: any, index2: number) => {
        if (axis.name === axis2.name) return

        let _name = axis.name

        try {
          if (_name && typeof _name === "string" && axis2.name) {
            _name = _name.split(" (")

            let _prefix = _name[1]

            if (_name.length > 2) {
              _prefix = _name[2]
            }

            if (
              (axis2.name.includes(_name[0]) && axis2.name.includes("(2)")) ||
              axis2.name.includes(_prefix)
            ) {
              colors[index2] = colorForCurrentAxis
            }
          }
        } catch (e) {
          console.error(e)
        }
      })
    })

    return colors
  }

  const _fillColors = useMemo(() => calculateChartColors(), [ series ])
  // const _fillColorsBar = useMemo(() => calculateChartBarColors(), [ series ])

  const compareTooltipFormatter = (
    value: number,
    dataRowIndex: number,
    seriesIndex: any,
    series: any
  ) => {
    let _value = value

    const seriesName = series[seriesIndex].name
    const compareSeries = series.find((item: any) => item.name === seriesName)

    if (compareSeries) {
      if (typeof compareSeries.data[0] === "number") {
        _value = compareSeries.data[0]
      } else {
        const _currentDate = compareSeries.data[dataRowIndex]

        if (_currentDate) {
          _value = _currentDate.x
        }
      }
    }

    return _value
  }

  const customTooltip = ({ seriesIndex, dataPointIndex }: any) => {
    const value = series[seriesIndex].data[dataPointIndex].y
    const seriesName = series[seriesIndex].name

    if (isCompare) {
      return `
                <div class="arrow_box">
                    <span> ${compareTooltipFormatter(
                      value,
                      dataPointIndex,
                      seriesIndex,
                      series
                    )} </span>
                    <span> 
                        ${seriesName}: ${value}
                    </span>
                </div>
            `
    } else {
      const seriesName = yaxis[seriesIndex].seriesName.toLowerCase()
      let data = formatCellData(value)

      if (seriesName.includes("сумма")) {
        data = formatMoneyData(value, currency)
      }

      if (seriesName.includes("конверсия")) {
        data = formatConvertData(value)
      }

      return `
                <div class="arrow_box">
                    <span> ${compareTooltipFormatter(
                      value,
                      dataPointIndex,
                      seriesIndex,
                      series
                    )} </span>
                    <span> ${seriesName}: ${data} </span>
                </div>
            `
    }
  }

  const bubbleTooltip = (title: string, items: any[]) => {
    let seriesData
    let groupName

    try {
      seriesData = items
      groupName = items[2].value
    } catch (e) {
      seriesData = null
    }

    if (seriesData) {
      return `
                <div class="arrow_box">
                    <b> ${groupName} </b>
                    <hr />
                    <span> ${_metricsChecked[0].label}: ${seriesData[0].value} </span>
                    <span> ${_metricsChecked[1].label}: ${seriesData[1].value} </span>
                    <span> ${_metricsChecked[2].label}: ${seriesData[3].value} </span>
                </div>
            `
    } else {
      return `
                <div class="arrow_box">
                    <span> Нет данных </span>
                </div>
            `
    }
  }

  const offsetsBubbleChart = useMemo(
    () => calculateMaxMinBubbleChart(seriesForBubbleChart),
    [ seriesForBubbleChart ]
  )

  const lineChartTooltip = {
    enabled: true,
    followCursor: false,
    shared: true,
    intersect: false,
    custom: customTooltip,
  }

  const otherChartTooltip = {
    enabled: true,
    y: {
      formatter: (value: any) => {
        return formatCellData(value)
      },
    },
  }

  const ChooseChartType = () => {
    const seriesData: IChartData[] = JSON.parse(JSON.stringify(series))
    if (
      tableData.data?.length === 0 &&
      Object.keys(tableData.total).length === 0
    ) {
      return (
        <div style={{ width: "100%", height: 400 }}>
          <div className="table-overlay">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={handleEmptyDescription(
                metricsChecked,
                groupsChecked,
                errorMessage
              )}
            />
          </div>
        </div>
      )
    }

    const generalOptions = {
      chart: {
        animations: {
          enabled: false,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: {
          show: false,
        },
        zoom: { enabled: false },
      },
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
      noData: {
        text: handleEmptyDescription(
          metricsChecked,
          groupsChecked,
          errorMessage
        ),
      },
      xaxis: { type: "category" },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "#262626",
          },
        ],
      },
    }
    const normalizeSeriesLength = (series: IChartData[]) => {
      if (series.length === 0) {
        return series
      }
      if (chartType !== "line") {
        return
      }
      // Находим серию с наибольшей длиной
      const maxLength = Math.max(...seriesData.map((s) => s?.data?.length))
      const normalizedSeries = seriesData.map((s) => {
        // Если длина серии меньше maxLength, добавляем недостающие элементы
        if (s?.data?.length < maxLength) {
          const newData = Array.from(
            { length: maxLength - s.data.length },
            (_, index) => ({
              x: "",
              y: "0",
              category: s.name,
            })
          )
          return {
            ...s,
            data: [ ...s.data, ...newData ],
          }
        }
        return s
      })

      return normalizedSeries
    }
    const regex = /\B(?=(\d{3})+(?!\d))/g
    const variantChart: { [key: string]: React.ReactElement } = useMemo(() => {
      return {
      pie: (
        <Pie
          animation={false}
          data={
            seriesData?.length > 0
              ? seriesData.map((item) => ({
                  ...item,
                  group: item?.group
                    ?.replace(/&nbsp;/g, " ")
                    ?.replace(/<span[^>]*>.*?<\/span>/gi, ""),
                }))
              : seriesData
          }
          angleField={"value"}
          colorField={"group"}
          tooltip={{
            formatter: (datum) => {
              return {
                name: datum?.group,
                value: `${getPieTooltip(seriesData, datum.value)}`,
              }
            },
          }}
          legend={
            locationState.pathname.includes("dashboards")
              ? {
                  position: "bottom",
                  itemName: {
                    style: {
                      fill: "#000",
                      fontSize: 14,
                    },
                  },
                  marker: {
                    symbol: "circle",
                  },
                }
              : false
          }
          label={false}
        />
      ),
      line: (
        <ChartWrap
          type="line"
          series={
            seriesData.length > 1 && isCompare
              ? normalizeSeriesLength(seriesData)
              : seriesData
          }
          options={{
            ...generalOptions,
            tooltip: isCompare ? lineChartTooltip : otherChartTooltip,
            colors: _fillColors,
            stroke: {
              width: 1.7,
              dashArray: calculateDashedLines(seriesData, isCompare),
            },
            yaxis,
          }}
          height={400}
        />
      ),
      area: (
        <ChartWrap
          type="area"
          series={seriesData}
          options={{
            ...generalOptions,
            tooltip: otherChartTooltip,
            chart: {
              ...generalOptions.chart,
              stacked: true,
            },
          }}
          height={400}
        />
      ),
      bubble: (
        <BubbleChartType
            axisMinMax={offsetsBubbleChart}
            tooltip={bubbleTooltip}
            data={seriesForBubbleChart}
            _metricsChecked={_metricsChecked}
        />
    ),
      bar: (
        <Column
          xAxis={{
            label: {
              autoHide: false,
              autoRotate: true,
            },
          }}
          style={{
            marginTop: "20px",
          }}
          animation={false}
          xField={"group"}
          yField={"value"}
          seriesField={"key"}
          label={{
            position: "middle",
            style: {
              fill: "#FFFFFF",
              opacity: 1,
            },
            content: (item) => {
              if (chartType === "bar") {
                return item.value.toString().replace(regex, " ")
              }
              return item.value
            },
          }}
          data={seriesData
            .map((item) => {
              const updatedItem = { ...item }
              if (
                chartType === "bar" &&
                typeof updatedItem?.group === "string"
              ) {
                updatedItem.group = updatedItem.group?.replace(regex, "")
              }
              updatedItem.value = parseInt(String(updatedItem.value))
              return updatedItem
            })
            .sort((a, b) => {
              const sortValue = Object.values(sortTable)[0]
              if (sortValue === "ASC") {
                return a.value - b.value
              } else if (sortValue === "DESC") {
                return b.value - a.value
              } else {
                return 0
              }
            })}
          tooltip={{
            formatter: (datum) => {
              return {
                name: datum.key,
                value: `${formatCellData(datum.value)}`,
              }
            },
          }}
          legend={false}
          isGroup={true}
          isStack={false}
        />
      ),
    }},[ seriesData,groupsChecked,metricsChecked,chartType,sortTable ])

    return (<div style={{ height: 455 }}>{variantChart[chartType]}</div>)
  }

 return (
        <>

                <Loading loading={isChartLoading} hasTip fullHeight>
                    <ButtonsType />
                    {isChartLoading ? (
                        <div style={{ height: 455 }} />
                    ) : (
                    <ChooseChartType />
                    )}
             
                </Loading>
         
            {titles.map((portalContent:any) => (
                <TitleChart
                    key={portalContent.content}
                    left={portalContent.left as number}
                    top={portalContent.top}
                    content={portalContent.content}
                    color={getColorTitle(series,portalContent.content)}
                />
            ))}
        </>
    )
}

export default ChartUniversal
