import React, {FC, useEffect, useRef, useState} from "react"

import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons"
import {Button, Checkbox, message, Modal, Tooltip} from "antd"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import {
    findObjectByLabelId,
    getGroupTitle,
    isFirstGrouping,
    sortDataRecursive
} from "../../../../../../helpers/utils/functions";
import {showInfoModal} from "../../../../../../helpers/utils/ui";
import useHover from "../../../../hooks/useHover";
import {data as dataFilters, groupingsToFilters,updateFilters} from "../../../../constants/filters";
import {generateListKeys, prepareData} from "../../../../../../helpers/utils/reports";
import {IFilter} from "../../../../../../app/providers/redux/slices/reports/params/filter/types";
import ToggleFilter from "./ToggleFilter";

const GroupCell: FC<any> = ({ rowData, expandedRowKeys,onCellWidthChange,withCheckbox=true }) => {
    const {hovered,bind} = useHover()
    const { groupsChecked, totalChecked,metricsChecked, groupsLimit,groupings,tableData:{ data },sortTable, tableData:{totalChosen} } = useTypedSelector((state) => state.table)
    const { filters } = useTypedSelector((state) => state.filter)
    const { setTotalChecked, clearTotalChecked, setGroupingsChecked, setMetaChartFetching, setMetaCompareChartFetching,setTotalChosenStatus,setFilter,setFilters,setMetaFilterUpdate } = useActions()
    const [firstGrouping,setFirstGrouping] = useState( data?.length > 0 ? data[0] : undefined)
    const cellRef = useRef<any>(null);
    const [groupTitlePos,setGroupTitlePos] = useState({
        left:"0px",
    })
    const isNested = rowData.children && (rowData.children.length > 1 || rowData.children[0].group !== "empty")
    const spanRef = useRef(null);
    const [dataFilter,setDataFilter] = useState(dataFilters)

    useEffect(()=>{
        if(location.pathname === '/users/ad-reports'){
            setDataFilter(updateFilters())
        }
    },[location.pathname])
    useEffect(() => {
        const wrapperDiv = cellRef.current;
        if(wrapperDiv.offsetLeft){
            setGroupTitlePos({left:`${isNested ? wrapperDiv.offsetLeft - 22 - 4 : wrapperDiv.offsetLeft}px`})
        }
    }, [cellRef,rowData]);

    useEffect(() => {
        if (Object.values(sortTable).length > 0) {
            const sortedData = sortDataRecursive(data, sortTable);
            setFirstGrouping(sortedData[0])
        } else {
            setFirstGrouping(data?.length > 0 ? data[0] : undefined);
        }
    }, [sortTable]);




const onChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    if (metricsChecked.length > 1) {
        if (rowData.pathStr === "total") {
            if (e.target.checked) {
                setTotalChecked();
                setGroupingsChecked([]);
            } else {
                clearTotalChecked();
            }

            setMetaChartFetching({value:true});
            setMetaCompareChartFetching({value:true});
            return;
        }
        if (rowData.pathStr === "total_choosen") {
            if (e.target.checked) {
                if (groupsChecked.length > 0) {
                    setTotalChosenStatus([rowData]);
                    setMetaChartFetching({value:true});
                    setMetaCompareChartFetching({value:true});
                } else {
                    showInfoModal("Нет выбранных.");
                }
            } else {
                setTotalChosenStatus([]);
            }
            return;
        }
        if (e.target.checked) {
            const ids = rowData.clientData.path;
            const pathStr = rowData.pathStr;
            const label = rowData.clientData.pathLabel.join(" → ");
            clearTotalChecked();
            setGroupingsChecked([{ ids, pathStr, label }]);

            setMetaChartFetching({value:true});
            setMetaCompareChartFetching({value:true});
        } else {
            setGroupingsChecked(groupsChecked.filter((item) => item.pathStr !== rowData.pathStr));

            setMetaChartFetching({value:true});
            setMetaCompareChartFetching({value:true});
        }

        return;
    }

    if (rowData.pathStr === "total") {
        if (e.target.checked) {
            setTotalChecked();
        } else {
            clearTotalChecked();
            setGroupingsChecked(groupsChecked.filter((item) => item.pathStr !== rowData.pathStr));
        }

        setMetaChartFetching({value:true});
        setMetaCompareChartFetching({value:true});
        return;
    }
    if (rowData.pathStr === "total_choosen") {
        if (e.target.checked) {
            if (groupsChecked.length > 0) {
                setTotalChosenStatus([rowData]);
                setMetaChartFetching({value:true});
                setMetaCompareChartFetching({value:true});
            } else {
                showInfoModal("Нет выбранных.");
            }
        } else {
            setTotalChosenStatus([]);
        }
        return;
    }

    if (e.target.checked) {
        const ids = rowData.clientData.path;
        const pathStr = rowData.pathStr;
        const label = rowData.clientData.pathLabel.join(" → ");
        setGroupingsChecked([...groupsChecked, { ids, pathStr, label }]);

        setMetaChartFetching({value:true});
        setMetaCompareChartFetching({value:true});
    } else {
        setGroupingsChecked(groupsChecked.filter((item) => item.pathStr !== rowData.pathStr));

        setMetaChartFetching({value:true});
        setMetaCompareChartFetching({value:true});
    }
};

// В useEffect для начальной установки:
useEffect(() => {
    // Устанавливаем начальное состояние total в активное
    setTotalChecked(); // или установите это состояние в true, если требуется.
}, []);

    const findChildrenChecked = (groups: any) => {
        if (!groups) return false

        let _l = 0

        groups.forEach((item: any) => {
            if ([ ...groupsChecked ].find((item2) => item2.pathStr === item.pathStr)) {
                _l += 1
            }
        })

        return groups.length === _l
    }

    const checkChildrenGroup = (groups: any, isChecked: boolean) => {
        let _gr: any = []

        groups.forEach((item: any) => {
            const ids = item.clientData.path
            const pathStr = item.pathStr
            const label = item.clientData.pathLabel.join(" → ")

            _gr.push({ ids, pathStr, label })
        })

        if (isChecked) {
            setGroupingsChecked(groupsChecked.filter((item) => {
                let _temp = _gr.find((grItem: any) => grItem.pathStr === item.pathStr)

                return !_temp
            }))
        }  else {
            setGroupingsChecked([ ...groupsChecked, ..._gr ])
        }

        setMetaChartFetching({value:true})
        setMetaCompareChartFetching({value:true})

    }
    const checked = !![ ...groupsChecked, ...totalChecked,...totalChosen.active ].find((item) => item.pathStr === rowData.pathStr)
    const childrenChecked = findChildrenChecked(rowData.children)
 
    
    const renderFirstChildSpan = (rowData:any) => {
        if (rowData.clientData?.path.length > 1) {
            const findDataArr = Object.values(sortTable).length > 0 ? sortDataRecursive(data, sortTable) : data as any[]
            const parent = findObjectByLabelId(findDataArr,rowData?.clientData.path[rowData.clientData?.path.length - 2],rowData?.clientData.pathLabel[rowData.clientData?.path.length - 2])
            if(rowData?.id === parent?.children[0]?.id){
                return true
            }
        }
        return null;
    };
    useEffect(() => {
        const calculateMaxWidth = () => {
            const elements = document.getElementsByClassName('checkBoxWrapper');
            let maxWidth = 0;

            for (let i = 0; i < elements.length; i++) {
                const elementWidth = elements[i].getBoundingClientRect().width;
                maxWidth = Math.max(maxWidth, elementWidth);
            }

            return maxWidth;
        };

        if (spanRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const maxCheckboxWidth = calculateMaxWidth();

            onCellWidthChange(maxCheckboxWidth);
        }
    }, [onCellWidthChange,groupsChecked.length]);
    const getFilterId = () => {
        const groupingsKey = groupings[rowData.clientData.pathLabel.length -1]?.dataKey
        return groupingsToFilters[groupingsKey]
    }
    const isAlreadyExists = () => {
        let result = false
        try{
            filters.forEach((el)=>{
                if(result){
                    return
                }
                if(Object.prototype.hasOwnProperty.call(el, "text")){
                    const isExistedFilter = filters.find((el)=>el.text === rowData.group.replace(/<a[^>]*>([^<]+)<\/a>/i, '$1'))
                    if(isExistedFilter){
                        result = true
                    }
                }
                if(Object.prototype.hasOwnProperty.call(el, "number")){
                    const rowValue = typeof rowData.group === "number" ? rowData.group : +rowData.group.replace(/<a[^>]*>([^<]+)<\/a>/i, '$1')
                    const isExistedFilter = filters.find((el)=>el.number === rowValue)
                    if(isExistedFilter){
                        result = true
                    }
                }
                if(Object.prototype.hasOwnProperty.call(el, "items")) {
                    const filterId = getFilterId()
                    const filtersCloned = filters.map((el)=>el)
                    const isExistedFilter = filtersCloned.find((el) => el.id === filterId)
                    if (isExistedFilter) {
                        const indexToRemove = isExistedFilter?.items?.findIndex((el: { name: string }) => el.name === rowData.group)
                        if (indexToRemove !== -1) {
                            result = true
                        }
                    }
                }
            })
        }catch(err){
            console.log(err)
        }

        return result
    }
const successModal = (position:{top:string,left:string}) => {
    message.open({
        content: "Фильтр добавлен",
        duration:1,
        type: "success",
        style: {position:"fixed",top:position.top,left:position.left }
    })
}
    const handleGroupClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        const position = event.currentTarget.getBoundingClientRect()
        let filter:IFilter = {}
        const filterId = getFilterId()
        const __data = prepareData(dataFilter, '', [], false,'','','','',[''],'')
        const _data =  generateListKeys(__data)
        const findFilter = _data.find((el:any)=>el.id === filterId )
       if(findFilter){
           switch(findFilter.type){
               case "text":{
                   const isExistedFilter = filters.find((el)=>el.text === rowData.group.replace(/<a[^>]*>([^<]+)<\/a>/i, '$1'))
                   if(isExistedFilter){
                       break
                   }
                   filter = {...findFilter,text:rowData.group.replace(/<a[^>]*>([^<]+)<\/a>/i, '$1')}
                   setFilter(filter)
                   successModal({top:`${position.top}px`,left:`${position.left}px`})
                   setMetaFilterUpdate({value:true})
                   break
               }
               case "number":{
                   const rowValue = typeof rowData.group === "number" ? rowData.group : +rowData.group.replace(/<a[^>]*>([^<]+)<\/a>/i, '$1')
                   const isExistedFilter = filters.find((el)=>el.number === rowValue)
                   if(isExistedFilter){
                       break
                   }
                   filter = {...findFilter,number:rowValue}
                   setFilter(filter)
                   successModal({top:`${position.top}px`,left:`${position.left}px`})
                   setMetaFilterUpdate({value:true})
                   break
               }
               default: {
                   const filtersCloned = filters.map((el)=>el)
                   const isExistedFilter = filtersCloned.find((el)=>el.id === findFilter.id)
                   if(isExistedFilter){
                       const indexToRemove = isExistedFilter.items.findIndex((el:{name:string})=>el.name === rowData.group)
                       if(indexToRemove !== -1){
                           isExistedFilter.items.splice(indexToRemove,1)
                           isExistedFilter.checkeds.splice(indexToRemove,1)
                       }
                       filter = {
                           ...isExistedFilter,
                           items: [...isExistedFilter.items,{id: rowData.id, name: rowData.group}],
                           checkeds: [...isExistedFilter.checkeds,{id: rowData.id, name: rowData.group}]
                       }
                       const modifiedArray = JSON.parse(JSON.stringify(filters)) as IFilter[]
                       const index = modifiedArray.findIndex(el => el.id === filter.id);
                       if (index !== -1) {
                           modifiedArray.splice(index, 1);
                       }
                       setFilters([...modifiedArray, filter])
                       successModal({top:`${position.top}px`,left:`${position.left}px`})
                       setMetaFilterUpdate({value:true})

                   }else{
                       filter = {
                           ...findFilter,
                           items: [{id: rowData.id, name: rowData.group}],
                           checkeds: [{id: rowData.id, name: rowData.group}]
                       }
                       setFilter(filter)
                       successModal({top:`${position.top}px`,left:`${position.left}px`})
                       setMetaFilterUpdate({value:true})
                   }
                   break
               }
           }

       }

    }
    const notToShow = ['total',"total_choosen"]

    return (
        <div className={"checkBoxWrapper"} ref={cellRef} {...bind}>
            {
                isNested ?
                    <Tooltip
                        placement="bottom"
                        title={<div className="page-title-tooltip-text" dangerouslySetInnerHTML={{ __html: "Выбор всех дочерних элементов группировки." }}></div>}
                        color="#fff"
                        zIndex={9999}
                        trigger="hover"
                        overlayClassName="page-title-tooltip"
                    >
                        <Button
                            icon={childrenChecked ? <CheckCircleTwoTone /> : <CheckCircleOutlined />}
                            size="small"
                            type="link"
                            style={{ marginRight: 5, color: "inherit" }}
                            onClick={() => checkChildrenGroup(rowData.children, childrenChecked)}
                        />
                    </Tooltip>
                    :
                    null
            }
            {
                withCheckbox ? (
                    <Checkbox checked={checked} onChange={onChange}>
                        <span ref={spanRef}  dangerouslySetInnerHTML={{ __html: rowData.group }} />
                    </Checkbox>
                ) : (
                    <span ref={spanRef} dangerouslySetInnerHTML={{__html: rowData.group}}/>
                )
            }
            {hovered && !notToShow.includes(rowData.pathStr) && getFilterId() && !isAlreadyExists() && <ToggleFilter handleGroupClick={handleGroupClick} />}
            {(renderFirstChildSpan(rowData) || isFirstGrouping(firstGrouping,firstGrouping,rowData)) && <span style={{position:"absolute",top:0,fontSize:"9px",color:"rgb(196 196 196)",minWidth:"200px",...groupTitlePos}}>{getGroupTitle(rowData,groupings)}</span> }
        </div>
    )
}

export default GroupCell
