import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Space } from "antd";

import Attribution from "./attribution";
import FirstRegIcon from "./attribution/icons/FirstRegIcon";
import LastRegIcon from "./attribution/icons/LastRegIcon";
import TypeIcon from "./attribution/icons/TypeIcon";
import Details from "./details";
import CompareSection from "./filter/compare";
import CompareButton from "./filter/CompareButton";
import DashboardFilter from "./filter/DashboardFilter";
import PeriodSelector from "./period";
import { IAttributionItem, IDetailItem } from "../../../../../app/types/filter";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const fistSelect = {
  id: "type_applications",
  name: "Тип регистрации:",
  selectValues: [
    { name: "Нулевые заказы GC", id: "2", icon: null },
    { name: "Прямая продажа", id: "4", icon: null },
    { name: "Регистрации GC", id: "0", icon: null },
    { name: "SCAN", id: "1", icon: null },
    { name: "По умолчанию", id: "blocks", icon: null },
    //{ name: "Ручное добавление", id: "3", icon: null },
  ],
  description: `Выбор типа регистрации по которым нужно создать отчёт:
                  <br><br>
                  Типы регистраци:
                  <br><br>
                  - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                  - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                  - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                  - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                  <br><br>
                  <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                  `,
  icon: <TypeIcon />,
};

const secondSelect = {
  id: "attribution",
  name: "Атрибуция:",
  selectValues: [
    { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
    { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
    { name: "По умолчанию", id: "blocks", icon: null },
  ],
  description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                  <br><br>
                  - Первая регистрация <br>
                  - Последняя регистрация <br>
                  <br><br>
                  <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                  `,
  icon: null,
};

type SelectedValues = {
  [key: string]: string | string[];  
};

interface IParamsProps {
  withAttribution?: boolean;
  attributionItems?: IAttributionItem[];
  withDetails?: boolean;
  detailsItems?: IDetailItem[];
  withCompare?: boolean;
  withSegments?: boolean;
  filtersFilter?: string[];
  isFilterVisible?: boolean;
  setUpdateDashboard: () => void;
  fetchDashboardDataButtonVisible?: boolean;
  setFetchDashboardDataButtonVisible?: Dispatch<SetStateAction<boolean>>;
}

const DashboardParams: FC<IParamsProps> = ({
  withAttribution = true,
  attributionItems,
  withDetails = true,
  detailsItems,
  withCompare = true,
  withSegments = true,
  filtersFilter,
  isFilterVisible = true,
  setUpdateDashboard,
  fetchDashboardDataButtonVisible = false,
  setFetchDashboardDataButtonVisible,
}) => {
  const { isCompare, isNotParamsFilterVisible } = useTypedSelector(
    (state) => state.meta
  );
  const { currentDashboard } = useTypedSelector((state) => state.reports);
  const [ stateAttributionItems, setStateAttributionItems ] = useState<IAttributionItem[]>([]);
  const [ stateDetailsItems, setStateDetailsItems ] = useState<IDetailItem[]>([]);
  const selects = [ fistSelect, secondSelect ];
  const [, setSelectedValues ] = useState<SelectedValues>({});

  useEffect(() => {
    const initialValues: SelectedValues = {};
    
    selects.forEach((select) => {
      const selectedIds = select.id === "type_applications"
        ? currentDashboard?.values?.attributesDashboard?.type_applications || []
        : currentDashboard?.values?.attributesDashboard?.attribution || [];

    
      initialValues[select.id] = selectedIds.length > 0 ? selectedIds : [ "blocks" ];
    });

    setSelectedValues(initialValues);  
  }, [ currentDashboard ]);  

  useEffect(() => {
    if (attributionItems) {
      setStateAttributionItems(attributionItems);
    }
  }, [ attributionItems ]);

  useEffect(() => {
    if (detailsItems) {
      setStateDetailsItems(detailsItems);
    }
  }, [ detailsItems ]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        padding: "15px 15px 0px 25px",
        display: "flex",
      }}
    >
      {!isCompare && <PeriodSelector isCompare={isCompare} setUpdateDashboard={setUpdateDashboard} />}
      <Space
        direction="horizontal"
        style={{
          position: "relative",
          display: "flex",
          flexWrap: "wrap", 
        }}
      >
        {withAttribution && stateAttributionItems && (
          <div>
            <Attribution items={stateAttributionItems} setBtnDisabled={setFetchDashboardDataButtonVisible}/>
          </div>
        )}
        {withDetails && stateDetailsItems && <Details items={stateDetailsItems} />}
        {withCompare && <CompareButton isCompare={isCompare} />}
      </Space>
      <div className="filter-wrapper">
        {isCompare ? (
          <CompareSection filtersFilter={filtersFilter} />
        ) : (
          isFilterVisible &&
          !isNotParamsFilterVisible && (
            <DashboardFilter
              isCompare={isCompare}
              withSegment={withSegments}
              filtersFilter={filtersFilter}
              setUpdateDashboard={setUpdateDashboard}
              fetchDashboardDataButtonVisible={fetchDashboardDataButtonVisible}
              setFetchDashboardDataButtonVisible={setFetchDashboardDataButtonVisible}
            />
          )
        )}
      </div>
    </Space>
  );
};

export default DashboardParams;