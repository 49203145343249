import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react"

import { Responsive, WidthProvider } from "react-grid-layout"

import Card from "./components/Card"
import AddBlockModal from "./components/modal/AddBlockModal"
import CopyBlockModal from "./components/modal/CopyBlockModal"
import EditBlockModal from "./components/modal/EditBlockModal"
import styles from "./Dashboards.module.scss"
import { BlockVisible } from "./types"
import {
  defaultGroupings,
  defaultMetrics,
} from "../../app/providers/redux/slices/reports/table/tableSlice"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import UniversalReportTitle from "../../shared/common/components/reports/dashboards/title/UniversalReportTitle"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"

import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import Table from "../../shared/common/components/reports/dashboards/Table"

import { Button, Dropdown, Menu, Tooltip } from "antd"
import {
  ArrowsAltOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons"

import Chart from "../../shared/common/components/reports/dashboards/Chart"
import ClarityCursorHandGrabLine from "../../shared/common/components/ui/icons/ClarityCursorHandGrabLine"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon"
import { dashboardChartDetails } from "../../shared/common/constants/details"
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon"
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon"

import { throttle } from "lodash"

import DashboardParams from "../../shared/common/components/reports/params/DashboardParams"
import DashboardParamsFixed from "../../shared/common/components/reports/params/DashboardParamsFixed"
import { STATIC_LINK_DRAWER } from "../../shared/common/constants/staticLinkConstants"
import { ReportMenu } from "../../shared/common/components/nav/components/reportMenu/reportMenu"

interface GridItemLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
}
export interface DashboardComponent {
  title?: string;
  componentType: string;
  layoutData: GridItemLayout;
  data: any;
}

const ResponsiveGridLayout = WidthProvider(Responsive)
const attrItems = [
  {
    id: "type_applications",
    name: "Тип регистрации:",
    selectValues: [
      { name: "Нулевые заказы GC", id: "2", icon: null },
      { name: "Прямая продажа", id: "4", icon: null },
      { name: "Регистрации GC", id: "0", icon: null },
      { name: "SCAN", id: "1", icon: null },
      { name: "По умолчанию", id: "blocks", icon: null },
      //{ name: "Ручное добавление", id: "3", icon: null },
    ],
    description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Прямая продажа - эта регистрация создаётся автоматически в SCANе при создании платного заказа. Название регистрации будет являться название платного предложения;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
    icon: <TypeIcon />,
  },
  {
    id: "attribution",
    name: "Атрибуция:",
    selectValues: [
      { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
      { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
      { name: "По умолчанию", id: "blocks", icon: null },
    ],
    description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
    icon: null,
  },
]

const Dashboards: FC = () => {
  const [ addBlockVisible, setAddBlockVisible ] = useState<BlockVisible>({
    visible: false,
    blockType: "",
  })
  const [ editBlockState, setEditBlockState ] = useState({
    visible: false,
    id: "",
    data: {},
  })
  const [ cloneBlockState, setCloneBlockState ] = useState({
    visible: false,
    id: "",
    data: {},
  })
  const [ fetchDashboardDataButtonVisible, setFetchDashboardDataButtonVisible ] =
    useState(true)

  const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
  const { isFixedHeader } = useTypedSelector((state) => state.dashboard)
  const { isChartLoading, isTableLoading } = useTypedSelector(
    (state) => state.meta
  )
  const { filters } = useTypedSelector((state) => state.filter)
  const [ dashboardFilters, setDashboardFilters ] = useState(filters)
  const [ isUpdated, setIsUpdated ] = useState(false)
  const {
    setPeriod,
    setFilters,
    setMetrics,
    setGroupings,
    setFiltersTable,
    setCurrentReport,
    setCurrentSegment,
    setSortTable,
    setMetaCompare,
    setSeries,
    setMetricsChecked,
    setMetaChartFetching,
    setAttributesDashboardBlocks,
    setDashboardBlockDetails,
    setDashboardsBlocksInitialState,
    setDashboardsBlocksTableInitialState,
    setDashboardsBlocksChartState,
    setCurrentFunnel,
    setDashboards,
    setIsFixedHeader,
    getSavedDashboards,
    setDrawerLink,
    setAppReportOpen,
    setAllBlocksFetching,
    setAllDataFetching,
  } = useActions();
  useEffect(() => {
    setMetaCompare({ value: false })
    setMetrics({ data: defaultMetrics })
    setGroupings({ data: defaultGroupings })
    setFilters([])
    setFiltersTable([])
    setCurrentReport({})
    setSortTable({})
    setCurrentSegment(null)
    setSeries([])
    setTimeout(() => {
      setMetaChartFetching({ value: true })
    }, 700)
    setMetricsChecked({
      data: [
        { label: defaultMetrics[0].label, name: defaultMetrics[0].dataKey },
      ],
    })

    return () => {
      setMetaCompare({ value: false })
      setMetrics({ data: defaultMetrics })
      setGroupings({ data: defaultGroupings })
      setFilters([])
      setFiltersTable([])
      setCurrentReport({})
      setSortTable({})
      setCurrentFunnel([])
      setCurrentSegment(null)
      setMetricsChecked({ data: [] })
    }
  }, [])
  const { currentDashboard, isLoading } = useTypedSelector(
    (state) => state.reports
  )

  const [ dashboardComponents, setDashboardComponents ] = useState<
    DashboardComponent[]
  >([])

  const handleOpenHelpFrame = () => {
    setDrawerLink(STATIC_LINK_DRAWER.dashboards)
    setAppReportOpen(true)
  }

  useEffect(() => {
    if (Object.keys(currentDashboard)?.length > 0) {
      setDashboardComponents(currentDashboard?.values?.blocks || [])
      handleCreatingBlocksState(currentDashboard?.values?.blocks || [])
    }
  }, [ currentDashboard ])
  const handleCreatingBlocksState = (blocks: any[]) => {
    const dashboardComponentsIds: string[] = blocks.map(
      (el) => el.data.blockId
    )
    setDashboardsBlocksTableInitialState({ ids: dashboardComponentsIds })
    setDashboardsBlocksInitialState(dashboardComponentsIds)
    blocks.forEach((component, index) => {
      setDashboardsBlocksChartState({
        ids: [ component.data.blockId ],
        data: component.data.chartType,
      })
      const { attribution, details, blockId } = component.data
      setAttributesDashboardBlocks({
        blockId,
        attribution: attribution?.attribution,
        type_applications: attribution?.type_applications,
      })
      setDashboardBlockDetails({
        blockId,
        detail: details,
      })
    })
  }
  useEffect(() => {
    handleCreatingBlocksState(dashboardComponents)
    setIsUpdated(true)
  }, [])

  const onLayoutChange = (newLayout: GridItemLayout[]) => {
    const updatedComponents = dashboardComponents.map((component) => {
      const layoutItem = newLayout.find(
        (item) => item.i === component.layoutData.i
      )
      return layoutItem ? { ...component, layoutData: layoutItem } : component
    })

    setDashboardComponents(updatedComponents)
  }

  useEffect(() => {
    setDashboards(dashboardComponents)
  }, [ dashboardComponents ])

  const handleAddBlock = (newComp: DashboardComponent) => {
    setDashboardComponents((prev) => [ ...prev, newComp ])
  }

  const handleEditBlock = (updComp: DashboardComponent) => {
    setDashboardComponents((prevState) => {
      const updCompIndex = prevState.findIndex(
        (comp) => comp.data.blockId === updComp.data.blockId
      )

      if (updCompIndex !== -1) {
        const newDashboardComponents = [ ...prevState ]
        newDashboardComponents[updCompIndex] = updComp
        return newDashboardComponents
      }

      return prevState
    })
  }

  const handleDelete = (id: string) => {
    setDashboardComponents(
      dashboardComponents.filter((component) => component.layoutData.i !== id)
    )
  }
  const handleEdit = (id: string) => {
    setEditBlockState({
      id,
      visible: true,
      data:
        dashboardComponents.find(
          (component) => component.layoutData.i === id
        ) || {},
    })
  }

  const handleClone = (id: string) => {
    const componentToClone = dashboardComponents.find(
      (component) => component.layoutData.i === id
    )

    if (componentToClone) {
      const cloneData = {
        ...componentToClone,

        layoutData: {
          ...componentToClone.layoutData,
          i: `${Date.now()}`,
        },
      }
      setCloneBlockState({
        id: `${Date.now()}`,
        visible: true,
        data: cloneData,
      })
    }
  }

  const handleAddClonedBlock = (newComp: DashboardComponent) => {
    setDashboardComponents((prev) => [ ...prev, newComp ])
    setCloneBlockState({
      visible: false,
      id: "",
      data: {
        title: "",
        componentType: "",
        layoutData: {
          i: "",
          x: 0,
          y: 0,
          w: 0,
          h: 0,
        },
        data: {},
      },
    })
  }

  const menu = (component: any) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(component.layoutData.i)}>
        <Button type="text">Редактировать</Button>
      </Menu.Item>
      <Menu.Item
        key="clone"
        onClick={() => handleClone(component.layoutData.i)}
      >
        <Button type="text">Копировать</Button>
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() => handleDelete(component.layoutData.i)}
      >
        <Button type="text">Удалить</Button>
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (
      editBlockState.visible ||
      addBlockVisible.visible ||
      cloneBlockState.visible
    ) {
      setDashboardFilters(filters)
      return
    }
    if (
      !editBlockState.visible &&
      !addBlockVisible.visible &&
      !cloneBlockState.visible
    ) {
      setFilters(dashboardFilters)
    }
  }, [
    editBlockState.visible,
    addBlockVisible.visible,
    cloneBlockState.visible,
  ])
  useEffect(() => {
    if (
      dashboardFilters?.length !== filters?.length &&
      !editBlockState.visible &&
      !addBlockVisible.visible &&
      !cloneBlockState.visible
    ) {
      setDashboardFilters(filters)
    }
  }, [ filters ])
  useEffect(() => {
    localStorage.setItem("dashboardFilters", JSON.stringify(dashboardFilters))
  }, [ dashboardFilters ])

  const paramsRef = useRef(null)

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (paramsRef.current) {
        const scrollY = window.scrollY || window.pageYOffset
        setIsFixedHeader(scrollY >= 280)
      }
    }, 200)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const layout = dashboardComponents.map((component) => ({
    ...component.layoutData,
  }))

  const memoizedGridLayout = useMemo(() => {
    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={7}
        onLayoutChange={(layout) => onLayoutChange(layout)}
        isDraggable={true}
        isResizable={true}
        draggableHandle={`.${styles.dragIcon}`}
        resizeHandle={
          <div
            className={`${styles.resizeHandle}`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "20px",
              color: "#c8c8c8d9",
            }}
          >
            <ArrowsAltOutlined rotate={90} />
          </div>
        }
        onResizeStart={() => {
          document.body.style.overflow = "hidden"
        }}
        onResizeStop={() => {
          document.body.style.overflow = "auto"
        }}
        margin={[ 16, 16 ]}
      >
        {dashboardComponents.map((component) => {
          let ComponentToRender
          switch (component.componentType) {
            case "table":
              ComponentToRender = Table
              break
            case "number":
              ComponentToRender = Card
              break
            case "chart":
              ComponentToRender = Chart
              break
            case "heading":
              ComponentToRender = Fragment
              break
            default:
              ComponentToRender = null
          }

          const getStyles = () => {
            let styles = {}

            switch (component.componentType) {
              case "table":
                styles = { marginTop: "-24px", width: "max-content" }
                break
              case "number":
                styles = { marginTop: "-24px", height: "100%" }
                break
              case "chart":
                styles = {
                  marginTop: "-24px",
                  height: "100%",
                  ...(component.data?.chartType === "pie"
                    ? { paddingTop: "20px" }
                    : {}),
                }
                break
              default:
                styles = {
                  marginTop: "-24px",
                  width: "100px", // например, если каждый блок 100px
                  height: "100px", // если rowHeight = 30px
                }
            }

            return styles
          }

          return ComponentToRender ? (
            <div
              className={`${styles.componentContainer} ${
                component.componentType !== "heading"
                  ? styles.smoothComponent
                  : styles.heading
              }`}
              style={{
                overflow:
                  component.componentType === "number" ? "hidden" : "hidden",
                boxShadow: "none",
              }}
              id={`component-${component.data.blockId}`}
              key={component.layoutData.i}
            >
              <div className={styles.headerBlock}>
                <div className={`${styles.dragIcon} drag-handler`}>
                  <ClarityCursorHandGrabLine className="drag-icon" />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown overlay={menu(component)} trigger={[ "click" ]}>
                    <Button type="text" icon={<EllipsisOutlined />} />
                  </Dropdown>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "8px",

                    ...(component.componentType === "heading"
                      ? {
                          position: "absolute",
                          top: "10px",
                          overflow: "hidden",
                        }
                      : {
                          marginTop: "-44px",
                          position: "relative",
                          zIndex: 999,
                        }),
                  }}
                >
                  <h2
                    style={{
                      ...(component.componentType === "heading" && {
                        fontWeight: 800,
                      }),
                      marginBottom: 0,
                    }}
                    className={`${styles.title}`}
                  >
                    {component.title}
                  </h2>
                  {component.data?.description && (
                    <Tooltip
                      placement="bottom"
                      title={
                        <div
                          className="page-title-tooltip-text"
                          dangerouslySetInnerHTML={{
                            __html: component.data.description,
                          }}
                        />
                      }
                      color="#fff"
                      zIndex={9999}
                      trigger="click"
                      overlayClassName="page-title-tooltip"
                    >
                      <QuestionCircleOutlined
                        style={{ color: "#ccc", fontSize: 14, marginTop: -5 }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className={styles.container}>
                <div style={getStyles() && { width: "100%" }}>
                  <ComponentToRender {...component.data} isShow={true} />
                </div>
              </div>
            </div>
          ) : null
        })}
      </ResponsiveGridLayout>
    );
  }, [ isUpdated, dashboardComponents, layout ]); 

  const handleUpdateDashboardData = () => {
    setIsUpdated(true);
    setAllBlocksFetching({ value: true })
    setAllDataFetching({ value: true })
  }

  const memorizedParams = useMemo(() => {
    return (
        <DashboardParams
          withCompare={false}
          withDetails={true}
          withSegments={true}
          isFilterVisible={true}
          detailsItems={dashboardChartDetails}
          attributionItems={attrItems}
          filtersFilter={[
            "Пользователи",
            "Регистрации",
            "Заявки",
            "Заказы",
            "Платежи",
            "Вебинары",
            "Реклама",
            "Просмотр страницы после регистрации",
          ]}
          setUpdateDashboard={handleUpdateDashboardData}
          fetchDashboardDataButtonVisible={fetchDashboardDataButtonVisible}
          setFetchDashboardDataButtonVisible={setFetchDashboardDataButtonVisible}
        />
    );
  }, [ dashboardChartDetails, fetchDashboardDataButtonVisible ]);

  const memorizedFixedParams = useMemo(() => {
    return (
      <DashboardParamsFixed
        withCompare={false}
        withDetails={true}
        withSegments={true}
        isFilterVisible={true}
        detailsItems={dashboardChartDetails}
        filtersFilter={[
          "Пользователи",
          "Регистрации",
          "Заявки",
          "Заказы",
          "Платежи",
          "Вебинары",
          "Реклама",
          "Просмотр страницы после регистрации",
        ]}
        setUpdateDashboard={handleUpdateDashboardData}
        fetchDashboardDataButtonVisible={fetchDashboardDataButtonVisible}
        setFetchDashboardDataButtonVisible={setFetchDashboardDataButtonVisible}
        setAddBlockVisible={setAddBlockVisible}
      />
    )
  }, [ dashboardChartDetails, fetchDashboardDataButtonVisible ])

  const divStyles = isFixedHeader
    ? ({
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        top: 0,
        background: "white",

        padding: "15px",
        maxWidth: "87vw",
        display: "flex",
        justifyContent: "flex-end",

        flexWrap: "wrap",
      } as const)
    : {}

  useEffect(() => {
    if (selectedSchool?.id) {
      getSavedDashboards(selectedSchool.id)
    }
  }, [ selectedSchool?.id ])

  useEffect(() => {
    if (currentDashboard?.values?.period) {
      const newPeriod = {
        start: currentDashboard.values.period.start ?? "",
        end: currentDashboard.values.period.end ?? "",
        interval: currentDashboard.values.period.interval ?? "",
        select: currentDashboard.values.period.select ?? "",
      }
      localStorage.setItem("current_un_period", JSON.stringify(newPeriod))
      if (
        currentDashboard?.values?.period?.start &&
        currentDashboard?.values?.period?.end
      ) {
        setPeriod({
          ...newPeriod,
          days: undefined,
        })
      }
    }
  }, [ currentDashboard.id ])

  return (
    <MainLayout pageTitle="Дашборды">
      <div
        className="page-wrapper"
        style={{
          borderRadius: "30px",
          background: "#fff",
          marginLeft: "20px",
          width: "97.6%",
          position: "relative",
        }}
      >
        {!isFixedHeader && (
          <div style={{ position: "absolute", top: 6, right: 15 }}>
            <UniversalReportTitle setAddBlockVisible={setAddBlockVisible} />
          </div>
        )}
        <Loader loading={isLoading}>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                marginLeft: "35px",
                marginRight: "15px",
              }}
            >
              {currentDashboard?.values?.name
                ? `Дашборд: ${currentDashboard.values.name}`
                : "Дашборд не выбран"}
            </div>
            <Button
              onClick={handleOpenHelpFrame}
              style={{
                background: "#35c45b",
                color: "#fff",
                borderRadius: "15px",
                padding: "5px 10px 28px 10px",
                textAlign: "center",
                zIndex: "2",
              }}
            >
              Инструкция
            </Button>
            {currentDashboard.id && (
              <ReportMenu isDashboard={true} itemId={currentDashboard.id} />
            )}
          </div>
        </Loader>
        <Loader loading={isTableLoading || isChartLoading || isLoading}>
          <div>{memorizedParams}</div>

          <div
            ref={paramsRef}
            style={divStyles}
            className={isFixedHeader ? styles.fixedHeader : ""}
          >
            {isFixedHeader && memorizedFixedParams}
          </div>
        </Loader>
        {addBlockVisible.visible && (
          <AddBlockModal
            blockType={addBlockVisible.blockType}
            visible={addBlockVisible.visible}
            setVisible={setAddBlockVisible}
            onAddBlock={handleAddBlock}
          />
        )}
        {editBlockState.visible && (
          <EditBlockModal
            editState={editBlockState}
            setEditState={setEditBlockState}
            onEditBlock={handleEditBlock}
          />
        )}
        {cloneBlockState.visible && (
          <CopyBlockModal
            onAddBlock={handleAddClonedBlock}
            editState={cloneBlockState}
            setEditState={setCloneBlockState}
          />
        )}
      </div>

      <div style={{ paddingLeft: "5px" }}>{memoizedGridLayout}</div>
    </MainLayout>
  )
}

export default Dashboards
