// ButtonContext.tsx
import React, { createContext, useContext, useState } from 'react';

// Определяем типы состояния кнопки
interface ButtonContextType {
  isFirstButtonActive: boolean;
  toggleFirstButton: () => void;
  isSecondButtonActive: boolean;
  toggleSecondButton: () => void;
}

// Создаём контекст с начальными значениями
const ButtonContext = createContext<ButtonContextType | undefined>(undefined);

// Провайдер для предоставления состояния
export const ButtonProvider: React.FC = ({ children }) => {
  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
  const [isSecondButtonActive, setIsSecondButtonActive] = useState(false);

  const toggleFirstButton = () => setIsFirstButtonActive(!isFirstButtonActive);
  const toggleSecondButton = () => setIsSecondButtonActive(!isSecondButtonActive);

  return (
    <ButtonContext.Provider value={{ isFirstButtonActive, toggleFirstButton, isSecondButtonActive, toggleSecondButton }}>
      {children}
    </ButtonContext.Provider>
  );
};

// Хук для использования контекста в компонентах
export const useButtonContext = () => {
  const context = useContext(ButtonContext);
  if (!context) {
    throw new Error('useButtonContext must be used within a ButtonProvider');
  }
  return context;
};
