import React, { FC, useEffect, useState } from "react";

import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, FormInstance, Popover, Tooltip } from "antd";

interface Props {
    id: string;
    name: string;
    selectValues: Array<{
        name: string;
        id: string;
        icon?: React.ReactElement | null;
    }>;
    description?: string | null;
    icon?: React.ReactElement | null;
    form: FormInstance<any>;
    handleValueChange?: (key: string, value: string) => void;
    initialValue?: string | null;
}

const FormManualSelect = ({
    id,
    icon,
    name,
    selectValues,
    description,
    form,
    handleValueChange,
    initialValue,
}: Props) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [currentSelection, setCurrentSelection] = useState({
        name: "",
        icon: null,
    });

    const handleVisibleChange = (newVisible: boolean) => {
        setIsPopoverVisible(newVisible);
    };

    const activateSelection = (value: any) => {
        handleValueChange && handleValueChange(id, value?.id);
        setCurrentSelection(value);
        setIsPopoverVisible(false);
    };

    useEffect(() => {
        const selectedValue = initialValue || form.getFieldValue(id); // Получаем начальное значение из пропсов или формы
        const selectedOption = selectValues.find((el) => el.id === selectedValue)

        if (selectedOption) {
            setCurrentSelection({
                name: selectedOption.name,
                icon: null,
            });
        } else {
            setCurrentSelection({ name: "", icon: null });
        }

    }, [form, id, selectValues, initialValue]);

    const list = () => (
        <div className="params__attr-select-list">
            {selectValues.length > 0 ? (
                selectValues.map((value: any, index: number) => (
                    <div
                        key={index}
                        className="segment-list-item"
                        onClick={() => activateSelection(value)}
                    >
                        {value?.icon ? value?.icon : null} {value.name}
                    </div>
                ))
            ) : (
                <div>Нет данных</div>
            )}
        </div>
    );

    return (
        <div className="params__attr-list-item">
            <Popover
                content={list}
                open={isPopoverVisible}
                onOpenChange={handleVisibleChange}
                trigger="click"
                placement="bottomLeft"
                showArrow={false}
                overlayStyle={{ paddingTop: 0 }}
            >
                <Button
                    icon={icon ? icon : null}
                    className="params__segment-show-button"
                >
                    {name}&nbsp;
                    {currentSelection?.icon ? currentSelection?.icon : null}{" "}
                    {currentSelection.name ? currentSelection.name : "Не выбрано"}
                    &nbsp; <DownOutlined />
                </Button>
            </Popover>

            {description && (
                <span style={{ marginLeft: 6, marginRight: 10, marginTop: 3 }}>
                    <Tooltip
                        placement="bottom"
                        title={
                            <div
                                className="page-title-tooltip-text"
                                dangerouslySetInnerHTML={{ __html: description }}
                            ></div>
                        }
                        color="#fff"
                        zIndex={9999}
                        trigger="click"
                        overlayClassName="page-title-tooltip"
                    >
                        <QuestionCircleOutlined style={{ color: "#ccc", fontSize: 14 }} />
                    </Tooltip>
                </span>
            )}
        </div>
    );
};
export default FormManualSelect;