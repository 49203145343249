import React, { FC, useEffect, useState } from "react";

import { Checkbox, Input } from "antd";
import classNames from "classnames";

import { finishRows } from "../../../../../../helpers/utils/reports";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

type Props = {
  children: React.ReactNode;
};

const GroupUtilItemWrapper = ({ children }: Props) => {
  return (
      <div style={{ display: "flex", columnGap: "6px", alignItems: "center" }}>
        {children}
      </div>
  );
};

const GroupHeader: FC<any> = ({ sort, dataKey, blockId, withCheckbox }) => {
  const {
    groupings,
    groupsChecked,
    totalChecked,
    tableData,
    percentages,
    filtersTable,
    sortTable,
  } = useTypedSelector((state) =>
      blockId ? state.table.blocks[blockId] : state.table
  );

  const {
    clearTotalChecked,
    setGroupingsChecked,
    setSortTable,
    setMetaChartFetching,
    setMetaCompareChartFetching,
  } = useActions();

  const [, setSelectedRows] = useState<any[]>([]);

  const clearCheckedGroups = () => {
    clearTotalChecked();
    setGroupingsChecked([]);
  };

  const setCheckedForCurrentGroup = () => {
    try {
      const data = finishRows({
        rows: tableData.data,
        filters: filtersTable,
        sort: sortTable,
        percentages,
        totalRow: tableData.total,
      });

      const firstGroupings = data.slice().map((item: any) => ({
        ids: [item.id ? item.id : item.pathStr],
        pathStr: item.pathStr,
        label: item.group,
      }));

      clearTotalChecked();
      setGroupingsChecked(firstGroupings);
    } catch (e) {
      console.log(e);
    }
  };

  const selectedUncheckedGroupings = () => {
    try {
      const data = finishRows({
        rows: tableData.data,
        filters: filtersTable,
        sort: sortTable,
        percentages,
        totalRow: tableData.total,
      });

      const firstGroupings = data.slice().map((item: any) => ({
        ids: [item.id ? item.id : item.pathStr],
        pathStr: item.pathStr,
        label: item.group,
      }));
 
      const newGroupingsChecked = firstGroupings.filter(
          (group: { ids: string[]; pathStr: string; label: string }) =>
              !groupsChecked.some(
                  (checkedGroup) => checkedGroup.pathStr === group.pathStr
              )
      );

      clearTotalChecked();
      setGroupingsChecked(newGroupingsChecked);
    } catch (e) {
      console.log(e);
    }
  };

  const setFirstNGroupsChecked = (n: number) => {
    try {
      const data = finishRows({
        rows: tableData.data,
        filters: filtersTable,
        sort: sortTable,
        percentages,
        totalRow: tableData.total,
      });

      const firstGroupings = data.slice().filter((item: any) => item.pathStr !== "total").map((item: any) => ({
        ids: [item.id ? item.id : item.pathStr],
        pathStr: item.pathStr,
        label: item.group,
      }));

      const firstNGroupings = firstGroupings.slice(0, n);

      clearTotalChecked();
      setGroupingsChecked(firstNGroupings);
    } catch (e) {
      console.log(e);
    }
  };

  const onChange = () => {
    setCheckedForCurrentGroup();
    setMetaChartFetching({ value: true });
    setMetaCompareChartFetching({ value: true });
  };

  const onUnselectAll = () => {
    selectedUncheckedGroupings();
    setMetaChartFetching({ value: true });
    setMetaCompareChartFetching({ value: true });
  };

  const handleFirstNChange = (n: number) => {
    setFirstNGroupsChecked(n);
    setMetaChartFetching({ value: true });
    setMetaCompareChartFetching({ value: true });
    setSelectedRows([]);
  };

  const sortGroups = () => {
    const sortStates = ["DESC", "ASC", null]; // Последовательность переключения
    const currentIndex = sortStates.indexOf(sort[dataKey] || null); // Определяем текущий индекс
    const nextIndex = (currentIndex + 1) % sortStates.length; // Получаем следующий индекс
    const newSort = sortStates[nextIndex] ? { [dataKey]: sortStates[nextIndex] } : {}; // Если null, сбрасываем сортировку
    
    setSortTable(newSort);
  };
  

  const [currentUtil, setCurrentUtil] = useState("");
  const [nValue, setNValue] = useState(0);

  const handleUtilSelect = (util: "firstN" | "notChosen" | "all") => {
    setCurrentUtil((prev) => (prev === util ? "" : util));
  };

  type DiagramUtils = "firstN" | "notChosen" | "all";

  useEffect(() => {
    const utilFunctions: Record<"firstN" | "notChosen" | "all", () => void> = {
      firstN: () => {
        handleFirstNChange(nValue);
        clearTotalChecked();
      },
      notChosen: () => {
        onUnselectAll();
      },
      all: () => {
        onChange();
      },
    };

    if (currentUtil) {
      utilFunctions[currentUtil as DiagramUtils]();
    } else {
      setGroupingsChecked([]);
      clearTotalChecked();
    }
  }, [currentUtil]);

  const diagramUtils = (
      <div
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "column",
            fontWeight: 400,
            fontSize: "14px",
            rowGap: "10px",
          }}
      >
        <GroupUtilItemWrapper>
          <Checkbox
              checked={currentUtil === "firstN"}
              onChange={() => handleUtilSelect("firstN")}
          />
          <span>Показать на диаграмме первые</span>
          <Input
              placeholder={"N"}
              onChange={(e) => {
                const n = parseInt(e.target.value, 10);
                setNValue(n);
                if (n > 0 && currentUtil === "firstN") {
                  handleFirstNChange(n);
                }
              }}
              style={{ width: "45px", fontSize: "13px", height: "23px" }}
          />
          <span>Строк</span>
        </GroupUtilItemWrapper>
      
        <GroupUtilItemWrapper >
          <Checkbox
              checked={currentUtil === "all"}
              onChange={() => handleUtilSelect("all")}
          />
          <span>Показать на диаграмме все строки</span>
        </GroupUtilItemWrapper>
      </div>
  );

  return (
      <div style={{ marginLeft: -18 }}>
        <div
            className={classNames(
                "table-header-sort",
                "table-header-sort-right",
                "table-header-withIcon",
                {
                  "sort-asc": sort[dataKey] === "ASC",
                  "sort-desc": sort[dataKey] === "DESC",
                }
            )}
        >
        <span style={{ cursor: "pointer" }} onClick={() => sortGroups()}>
          {groupings?.length > 0
              ? groupings.map((item: any) => item.label).join(" → ")
              : "Группировки не выбраны"}
        </span>
          {withCheckbox && diagramUtils}
        </div>
      </div>
  );
};

export default GroupHeader;
