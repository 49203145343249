import React, { FC, useEffect, useState } from "react"

import MainLayout from "../../../shared/common/components/layouts/MainLayout"
import Loader from "../../../shared/common/components/loader/Loader"
import PageHead from "../../../shared/common/components/page-header/PageHeader"
import Details from "../../../shared/common/components/reports/params/details"
import Filter from "../../../shared/common/components/reports/params/filter"
import PeriodSelector from "../../../shared/common/components/reports/params/period"
import WebinarChart from "../../../shared/common/components/reports/webinar-chart/WebinarChart"
import { useActions } from "../../../shared/common/hooks/useActions"
import UniversalReportTitle from "../../../shared/common/components/reports/un-title/UniversalReportTitle";

const WebinarQuality: FC = () => {

    const [ isLoading, setIsLoading ] = useState(false)
    const { setAllDataFetching, setCurrentSegment,setMetaCompareChartFetching,setMetaCompareTableFetching,setMetaTableFetching,setMetaChartFetching } = useActions()
    useEffect(() => {
        setCurrentSegment(null)
        return () => {
            setMetaCompareChartFetching({value:true})
            setMetaCompareTableFetching({value:true})
            setMetaTableFetching({value:true})
            setMetaChartFetching({value:true})
        }
    }, [])

    const details = [
        { name: "1 минута", value: "1m" },
        { name: "5 минут", value: "5m" },
        { name: "15 минут", value: "15m" },
    ]

    return (
        <MainLayout pageTitle="Качество вебинара">
            <div className="page-wrapper">
                <PageHead
                    title="Качество вебинара"
                    description={`
                        Данный отчёт показывает в информационных блоках и на графике активность людей участвовавших на вебинаре в разрезе по времени. Можно увидеть, какой именно сегмент или какой именно человек:
                        - Когда зашёл на вебинар;
                        - Когда вышел с вебинара и больше не заходил на него;
                        - Когда был онлайн;
                        - Когда и сколько оставлял комментариев;
                        - Когда и сколько раз кликах на продающую кнопку или баннер под видео-трансляцией;
                        - Какие делал заказы в течение вебинара.
                    `}
                />

                <div className="page-content">
                    <Loader
                        loading={isLoading}
                        hasTip={false}
                    >
                        <UniversalReportTitle />
                        <PeriodSelector setUpdateDashboard={() => setAllDataFetching({value: true})} isCompare={false} />

                        <div className="external-details">
                            <Details items={details} />
                        </div>

                        <Filter
                            isCompare={false}
                            withSegment={true}
                            filtersFilter={[
                                "Пользователи",
                                "Заявки",
                                "Заказы",
                                "Платежи",
                                "Вебинары",
                            ]}
                        />
                    </Loader>

                    <div>
                        <WebinarChart
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default WebinarQuality
