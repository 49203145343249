import { Dispatch, FC, SetStateAction } from "react";

import { Space } from "antd";

import Attribution from "./attribution";
import Details from "./details";
import CompareSection from "./filter/compare";
import CompareButton from "./filter/CompareButton";
import DashboardFilter from "./filter/DashboardFilter";
import styles from "./Params.module.scss";
import PeriodSelector from "./period";
import { IAttributionItem, IDetailItem } from "../../../../../app/types/filter";
import { BlockVisible } from "../../../../../pages/dashboards/types";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import FixedUniversalTitle from "../dashboards/title/FixedUniversalTitle";

interface IParamsProps {
  withAttribution?: boolean;
  attributionItems?: IAttributionItem[];
  withDetails?: boolean;
  detailsItems?: IDetailItem[];
  withCompare?: boolean;
  withSegments?: boolean;
  filtersFilter?: string[];
  isFilterVisible?: boolean;
  setUpdateDashboard: () => void;
  setAddBlockVisible: Dispatch<SetStateAction<BlockVisible>>
  fetchDashboardDataButtonVisible?: boolean;
  setFetchDashboardDataButtonVisible?: Dispatch<SetStateAction<boolean>>;
}

const DashboardParamsFixed: FC<IParamsProps> = ({
  withAttribution = true,
  attributionItems,
  withDetails = true,
  detailsItems,
  withCompare = true,
  withSegments = true,
  filtersFilter,
  isFilterVisible = true,
  setUpdateDashboard,
  fetchDashboardDataButtonVisible=false,
  setFetchDashboardDataButtonVisible,
  setAddBlockVisible
}) => {
  const { isCompare, isNotParamsFilterVisible } = useTypedSelector(
    (state) => state.meta
  );
  const { isFixedHeader, isOpenFixedHeader } = useTypedSelector(
    (state) => state.dashboard
  );
  
  const shouldRenderPeriodSelector = !isCompare;
  const shouldRenderAttributes = withAttribution && attributionItems;
  const shouldRenderDetails = withDetails && detailsItems && (!isFixedHeader || isOpenFixedHeader);

  const additingFilter = (
    <Space
    direction="horizontal"
    style={{ position: "relative" }}
    className={styles.params}
    >
      {shouldRenderPeriodSelector && <PeriodSelector isFixed={true} isCompare={isCompare} setUpdateDashboard={setUpdateDashboard} />}
      {shouldRenderAttributes && <Attribution items={attributionItems} setBtnDisabled={setFetchDashboardDataButtonVisible} />}
      {shouldRenderDetails && <Details items={detailsItems} />}
      {withCompare && <CompareButton isCompare={isCompare} />}
    </Space>
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Space
        direction="vertical"
        className={styles.Dashboard_Fixed}
        style={{
          ...(isFixedHeader
            ? {
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap",
              }
            : {}),
        }}
      >
        <div
          className="filter-wrapper"
        >
          {isCompare ? (
            <CompareSection filtersFilter={filtersFilter} />
          ) : (
            isFilterVisible &&
            !isNotParamsFilterVisible && (
              <DashboardFilter
                preElement={additingFilter}
                isCompare={isCompare}
                withSegment={withSegments}
                filtersFilter={filtersFilter}
                isFixed={true}
                setUpdateDashboard={setUpdateDashboard}
                fetchDashboardDataButtonVisible={fetchDashboardDataButtonVisible}
                setFetchDashboardDataButtonVisible={setFetchDashboardDataButtonVisible}
                
              />
            )
          )}
        </div>
      </Space>
      {isFixedHeader && <FixedUniversalTitle setAddBlockVisible={setAddBlockVisible} />}
    </div>
  );
};

export default DashboardParamsFixed;
