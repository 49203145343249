import React, { FC, useEffect, useRef, useState } from "react"

import { CloseOutlined } from "@ant-design/icons"
import { Button, Menu } from "antd"
import { Link, NavLink as NativeLink, useLocation, useMatch } from "react-router-dom"

import { STATIC_TAGS } from "./components/constants/tags"
import DynamicMenu from "./components/DynamicMenu"
import SideMenu from "./components/SideMenu"
import TabContent from "./components/TabContent"
import styles from "./Nav.module.scss"
import { TabType } from "./types"
import { fetchTags } from "../../../../app/providers/redux/slices/reports/params/reports/reportsSlice"
import { MenuItem } from "../../../../app/types/nav"
import { clearSearchParams } from "../../../../helpers/utils/functions"
import { routeNames } from "../../constants/routes"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector , useAppDispatch } from "../../hooks/useTypedSelector"

type NavLinkProps = {
    to: string
    onClick: () => void
    children: React.ReactNode
}
const NavLink = ({ to, onClick, children }: NavLinkProps) => {
    const isActive = useMatch(to + "/*")
    const onClickHandler = () => {
        clearSearchParams()
        onClick && onClick()
    }
    return <NativeLink to={to} onClick={onClickHandler} style={{ color: isActive ? "#1890ff" : "inherit" }}>
        {children}
    </NativeLink>
}

const Nav: FC = () => {
    const { setFilters } = useActions()
    const [ isTabContentOpen, setIsTabContentOpen ] = useState(true)
    const [ activeSubTab, setActiveSubTab ] = useState<"all" | "favorites">("all")
    const { getSavedReports } = useActions()
    const { savedReports } = useTypedSelector((state) => state.reports)
    const { savedDashboards } = useTypedSelector((state) => state.reports)
    const [ isSideMenuOpen, setIsSideMenuOpen ] = useState(false)

    const [ activeMainTab, setActiveMainTab ] = useState<"overview" | "dashboards" | "reports" | "ads" | "bd" | "access" | "tools" | "settings" | "import" | "suppliers" | "education">("overview")
    const [ activeTab, setActiveTab ] = useState<TabType>("all")
    const [ activeContentTab, setActiveContentTab ] = useState<"all" | "my" | "schools" | "scan">("all")
    const [ isAdMenuOpen, setIsAdMenuOpen ] = useState(false)
    const [ isBdMenuOpen, setIsBdMenuOpen ] = useState(false)
    const [ isToolsMenuOpen, setIsToolsMenuOpen ] = useState(false)
    const [ isAccessMenuOpen, setIsAccessMenuOpen ] = useState(false)
    const [ isSettingsMenuOpen, setIsSettingsMenuOpen ] = useState(false)
    const [ isImportMenuOpen, setIsImportMenuOpen ] = useState(false)
    const [ isSuppMenuOpen, setIsSuppMenuOpen ] = useState(false)
    const [ showAdConfigFrame, setShowAdConfigFrame ] = useState(false)
    const [ showHelpFrame, setShowHelpFrame ] = useState(false)
    const [ helpFrameUrl, setHelpFrameUrl ] = useState("")
    const dispatch = useAppDispatch()
    const { tags } = useTypedSelector((state) => state.reports)
    const { selectedSchool } = useTypedSelector((state) => state.currentSchool)
    const { setAppReportOpen, setDrawerLink } = useActions()
    const [ isFixed, setIsFixed ] = useState(false)
    const navRef = useRef<HTMLDivElement>(null)

    const location = useLocation()

    useEffect(() => {
        const pathToKeyMap: [string, typeof activeMainTab][] = [
            [ routeNames.schoolStaff, "access" ],
            [ routeNames.adsSpend, "ads" ],
            [ routeNames.adsConnect, "ads" ],
            [ routeNames.orderReports, "reports" ],
            [ routeNames.paymentReports, "reports" ],
            [ routeNames.adReports, "reports" ],
            [ routeNames.webinarReports, "reports" ],
            [ routeNames.yandexMetrics, "tools" ],
            [ routeNames.schoolEdit, "settings" ],
            [ routeNames.schoolLoadData, "settings" ],
            [ routeNames.schoolTrackScript, "settings" ],
            [ routeNames.export, "import" ],
            [ routeNames.partner, "suppliers" ],
            [ routeNames.dashboards, "dashboards" ],
            [ routeNames.home, "overview" ],
        ]

        const matchedEntry = pathToKeyMap.find(([ path ]) =>
            location.pathname.startsWith(path)
        )

        if (matchedEntry) {
            const [ , matchedKey ] = matchedEntry
            if (activeMainTab !== matchedKey) {
                setActiveMainTab(matchedKey)
            }
            closeAllMenus()
        }
    }, [ location.pathname ])

    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current) {
                const rect = navRef.current.getBoundingClientRect()
                // Фиксируем когда верхняя граница достигает верха окна
                setIsFixed(rect.top <= 0)
            }
        }

        window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])


    useEffect(() => {
        if (selectedSchool?.id) {
            getSavedReports(selectedSchool.id)
        }
    }, [ selectedSchool?.id ])

    useEffect(() => {
        dispatch(fetchTags())
    }, [ dispatch ])

    useEffect(() => {
        setActiveContentTab("all")
    }, [])

    useEffect(() => {
        if (location.pathname.startsWith(routeNames.adsConnect) ||
            location.pathname.startsWith(routeNames.adsSpend)) {
            setActiveTab("all")
            closeAdMenu()
        }
    }, [ location.pathname ])

    const transformedData = savedReports?.map(report => ({
        id: report.id,
        name: report.name || "Без названия",
        hash: report.hash,
        type: report.access_id,
        report_id: report.report_id,
        tags: report.tags
            ? Array.isArray(report.tags)
                ? report.tags.map((t: any) => ({ id: Number(t.id), name: String(t.name) }))
                : Object.entries(report.tags).map(([ id, name ]) => ({
                    id: Number(id),
                    name: String(name)
                })
                )
            : [],
        category: (() => {
            switch (report.report_id) {
                case 1: return "registrations"
                case 2: return "orders"
                case 3: return "payments"
                case 4: return "ads"
                case 5: return "webinars"
                default: return "registrations"
            }
        })(),
        is_favorite: report.is_favorite,
        values: report.values || {},
    })) || []

    const transformedDashboardsData = savedDashboards?.map(dashboard => {
        const parseTags = (tags: any) => {
            if (Array.isArray(tags)) {
                return tags.map(t => ({ id: t.id, name: t.name }))
            }
            if (typeof tags === "object" && !Array.isArray(tags)) {
                return Object.entries(tags).map(([ id, name ]) => ({
                    id: Number(id),
                    name: String(name)
                }))
            }
            return []
        }

        return {
            id: dashboard.id,
            name: dashboard.name || "Без названия",
            // type: dashboard.access_id === 2 ? 'школа' : 'my',
            type: dashboard.access_id === 2 ? "школа" : dashboard.access_id === 3 ? "SCAN" : "my",
            tags: parseTags(dashboard.tags),
            is_favorite: dashboard.is_favorite,
            report_id: null, // Добавляем report_id
            hash: null, // Добавляем hash
            values: dashboard.values || {},
        }
    }) || []

    const iframeRef = useRef<HTMLIFrameElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
        if (
            iframeRef.current &&
            !iframeRef.current.contains(event.target as Node)
        ) {
            handleCloseHelpFrame()
            setShowHelpFrame(false)
        }
    }

    useEffect(() => {
        if (showAdConfigFrame || showHelpFrame) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ showAdConfigFrame, showHelpFrame ])


    const items = [
        {
            key: "overview",
            label: <div style={{ cursor: "pointer", fontSize: "14px", fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif" }} onClick={() => handleMainTabClick("overview")}>Сводка</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/overview.png" />,
        },
        {
            key: "dashboards",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleMainTabClick("dashboards")}>Дашборды</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/dashboard.png" />,
        },
        {
            key: "reports",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleMainTabClick("reports")}>Отчёты</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/chart.png" />,
        },
        {
            key: "ads",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleAdTabClick()}>Реклама</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/ads.png" />,
        },
        {
            key: "bd",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleBdTabClick()}>База данных</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/bd.png" />,
        },
        {
            key: "access",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleAccessTabClick()}>Доступы</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/access.png" />,
        },
        {
            key: "tools",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleToolsTabClick()}>Инструменты</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/tools.png" />,
        },
        {
            key: "settings",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleSettingsTabClick()}>Настройка</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/settings.png" />,
        },
        {
            key: "import",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => handleImportTabClick()}>Импорт/Экспорт</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/chart.png" />,
        },
        {
            key: "suppliers",
            label: <Link style={{ cursor: "pointer", fontSize: "14px" }} to={routeNames.partner}>Партнёрка</Link>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/suppl.png" />,
        },
        {
            key: "education",
            label: <div style={{ cursor: "pointer", fontSize: "14px" }} onClick={() => { }}>Обучение</div>,
            icon: <img style={{ width: "20px" }} alt="overview" src="/lesson.png" />,
        },
    ]

    const handleOpenHelpFrame = (url: string) => {
        setDrawerLink(`${url}?timestamp=${new Date().getTime()}`)
        setAppReportOpen(true)
        closeAllMenus()
    }

    const closeAllMenus = () => {
        setIsAdMenuOpen(false)
        setIsBdMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsSideMenuOpen(false)
    }

    const handleCloseHelpFrame = () => {
        setShowHelpFrame(false)
        setHelpFrameUrl("")
    }

    const handleOpenAdConfig = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        handleOpenHelpFrame("https://help.scananalytics.ru/nastroyka-reklamy-dlya-skvoznoy-analitiki/")
    }
    const handleMainTabClick = (tab: typeof activeMainTab) => {
        if (activeMainTab === tab) {
            setIsSideMenuOpen(prev => !prev)
        } else {
            closeAllMenus()
            setActiveMainTab(tab)
            setIsSideMenuOpen(true)
        }
    }

    const handleAdTabClick = () => {
        setIsBdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsAdMenuOpen(prev => !prev)
    }
    const handleBdTabClick = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsBdMenuOpen(prev => {
            if (!prev) {
                setActiveTab("platform")
            }
            return !prev
        })
    }
    const handleAccessTabClick = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsBdMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsAccessMenuOpen(prev => !prev)
    }

    const handleToolsTabClick = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsBdMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsToolsMenuOpen(prev => !prev)
    }
    const handleSettingsTabClick = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsBdMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsImportMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsSettingsMenuOpen(prev => {
            if (!prev) {
                setActiveTab("integrations")
            }
            return !prev
        })
    }
    const handleImportTabClick = () => {
        setIsAdMenuOpen(false)
        setIsSideMenuOpen(false)
        setIsBdMenuOpen(false)
        setIsAccessMenuOpen(false)
        setIsToolsMenuOpen(false)
        setIsSettingsMenuOpen(false)
        setIsSuppMenuOpen(false)
        setIsImportMenuOpen(prev => !prev)
    }

    const openTabContent = (subTab: "all" | "favorites") => {
        setActiveSubTab(subTab)
        setActiveContentTab("all")
    }

    const openSideMenu = () => {
        setIsSideMenuOpen(true)
    }

    const closeSideMenu = () => {
        setIsSideMenuOpen(false)
    }
    const closeAdMenu = () => {
        setIsAdMenuOpen(false)
        setActiveTab("all")
    }
    const closeBdMenu = () => {
        setIsBdMenuOpen(false)
    }

    const closeTabContent = () => {
        setIsTabContentOpen(false)
    }

    const getSelectedKeys = () => {
        // Всегда возвращаем текущую активную вкладку
        return [ activeMainTab ]
    }

    const adMenuItems = [
        {
            key: "ad-accounts",
            label: "Настройка рекламы для сквозной аналитики",
            onClick: () => handleOpenHelpFrame("https://help.scananalytics.ru/nastroyka-reklamy-dlya-skvoznoy-analitiki/")
        },
        { key: "ad-accounts", label: "Подключение рекламных кабинетов", url: routeNames.adsConnect },
        { key: "auto-placement-accounts", label: "Кабинеты автоматического размещения", url: routeNames.adsConnect },
        { key: "auto-placement-ads", label: "Объявления автоматического размещения", url: `${routeNames.adsConnect}?tab=2` },
        { key: "manual-placement-accounts", label: "Кабинеты ручного размещения", url: routeNames.adsSpend },
        { key: "manual-placement-ads", label: "Объявления ручного размещения", url: `${routeNames.adsSpend}?tab=2` },
        { key: "add-ad-spend", label: "Добавить рекламный расход", url: `${routeNames.adsSpend}?tab=2&showModal=true` },
    ]

    const bdMenuSections = [
        {
            title: "",
            items: [ "platform", "webinars", "visits", "ads" ],
            itemLabels: {
                platform: "Платформа",
                webinars: "Вебинары",
                visits: "Посещения",
                ads: "Реклама",
            },
        },
    ]

    const accessMenuItems = [
        {
            key: "access-settings",
            label: "Настройка доступа",
            onClick: () => handleOpenHelpFrame("https://help.scananalytics.ru/sotrudniki/"),
        },
        {
            key: "employees",
            label: "Сотрудники",
            url: routeNames.schoolStaff
        },
        {
            key: "employee-activity",
            label: "Активность сотрудников (скоро)",
            url: ""
        },
    ]

    const toolsMenuItems = [
        {
            key: "yandex-metric",
            label: "Отправка конверсий в Яндекс.Метрику",
            url: `${routeNames.yandexMetrics}?tab=2`
        },
        {
            key: "reportTg",
            label: "Отправка отчётов в Telegram. Радар",
            onClick: () => handleOpenHelpFrame("https://help.scananalytics.ru/otpravka-lyubyh-otchetov-v-telegram-v-opredelyonnoe-vremya-ruka-na-pulse/")
        },
        {
            key: "analytics",
            label: "Робот аналитик (скоро)",
            url: ""
        },
        {
            key: "directolog",
            label: "Робот директолог (скоро)",
            url: ""
        },
        {
            key: "addSchool",
            label: "Подключить новую школу",
            url: routeNames.schoolAdd
        },
    ]

    const settingsMenuSections = [
        {
            title: "",
            items: [ "integrations" ],
            itemLabels: {
                integrations: "Интеграции",
            },
        },
    ]

    const settingsMenuItems = [
        {
            key: "school-info",
            label: "Информация о школе",
            url: routeNames.schoolEdit
        },

        {
            key: "sell",
            label: "Воронки продаж",
            onClick: () => handleOpenHelpFrame("https://help.scananalytics.ru/voronki-prodazh/")
        },
        {
            key: "add-data",
            label: "Ручная загрузка данных по школе",
            url: routeNames.schoolLoadData
        },
        {
            key: "tracking",
            label: "Скрипт отслеживания",
            url: routeNames.schoolTrackScript
        },
        {
            key: "utm",
            label: "Скрипт захвата UTM меток",
            onClick: () => handleOpenHelpFrame("https://help.scananalytics.ru/skript-vstavki-utm-metok-iz-url-v-dop-polya-nulevogo-zakaza-gc/")
        },
    ]

    const importMenuItems = [
        {
            key: "export",
            label: "Экспорт",
            url: routeNames.export
        },
        {
            key: "import",
            label: "Импорт",
            url: ""
        },
    ]
    return (
        <div className={styles.siderChildren} ref={navRef} style={{ position: isFixed ? "static" : "relative" }}>
            <div className={`${styles.navMenu} ${isFixed ? styles.fixed : ""}`}>
                <Menu
                    mode="inline"
                    className={styles.navTabs}
                    items={items}
                    selectedKeys={getSelectedKeys()}
                    onSelect={({ key }) => setActiveMainTab(key as typeof activeMainTab)}
                    style={{ paddingLeft: "10px", paddingRight: "0px", marginTop: "1px", height: isFixed ? "calc(100vh - 20px)" : "auto" }}
                />
            </div>
            <SideMenu
                isOpen={isSideMenuOpen}
                onClose={closeSideMenu}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onOpenHelpFrame={handleOpenHelpFrame}
                tags={tags}
            />
            <DynamicMenu
                isOpen={isAdMenuOpen}
                onClose={closeAdMenu}
                menuType="ad"
                items={adMenuItems}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />

            <DynamicMenu
                isOpen={isBdMenuOpen}
                onClose={closeBdMenu}
                menuType="bd"
                sections={bdMenuSections}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <DynamicMenu
                isOpen={isAccessMenuOpen}
                onClose={() => setIsAccessMenuOpen(false)}
                menuType="access"
                items={accessMenuItems}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <DynamicMenu
                isOpen={isToolsMenuOpen}
                onClose={() => setIsToolsMenuOpen(false)}
                menuType="tools"
                items={toolsMenuItems}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <DynamicMenu
                isOpen={isSettingsMenuOpen}
                onClose={() => setIsSettingsMenuOpen(false)}
                menuType="settings"
                sections={settingsMenuSections}
                items={settingsMenuItems}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <DynamicMenu
                isOpen={isImportMenuOpen}
                onClose={() => setIsImportMenuOpen(false)}
                menuType="import"
                items={importMenuItems}
                activeMainTab={activeMainTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            {(isSideMenuOpen || isBdMenuOpen || isSettingsMenuOpen) && (
                <TabContent
                    mainTab={activeMainTab}
                    subTab={activeTab === "all" || activeTab === "favorites" ? activeTab : "all"}
                    activeContentTab={activeContentTab}
                    onContentTabChange={setActiveContentTab}
                    activeMainTab={activeMainTab}
                    data={activeMainTab === "reports" ? transformedData : transformedDashboardsData}
                    activeReportTab={activeTab === "registrations" || activeTab === "orders" || activeTab === "payments" || activeTab === "webinars" || activeTab === "ads" ? activeTab : undefined}
                    activeTagTab={activeTab === "manager" || activeTab === "traffic" || activeTab === "funnels" || activeTab === "contractors" ? activeTab : undefined}
                    activeTab={activeTab}
                    tags={tags}
                    staticTags={STATIC_TAGS}
                    onItemClick={() => {
                        closeSideMenu()
                        closeTabContent()
                    }}
                />

            )}
        </div>
    )
}

export default Nav