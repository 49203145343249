import React, { FC } from "react"

import { Scatter } from "@ant-design/plots"
import { ScatterConfig } from "@ant-design/plots/es/components/scatter"
const BubbleChartType: FC<any> = ({ data, tooltip, axisMinMax, _metricsChecked }) => {

    const formatInputData = (inputData: any) => {
        let _data: any = []

        if (inputData.length > 0) {
            inputData.forEach((item: any) => {
                _data.push({
                    group: item.name,
                    x: item.data[0][0],
                    y: item.data[0][1],
                    z: item.data[0][2],
                })
            })
        }

        return _data
    }

    const config: ScatterConfig = {
        appendPadding: 30,
      
        data: formatInputData(data),
        xField: "x",
        yField: "y",
        colorField: "group",
        color:[ "#2E93fA", "#35be6a", "#546E7A", "#E91E63", "#FF9800", "#1979C9", "#D62A0D", "#FAA219" ],
        sizeField: "z",
        size: [ 3, 50 ],
        shape: "circle",
        
        legend: false,
        yAxis: {
            nice: true,
            line: {
                style: {
                    stroke: "#eee",
                },
            },
        },
        xAxis: {
            grid: {
                line: {
                    style: {
                        stroke: "#eee",
                    },
                },
            },
            line: {
                style: {
                    stroke: "#eee",
                },
            },
            max: axisMinMax.maxX + 50,
        },
        tooltip: {
            customContent: tooltip
        },
        brush: {
            enabled: true,
            mask: {
                style: {
                    fill: "rgba(255,0,0,0.15)",
                },
            },
        },
    }


    return(
        <div className="bubbleChart__wrapper">
            <div className="bubbleChart__canvasWrapper">
                <div className="bubbleChart__yLabel" style={{ fontWeight:"600" }}>
                    {_metricsChecked ? _metricsChecked[1]?.label || "" : ""}
                </div>

                <Scatter
                    animation={false}
                  
                    {...config}
                />
            </div>

            <div className="bubbleChart__xLabel" style={{ fontWeight:"600" }}>
                {_metricsChecked ? _metricsChecked[0]?.label || "" : ""}
            </div>
        </div>
    )
}

export default BubbleChartType
