import React, { FC, useEffect } from "react"

import { Button } from "antd"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

interface IRequestButtonProps {
    btnDisabled: boolean
    setBtnDisabled: (val: boolean) => any
    setUpdateDashboard: () => void
}

const RequestButton: FC<IRequestButtonProps> = ({ btnDisabled, setBtnDisabled, setUpdateDashboard }) => {
    const { isFilterUpdate } = useTypedSelector((state) => state.meta)
    const { setMetaFilterUpdate } = useActions()

    useEffect(() => {
        if (isFilterUpdate) {
            setBtnDisabled(false)
            setMetaFilterUpdate({ value: false })
        }
    }, [isFilterUpdate, setBtnDisabled, setMetaFilterUpdate])

    const sendRequest = () => {
        setBtnDisabled(true)
        setUpdateDashboard()
    }


    return (!btnDisabled) ? (
        <Button
            type="primary"
            className="request_button"
            onClick={sendRequest}
        >
            Показать
        </Button>
    ) : null
}

export default RequestButton
