import React, { createContext, useState, useContext, ReactNode } from "react";

// Определяем тип контекста
interface ModalContextType {
  modalState2: { plan: number };
  setModalState2: React.Dispatch<React.SetStateAction<{ plan: number }>>;
}

// Создаём контекст с типом `ModalContextType | null`
const ModalContext = createContext<ModalContextType | null>(null);

// Хук для удобного использования контекста
export const useModalState = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalState должен использоваться внутри ModalProvider");
  }
  return context;
};

// Пропсы для `ModalProvider`
interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  // Теперь `plan` — число, а не строка
  const [modalState2, setModalState2] = useState<{ plan: number }>({ plan: 0 });

  return (
    <ModalContext.Provider value={{ modalState2, setModalState2 }}>
      {children}
    </ModalContext.Provider>
  );
};
