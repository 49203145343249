import React, { useState, useEffect } from 'react';
import { Button, Input, message, Modal, Select, Tag } from 'antd';
import styles from '../styles/TabContent.module.scss'
import { useAppDispatch, useTypedSelector } from '../../../hooks/useTypedSelector';
import { createTagRequest, fetchTags } from '../../../../../app/providers/redux/slices/reports/params/reports/reportsSlice';

const { Option } = Select;

export interface TagAttachModalProps {
  visible: boolean;
  onClose: () => void;
  reportId: number;
  reportType: 'reports' | 'dashboards';
  reportName?: string;
  attachedTags: { id: number; name: string }[];
  allTags: { id: number; name: string }[];
  staticTags?: { id: number; name: string }[];
  onAttach: (tagIds: number[]) => void;
  onDetach: (tagIds: number[]) => void;
  onCreateTag?: (name: string) => Promise<number>;
  activeMainTab: 'overview' | 'dashboards' | 'reports' | 'ads' | 'bd' | 'access' | 'tools' | 'settings' | 'import' | 'suppliers' | 'education';
}

export const TagAttachModal: React.FC<TagAttachModalProps> = ({
  visible,
  onClose,
  reportName,
  reportType,
  attachedTags,
  allTags,
  activeMainTab,
  onAttach,
  onDetach,
  staticTags = [],
  onCreateTag,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [loading, setLoading] = useState(false);
  const { selectedSchool } = useTypedSelector((state) => state.currentSchool)

  const allAvailableTags = [...allTags, ...staticTags];

  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedTagIds(attachedTags.map(t => t.id));
  }, [attachedTags]);

  const handleChange = (newSelectedIds: number[]) => {
    const added = newSelectedIds.filter(id => !selectedTagIds.includes(id));
    const removed = selectedTagIds.filter(id => !newSelectedIds.includes(id));

    if (added.length > 0) onAttach(added);
    if (removed.length > 0) onDetach(removed);

    setSelectedTagIds(newSelectedIds);
  };

      const handleCreateTag = async () => {
          if (!selectedSchool?.id) {
              message.error('Школа не выбрана');
              return;
          }
  
          const kindId = activeMainTab === 'reports' ? 1 : 2;
  
          try {
              await dispatch(
                  createTagRequest({
                      schoolId: selectedSchool.id,
                      kindId,
                      tagName: newTagName
                  })
              ).unwrap();
  
              message.success('Тег успешно создан');
              setIsCreateTagModalOpen(false);
              setNewTagName('');
          } catch (error) {
              message.error('Ошибка при создании тега');
          }
      };

  const filteredTags = allAvailableTags.filter(tag =>
    tag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <Modal
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
              Привязать тег к {reportType === 'reports' ? 'отчёту' : 'дашборду'}: {reportName}
            </span>
          </div>
        }
        open={visible}
        closable={false}
        footer={null}
        width={600}
        className={styles.customModal}
        destroyOnClose
        maskClosable
      >
        <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "15px", gap: 10}}>
          <Button
            onClick={onClose}
          >
            Закрыть
          </Button>
          <Button
            onClick={() => setIsCreateTagModalOpen(true)}
          >
            Создать тег
          </Button>
          <Button
            onClick={onClose}
          >
            Сохранить
          </Button>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Выберите теги"
          value={selectedTagIds}
          onChange={handleChange}
          onSearch={setSearchQuery}
          filterOption={false}
          showSearch
          tagRender={({ label, value, closable, onClose }) => (
            <Tag
              closable={closable}
              onClose={onClose}
              style={{ margin: 4, display: "flex" }}
            >
              {label}
            </Tag>
          )}
        >
          {filteredTags.map(tag => (
            <Option key={tag.id} value={tag.id}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{tag.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title="Создать новый тег"
        open={isCreateTagModalOpen}
        onOk={handleCreateTag}
        onCancel={() => setIsCreateTagModalOpen(false)}
        okText="Создать"
        cancelText="Отмена"
        confirmLoading={loading}
      >
        <Input
          placeholder="Введите название тега"
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
          onPressEnter={handleCreateTag}
        />
      </Modal>
    </>
  );
};