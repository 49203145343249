import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IReportsState } from "./types";
import {
  switchInterval,
  updateQueryParams,
} from "../../../../../../../helpers/utils/functions";
import { unApi } from "../../../../../../../shared/common/api/endpoints/reports/unApi";
import axios from "axios";
import { STATIC_TAGS } from "../../../../../../../shared/common/components/nav/components/constants/tags";
import { TabType } from "../../../../../../../shared/common/components/nav/types";
import { handleResponseError } from "../../../../../../../shared/common/api/middleware";
import { showSuccessMessage } from "../../../../../../../helpers/utils/ui";

const initialState: IReportsState = {
  isLoading: false,
  reports: [],
  savedDashboards: [],
  currentReport: {},
  currentDashboard: {},
  savedReports: [],
  isCreateMetricsVisible: false,
  tags: {
    dashboards: [],
    reports: [],
    static: [],
  },
  activeTab: 'all',
};

interface RootState {
  reports: IReportsState;
}

export const fetchTags = createAsyncThunk(
  'reports/fetchTags',
  async (_, { rejectWithValue }) => {
    try {
      const response = await unApi.getTags();
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);
export const deleteTag = createAsyncThunk(
  'reports/deleteTag',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await unApi.deleteTags(id);
      dispatch(fetchTags());
      return id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);
export const updateTag = createAsyncThunk(
  'reports/updateTag',
  async (
    { tagId, tagName }: { tagId: number; tagName: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await unApi.updateTag(tagId, tagName);
      dispatch(fetchTags());
      return { tagId, tagName };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);

export const createTagRequest = createAsyncThunk(
  'reports/createTag',
  async (
    { schoolId, kindId, tagName }: { schoolId: any; kindId: number; tagName: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await unApi.createTag(schoolId, kindId, tagName);
      dispatch(fetchTags());
      return { kindId, tagName };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);

export const fetchAttachTagsToReport = createAsyncThunk(
  'reports/attachTags',
  async (
    { reportId, tagIds }: { reportId: number; tagIds: number[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await unApi.attachTags(reportId, tagIds);
      dispatch(fetchUpdatedReport(reportId));
      return { reportId, tagIds };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);

export const detachTagsFromReport = createAsyncThunk(
  'reports/detachTags',
  async (
    { reportId, tagIds }: { reportId: number; tagIds: number[] },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await unApi.detachTags(reportId, tagIds);
      dispatch(fetchUpdatedReport(reportId));
      return { reportId, tagIds };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue({ message: 'Unknown error' });
    }
  }
);


const fetchUpdatedReport = createAsyncThunk(
  'reports/fetchUpdated',
  async (reportId: number, { getState }) => {
    const state = getState() as RootState;
    // Ищем в savedReports
    const report = state.reports.savedReports.find((r: any) => r.id === reportId);
    if (report) {
      return { item: report, type: 'report' };
    }

    // Ищем в savedDashboards
    const dashboard = state.reports.savedDashboards.find((d: any) => d.id === reportId);
    if (dashboard) {
      return { item: dashboard, type: 'dashboard' };
    }

    return null;
  }
);

export const toggleFavorite = createAsyncThunk(
  'reports/toggleFavorite',
  async ({ id, isFavorite }: { id: number; isFavorite: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const currentLocation = location.pathname;

      if (isFavorite) {
        await unApi.removeFavorite(id, currentLocation);
      } else {
        await unApi.setFavorite(id, currentLocation);
      }

      return { id, isFavorite: !isFavorite };
    } catch (error) {
      return rejectWithValue(error);
    }
  });



export const renameReportOrDashboard = createAsyncThunk(
  'reports/rename',
  async (
    {
      schoolId,
      id,
      newName,
      isDashboard
    }: {
      schoolId: string | number;
      id: string;
      newName: string;
      isDashboard: boolean;
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const items = isDashboard
        ? state.reports.savedDashboards
        : state.reports.savedReports;

      const item = items.find((item: any) => item.id === id);

      if (!item) {
        throw new Error('Элемент не найден');
      }

      const updatedValues = {
        ...item.values,
        name: newName
      };

      await unApi.editReport(schoolId, id, updatedValues, location.pathname);

      return { id, newName, isDashboard };
    } catch (error: any) {
      return rejectWithValue(error.response?.data);
    }
  }
);


export const deleteReportOrDashboard = createAsyncThunk(
  'reports/delete',
  async (
    {
      schoolId,
      id,
      isDashboard
    }: {
      schoolId: string | number;
      id: string;
      isDashboard: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      await unApi.removeFavorite(id, location.pathname);
      await unApi.deleteReport(schoolId, id, location.pathname);
      showSuccessMessage(isDashboard ? "Дашборд удален" : "Отчёт удален");
      return { id, isDashboard };
    } catch (error: any) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const copyReportOrDashboard = createAsyncThunk(
  'reports/copy',
  async (
    {
      schoolId,
      id,
      isDashboard,
      newName,
      values,
      pathName
    }: {
      schoolId: string | number;
      id: string;
      isDashboard: boolean;
      newName: string;
      values: any;
      pathName: any;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const copiedValues = {
        ...values,
        name: newName
      };

      const response = await unApi.createReport(
        schoolId,
        copiedValues,
        pathName
      );

      if (isDashboard) {
        dispatch(reportsSliceActions.addDashboard(response.data));
      } else {
        dispatch(reportsSliceActions.addReport(response.data));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateItemAccess = createAsyncThunk(
  'reports/updateAccess',
  async (
    {
      schoolId,
      id,
      accessId,
      itemType,
      values
    }: {
      schoolId: any;
      id: any;
      accessId: number;
      itemType: 'report' | 'dashboard';
      values: any;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      // Оптимистичное обновление
      dispatch(reportsSliceActions.optimisticUpdateAccess({ 
        id, 
        accessId, 
        itemType 
      }));

      // Отправка запроса с обновленным access_id
      await unApi.editReport(
        schoolId,
        id,
        { ...values },
        location.pathname,
        accessId,
      );

      return { id, accessId, itemType };
    } catch (error) {
      // Откат изменений при ошибке
      dispatch(reportsSliceActions.optimisticUpdateAccess({
        id,
        accessId: values.access_id,
        itemType
      }));
      return rejectWithValue(error);
    }
  }
);

const staticTags = STATIC_TAGS;
export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    optimisticUpdateAccess: (state, action: PayloadAction<{
      id: any;
      accessId: number;
      itemType: 'report' | 'dashboard';
    }>) => {
      const { id, accessId, itemType } = action.payload;
      const items = itemType === 'report' 
        ? state.savedReports 
        : state.savedDashboards;
    
      const updatedItems = items.map(item => 
        item.id === id ? { ...item, access_id: accessId } : item
      );
    
      if (itemType === 'report') {
        state.savedReports = updatedItems;
      } else {
        state.savedDashboards = updatedItems;
      }
    },
    optimisticDeleteReport: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      
      // Удаляем из общего списка
      state.reports = state.reports.filter((r: any) => r.id !== id);
      
      // Удаляем из сохраненных
      state.savedReports = state.savedReports.filter(r => r.id !== id);
      
      // Сбрасываем текущий отчет если это он
      if (state.currentReport.id === id) {
        state.currentReport = {};
      }
    },
    optimisticRename: (state, action: PayloadAction<{ id: string; newName: string }>) => {
      const { id, newName } = action.payload;

      // Обновляем текущий отчет
      if (state.currentReport.id === id) {
        state.currentReport.values.name = newName;
      }

      // Обновляем в общем списке отчетов
      const reportIndex = state.reports.findIndex((r: any) => r.id === id);
      if (reportIndex !== -1) {
        state.reports[reportIndex].values.name = newName;
      }

      // Обновляем в сохраненных отчетах
      const savedReportIndex = state.savedReports.findIndex(r => r.id === id);
      if (savedReportIndex !== -1) {
        state.savedReports[savedReportIndex].values.name = newName;
      }
    },
    optimisticToggleFavorite: (state, action: PayloadAction<{ id: number; isFavorite: boolean }>) => {
      // Обновляем текущий отчет
      if (state.currentReport.id === action.payload.id) {
        state.currentReport.is_favorite = action.payload.isFavorite;
      }

      // Обновляем сохраненные отчеты
      const reportIndex = state.savedReports.findIndex(r => r.id === action.payload.id);
      if (reportIndex !== -1) {
        state.savedReports[reportIndex].is_favorite = action.payload.isFavorite;
      } else {
        // Обновляем дашборды
        const dashboardIndex = state.savedDashboards.findIndex(d => d.id === action.payload.id);
        if (dashboardIndex !== -1) {
          state.savedDashboards[dashboardIndex].is_favorite = action.payload.isFavorite;
        }
      }
    },
    addReport: (state, action: PayloadAction<any>) => {
      state.savedReports.push(action.payload);
    },
    addDashboard: (state, action: PayloadAction<any>) => {
      state.savedDashboards.push(action.payload);
    },
    setReportsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setReports: (state, action: PayloadAction<any[]>) => {
      let reports = action.payload;

      if (Array.isArray(reports)) {
        reports.forEach((rep) => {
          Object.keys(rep.values).forEach((key) => {
            if (
              [
                "filters",
                "filtersReq",
                "filtersTable",
                "selFilters",
                "sortTable",
              ].includes(key)
            ) {
              if (rep.values[key].length === 0) {
                rep.values[key] = {};
              }
            }
          });
        });

        state.reports = reports;
      } else {
        console.error("Error: 'reports' is not an array:", reports);
      }
    },
    setSavedReports: (state, action: PayloadAction<any[]>) => {
      let reports = action.payload;

      if (Array.isArray(reports)) {
        reports.forEach((rep) => {
          Object.keys(rep.values).forEach((key) => {
            if (
              [
                "filters",
                "filtersReq",
                "filtersTable",
                "selFilters",
                "sortTable",
              ].includes(key)
            ) {
              if (rep.values[key].length === 0) {
                rep.values[key] = {};
              }
            }
          });
        });

        state.savedReports = reports;
      } else {
        console.error("Error: 'reports' is not an array:", reports);
      }
    },
    setSavedDashboards: (state, action: PayloadAction<any[]>) => {
      state.savedDashboards = action.payload;
    },

    setCurrentReport: (state, action: PayloadAction<any>) => {
      // Копируем весь объект отчёта
      const report = JSON.parse(JSON.stringify(action.payload));
      const savedReport = state.savedReports.find(r => r.id === report.id)
        || state.reports.find((r: any) => r.id === report.id);

      if (savedReport) {
        report.values.name = savedReport.values.name;
      }
      // Если есть период, обновляем start и end
      if (report?.values?.period?.interval) {
        const { start, end } = switchInterval(report.values.period.interval);
        if (start && end) {
          report.start = start.format("YYYY-MM-DD");
          report.end = end.format("YYYY-MM-DD");
        }
      }

      // Обновляем query params, если это необходимо
      updateQueryParams(report, "currentReport");

      // Сохраняем весь объект отчёта в currentReport
      state.currentReport = report;
    },
    setCurrentDashboard: (state, action: PayloadAction<any>) => {
      const payload = JSON.parse(JSON.stringify(action.payload));
      state.currentDashboard = {
        ...payload,
        values: JSON.parse(payload.values),
      };
    },
    setIsCreateMetricsVisible: (state, action: PayloadAction<boolean>) => {
      state.isCreateMetricsVisible = action.payload;
    },
    setReportFavorite: (state, action: PayloadAction<{ id: number; isFavorite: boolean }>) => {
      const index = state.savedReports.findIndex(r => r.id === action.payload.id);
      if (index !== -1) {
        state.savedReports[index].is_favorite = action.payload.isFavorite;
      }
    },
    setDashboardFavorite: (state, action: PayloadAction<{ id: number; isFavorite: boolean }>) => {
      const index = state.savedDashboards.findIndex(d => d.id === action.payload.id);
      if (index !== -1) {
        state.savedDashboards[index].is_favorite = action.payload.isFavorite;
      }
    },
    attachStaticTag: (state, action: PayloadAction<{ id: number; tagId: number; reportType: 'reports' | 'dashboards' }>) => {
      const { id, tagId, reportType } = action.payload;
      const targetArray = reportType === 'reports' ? state.savedReports : state.savedDashboards;
      const item = targetArray.find(item => item.id === id);
      if (item) {
        if (!item.tags) item.tags = {};
        item.tags[tagId] = STATIC_TAGS.find(t => t.id === tagId)?.name || '';
      }
    },
    detachStaticTag: (state, action: PayloadAction<{ id: number; tagId: number; reportType: 'reports' | 'dashboards' }>) => {
      const { id, tagId, reportType } = action.payload;
      const targetArray = reportType === 'reports' ? state.savedReports : state.savedDashboards;
      const item = targetArray.find(item => item.id === id);
      if (item?.tags) {
        delete item.tags[tagId];
      }
    },
    setActiveTag: (state, action: PayloadAction<TabType>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        // Проверяем, что action.payload содержит данные
        if (action.payload && typeof action.payload === 'object') {
          const { reports, dashboards } = action.payload;

          // Обновляем стейт
          state.tags = {
            dashboards: Array.isArray(dashboards) ? dashboards : [],
            reports: Array.isArray(reports) ? reports : [],
            static: staticTags, // Сохраняем статические теги
          };
        } else {
          console.error('Некорректный формат данных:', action.payload);
        }
        state.isLoading = false;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.isLoading = false;
        console.error('Ошибка загрузки тегов:', action.payload);
      })
      .addCase(deleteTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTag.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTag.rejected, (state, action) => {
        state.isLoading = false;
        console.error('Ошибка удаления тега:', action.payload);
      })
      .addCase(updateTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTag.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateTag.rejected, (state, action) => {
        state.isLoading = false;
        console.error('Ошибка переименования тега:', action.payload);
      })
      .addCase(fetchAttachTagsToReport.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAttachTagsToReport.fulfilled, (state, action) => {
        const { reportId, tagIds } = action.payload;

        state.savedDashboards = state.savedDashboards.map(dashboard => {
          if (dashboard.id === reportId) {
            const newTags = tagIds.reduce((acc, tagId) => {
              const tag = state.tags.dashboards.find(t => t.id === tagId);
              if (tag) acc[tagId] = tag.name;
              return acc;
            }, { ...dashboard.tags });

            return { ...dashboard, tags: newTags };
          }
          return dashboard;
        });
      })
      .addCase(fetchAttachTagsToReport.rejected, (state, action) => {
        state.isLoading = false;
        console.error('Ошибка привязки тегов:', action.payload);
      })
      .addCase(createTagRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTagRequest.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createTagRequest.rejected, (state, action) => {
        state.isLoading = false;
        console.error('Ошибка создания тега:', action.payload);
      })
      .addCase(detachTagsFromReport.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(detachTagsFromReport.fulfilled, (state, action) => {
        const { reportId, tagIds } = action.payload;

        state.savedDashboards = state.savedDashboards.map(dashboard => {
          if (dashboard.id === reportId && dashboard.tags) {
            const newTags = { ...dashboard.tags };
            tagIds.forEach(tagId => delete newTags[tagId]);
            return { ...dashboard, tags: newTags };
          }
          return dashboard;
        });
      })
      .addCase(fetchUpdatedReport.fulfilled, (state, action) => {
        if (action.payload) {
          const { item, type } = action.payload;

          if (type === 'report') {
            state.savedReports = state.savedReports.map(report =>
              report.id === item.id ? item : report
            );
          } else if (type === 'dashboard') {
            state.savedDashboards = state.savedDashboards.map(dashboard =>
              dashboard.id === item.id ? item : dashboard
            );
          }
        }
      })
      .addCase(toggleFavorite.fulfilled, (state, action) => {
        const { id, isFavorite } = action.payload;

        // Обновляем отчеты
        const reportIndex = state.savedReports.findIndex(r => r.id === id);
        if (reportIndex !== -1) {
          state.savedReports[reportIndex].is_favorite = isFavorite;
        }

        // Обновляем дашборды
        const dashboardIndex = state.savedDashboards.findIndex(d => d.id === id);
        if (dashboardIndex !== -1) {
          state.savedDashboards[dashboardIndex].is_favorite = isFavorite;
        }
      })
      .addCase(toggleFavorite.rejected, (state, action) => {
        const { id, isFavorite } = action.meta.arg;

        // Откатываем изменения при ошибке
        const reportIndex = state.savedReports.findIndex(r => r.id === id);
        if (reportIndex !== -1) {
          state.savedReports[reportIndex].is_favorite = isFavorite;
        }

        const dashboardIndex = state.savedDashboards.findIndex(d => d.id === id);
        if (dashboardIndex !== -1) {
          state.savedDashboards[dashboardIndex].is_favorite = isFavorite;
        }
      })
      .addCase(renameReportOrDashboard.fulfilled, (state, action) => {
        const { id, newName, isDashboard } = action.payload;
        if (isDashboard) {
          state.savedDashboards = state.savedDashboards.map(dashboard =>
            dashboard.id === id ? { ...dashboard, name: newName } : dashboard
          );
          state.currentDashboard.values.name = newName 
        } else {
          state.savedReports = state.savedReports.map(report =>
            report.id === id ? { ...report, name: newName } : report
          );
          state.currentReport.name = newName 
        }
      })
      .addCase(renameReportOrDashboard.rejected, (state, action) => {
        console.error('Ошибка переименования:', action.payload);
      })
      .addCase(deleteReportOrDashboard.fulfilled, (state, action) => {
        const { id, isDashboard } = action.payload;
        if (isDashboard) {
          state.savedDashboards = state.savedDashboards.filter(d => d.id !== id);
        } else {
          state.savedReports = state.savedReports.filter(r => r.id !== id);
        }
      })
      .addCase(deleteReportOrDashboard.rejected, (state, action) => {
        console.error('Ошибка удаления:', action.payload);
      });
  },
});

export const reportsSliceActions = reportsSlice.actions;
export const reportsSliceReducer = reportsSlice.reducer;
