import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import {
  CloseCircleFilled,
  DownOutlined,
  EditFilled,
  FieldTimeOutlined,
  FileTextOutlined,
  LeftOutlined,
  LinkOutlined,
  LoadingOutlined,
  RightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Popconfirm,
  Popover,
  Spin,
  Tooltip,
} from "antd";
import axios from "axios";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";

import AddReportTG from "./components/modals/AddReportTG/AddReportTG";
import EditReportTG from "./components/modals/EditReportTG/EditReportTG";
import {
  formatPeriod,
  getAdGroupings,
  getFiltersWithoutSegment,
} from "../../../../../../helpers/utils/functions";
import {
  handleReportPeriod,
  parseTableData,
} from "../../../../../../helpers/utils/reports";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../helpers/utils/ui";
import { BlockVisible } from "../../../../../../pages/dashboards/types";
import { unApi } from "../../../../api/endpoints/reports/unApi";
import { axiosConfigAuth } from "../../../../api/middleware";
import { API_UN_REPORT_EXEL } from "../../../../api/urls";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { listKeysGroupings } from "../../GroupingsAD";

type Props = {
  setAddBlockVisible: Dispatch<
    SetStateAction<BlockVisible>
  >;
};

const FixedHeaderSwitcher = () => {
  const { isOpenFixedHeader, isFixedHeader } = useTypedSelector(
    (state) => state.dashboard
  );
  const styles = { fontSize: "18px", cursor: "pointer" };
  const { setIsOpenFixedHeader } = useActions();
  const toggleIsOpenHeader = () => {
    setIsOpenFixedHeader(!isOpenFixedHeader);
  };
  if (!isFixedHeader) {
    return null;
  }
  return (
    <Tooltip 
      title={`${isOpenFixedHeader ? "Закрыть" : "Открыть"} дополнительные фильтры`}
      placement="bottomLeft"
    >
      <Button 
        onClick={toggleIsOpenHeader} 
        icon={isOpenFixedHeader ? (
          <LeftOutlined style={styles} />
          ) : (
            <RightOutlined style={styles} />
          )
        }
      />
    </Tooltip>
  );
};

const FixedUniversalTitle: FC<Props> = ({ setAddBlockVisible }) => {
  const location = useLocation();

  const { selectedSchool } = useTypedSelector((state) => state.currentSchool);
  const { savedDashboards, currentDashboard, isLoading } = useTypedSelector(
    (state) => state.reports
  );
  const period = useTypedSelector((state) => state.period);
  const { dashboards } = useTypedSelector((state) => state.dashboard);
  const { detailsDashboard } = useTypedSelector(
    (state) => state.details
  );
  const { filters, currentSegment } = useTypedSelector((state) => state.filter);
  const { attributes, attributesDashboard } = useTypedSelector(
    (state) => state.attributes
  );
  const {
    metrics,
    groupings,
  } = useTypedSelector((state) => state.table);
  const { isCompare } = useTypedSelector((state) => state.meta);
  const { isFixedHeader } = useTypedSelector(
    (state) => state.dashboard
  );
  const {
    setFilters,
    setDetails,
    setChartType,
    setGroupings,
    setMetrics,
    setGroupingsChecked,
    setMetricsChecked,
    setFiltersTable,
    setSortTable,
    setPeriod,
    setCurrentDashboard,
    setMetricsFiltersUpdate,
    setCurrentSegment,
    setAttributes,
    postReport,
    putReport,
    deleteReport,
    setMetaTableFetching,
    setComparePeriod,
    setAllDataFetching,
  } = useActions();
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ isModalCreateVisible, setIsModalCreateVisible ] = useState(false);
  const [ isModalEditVisible, setIsModalEditVisible ] = useState(false);
  const [ isModalXLSXVisible, setIsModalXLSXVisible ] = useState(false);
  const [ editReportId, setEditReportId ] = useState("");
  const [ isPopoverVisible, setIsPopoverVisible ] = useState(false);
  const [ reportName, setReportName ] = useState("");
  const [ excelName, setExcelName ] = useState("");
  const [ searchVal, setSearchVal ] = useState("");
  const [ isDisableSave, setIsDisableSave ] = useState(false);
  const [ ,setRefetch ] = useState(false);
  const [ tgOptions, setTgOptions ] = useState({
    visible: false,
    title: "",
    id: 0,
  });

  const [ tgEdit, setTgEdit ] = useState({
    visible: false,
    title: "",
    id: 0,
  });
  const [ reportsWithTasks ] = useState<number[]>([]);
  const reportParam = new URLSearchParams(location.search).get("report_id");

  useEffect(() => {
    if (location.search && location.search.includes("report_id")) {
      setIsDisableSave(true);
    } else {
      setIsDisableSave(false);
    }
  }, [ location ]);

  useEffect(() => {
    if (reportParam) {
      setReportFromUrl(reportParam);
    }
  }, []);

  const save = () => {
    let values: any = {
      ...currentDashboard.values,
      period: {
        start: moment(period.start).format("YYYY-MM-DD"),
        end: moment(period.end).format("YYYY-MM-DD"),
        interval: period.interval || period.select,
        select: period.select || period.interval,
        days: null,
      },
      attributesDashboard,
      detailsDashboard,
      filters,
      segment: null,
      blocks: dashboards,
    };

    if (Object.keys(currentSegment || {}).length > 0) {
      values.segment = currentSegment;
      values.filters = getFiltersWithoutSegment(
        filters,
        currentSegment?.values?.filters
      );
    } else {
      values.segment = {};
      values.filters = filters;
    }

    if (selectedSchool?.id) {
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days;
      }
      putReport(selectedSchool.id, { id: currentDashboard.id, values });
      showSuccessMessage("Отчёт сохранен");
    }
  };

  const saveAs = () => {
    if (selectedSchool?.id) {
      let values: any = {
        name: reportName,
        period: {
          start: moment(period.start).format("YYYY-MM-DD"),
          end: moment(period.end).format("YYYY-MM-DD"),
          interval: period.interval || period.select,
          select: period.select || period.interval,
        },
        attributesDashboard,
        detailsDashboard,
        filters,
        segment: null,
        blocks: dashboards,
      };

      if (Object.keys(currentSegment || {}).length > 0) {
        values.segment = currentSegment;
        values.filters = getFiltersWithoutSegment(
          filters,
          currentSegment?.values?.filters
        );
      } else {
        let _filters = filters;
        if (!Array.isArray(filters)) {
          _filters = [];
        }
        values.filters = _filters;
      }
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days;
      }
      window.history.replaceState({}, document.title, window.location.pathname);
      postReport(selectedSchool.id, { values });
      setIsModalVisible(false);
      setReportName("");
    }
  };

  const create = () => {
    if (selectedSchool?.id) {
      let values: any = {
        name: reportName,
        period: {
          start: moment(period.start).format("YYYY-MM-DD"),
          end: moment(period.end).format("YYYY-MM-DD"),
          interval: period.interval || period.select,
          select: period.select || period.interval,
        },
        attributesDashboard,
        detailsDashboard,
        filters,
        segment: null,
        blocks: dashboards,
      };
      if (Object.keys(currentSegment || {}).length > 0) {
        values.segment = currentSegment;
        values.filters = getFiltersWithoutSegment(
          filters,
          currentSegment?.values?.filters
        );
      } else {
        let _filters = filters;
        if (!Array.isArray(filters)) {
          _filters = [];
        }
        values.filters = _filters;
      }
      if (period.interval === "ndaysago" || period.select === "ndaysago") {
        values.period.days = period.days;
      }
      postReport(selectedSchool.id, { values });
      setIsModalCreateVisible(false);
      setReportName("");
    }
  };

  const edit = () => {
    if (selectedSchool?.id) {
      putReport(
        selectedSchool.id,
        { id: editReportId },
        currentDashboard
      );
      setIsModalEditVisible(false);
      setReportName("");
    }
  };
  const manualActivateReport = (report: any) => {
    const metrics = parseTableData(report.values.metrics);

    let filters = report.values.filters;

    if (!Array.isArray(report.values.filters)) {
      filters = [];
    }

    const handledPeriod = handleReportPeriod(report.values.period);

    setCurrentDashboard(report);
    setPeriod({
      start: handledPeriod.start,
      end: handledPeriod.end,
      interval: handledPeriod.interval,
      select: "",
    });
    setComparePeriod({
      index: 2,
      period: {
        start: handledPeriod.start,
        end: handledPeriod.end,
        interval: handledPeriod.interval,
      },
    });
    setDetails(report.values.details);
    setChartType(report.values.chartType);
    location.pathname.includes("ad-reports")
      ? setGroupings({
          data: getAdGroupings(report.values.groupings, listKeysGroupings),
        })
      : setGroupings({ data: report.values.groupings });
    setMetrics({ data: metrics });
    setFiltersTable(report.values.filtersTable);
    setSortTable(report.values.sortTable);
    setAttributes(report.values.filtersReq);

    setTimeout(() => {
      setGroupingsChecked(report.values.groupsChecked);
      setMetricsChecked({ data: report.values.metricsChecked });
    }, 5);

    const { segment } = report.values;
    const isSegment = segment && Object.keys(report.values.segment).length > 0;

    if (isSegment) {
      setCurrentSegment(segment);
      setFilters([ ...segment.values.filters, ...filters ]);
    } else {
      setCurrentSegment(null);
      setFilters(filters);
    }

    setIsPopoverVisible(false);
    setAllDataFetching({ value: true });
    setTimeout(() => {
      setMetaTableFetching({ value: true });
      setMetricsFiltersUpdate(true);
    }, 15);
  };

  const onEditReport = (id: string) => {
    const report = savedDashboards?.find((rep: any) => rep.id === id);
    setReportName(report?.name || "");
    setEditReportId(id);
    setIsModalEditVisible(true);
    setIsPopoverVisible(false);
  };

  const onRemoveReport = (id: string | number) => {
    if (!isCompare && selectedSchool?.id) {
      deleteReport(selectedSchool.id, { id });
    }
  };

  const saveXSLX = () => {
    const format = "YYYY-MM-DD";
    const _period = {
      ...formatPeriod({ start: period.start, end: period.end }, format),
    };
    const data = {
      name: excelName,
      period: _period,
      filters: attributes,
      groupings,
      metrics,
    };

    axios
      .post(API_UN_REPORT_EXEL, data, axiosConfigAuth())
      .then(() => {
        showSuccessMessage("Сохранено");
      })
      .catch(() => {
        showErrorMessage("Ошибка при сохранении");
      });

    setIsModalXLSXVisible(false);
    setExcelName("");
  };

  const copyReport = (hash: string) => {
    const reportLink = `${process.env.REACT_APP_BASE_URL}${location.pathname}?report_id=${hash}`;
    navigator.clipboard
      .writeText(reportLink)
      .then(() => {
        showSuccessMessage("Ссылка скопирована");
      })
      .catch(() => {
        showErrorMessage("Ошибка при копировании ссылки отчета");
      });
  };
  const handleAddTgClick = (report: {
    values: { name: string };
    id: number;
  }) => {
    setIsPopoverVisible(false);
    setTgOptions({ visible: true, title: report.values.name, id: report.id });
  };
  const handleEditTgClick = (report: {
    values: { name: string };
    id: number;
  }) => {
    setIsPopoverVisible(false);
    setTgEdit({ visible: true, title: report.values.name, id: report.id });
  };
  const setReportFromUrl = async (reportHash: string) => {
    unApi
      .getReportDataFromHash(reportHash)
      .then((response) => {
        if (response.status === 200) {
          const filterData = response.data[0];

          if (filterData) {
            manualActivateReport(filterData);
          }
        }
      })
      .catch((e) => {
        showErrorMessage("Ошибка при получении сегмента.");
      });
  };

  const listSegment = (
    <div style={{ width: 450 }}>
      <Input
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        placeholder="Найти дашборд"
        allowClear
      />
      <div
        style={{
          maxHeight: 300,
          overflowY: "auto",
          marginTop: 7,
          marginLeft: "-16px",
          marginRight: "-16px",
        }}
      >
        {savedDashboards
          ?.filter((rep: any) =>
            rep.name.toLowerCase().includes(searchVal.toLowerCase())
          )
          .map((rep: any) => (
            <div className="report-list-item" key={rep.id}>
              <div
                className="report-list-name"
                style={{ width: "100%" }}
              >
                {rep.name}
              </div>
              <div
                className="report-list-item-controls"
                style={{ marginLeft: "auto", whiteSpace: "nowrap" }}
              >
                {!location.pathname.includes("/webinar/quality") ? (
                  reportsWithTasks.includes(rep.id) ? (
                    <Button
                      onClick={() => handleEditTgClick(rep)}
                      icon={<FieldTimeOutlined style={{ color: "red" }} />}
                      size="small"
                      title="Отправлять отчёт в Telegram по времени"
                      type="link"
                      style={{ color: "#9a9a9a" }}
                    />
                  ) : (
                    <Button
                      onClick={() => handleAddTgClick(rep)}
                      icon={<FieldTimeOutlined />}
                      size="small"
                      title="Отправлять отчёт в Telegram по времени"
                      type="link"
                      style={{ color: "#9a9a9a" }}
                    />
                  )
                ) : null}

                <Button
                  onClick={() => copyReport(rep.hash)}
                  icon={<LinkOutlined />}
                  size="small"
                  title="Копировать"
                  type="link"
                  style={{ color: "#9a9a9a" }}
                />
                <Button
                  onClick={() => onEditReport(rep.id)}
                  icon={<EditFilled />}
                  size="small"
                  title="Редактировать отчет"
                  type="text"
                  style={{ color: "#9a9a9a" }}
                />
                <Popconfirm
                  title="Подтвердите удаление"
                  onConfirm={() => onRemoveReport(rep.id)}
                  okText="Удалить"
                  cancelText="Отмена"
                >
                  <Button
                    icon={<CloseCircleFilled />}
                    size="small"
                    title="Удалить отчёт"
                    type="text"
                    style={{ color: "#9a9a9a" }}
                  />
                </Popconfirm>
              </div>
            </div>
          ))}
      </div>
    </div>
  );

  const reportSelectDisplayElement = (name: string, unselected = false) => {
    return unselected ? (
      <span>
        <span style={{ marginRight: 10, marginTop: "-4px" }}>{name}</span>
        <DownOutlined />
      </span>
    ) : (
      <span style={{ fontWeight: "bold" }}>
        <FileTextOutlined />
        <span style={{ marginRight: 10, marginLeft: 10, marginTop: "-4px" }}>
          {name}
        </span>
        <DownOutlined />
      </span>
    );
  };
  const savingItemsDropDown: MenuProps["items"] = [
    {
      key: "1",
      label: Object.keys(currentDashboard).length > 0 && !isDisableSave && (
        <span onClick={save}>Сохранить</span>
      ),
    },
    {
      key: "2",
      label: <span onClick={() => setIsModalVisible(true)}>Сохранить как</span>,
    },
  ];
  const reportSelectName =
    Object.keys(currentDashboard).length === 0
      ? reportSelectDisplayElement("Все дашборды", true)
      : reportSelectDisplayElement(currentDashboard.values.name);

  const menuItems = [
    {
      key: "1",
      label: "Блок",
      onClick: () => setAddBlockVisible({ visible: true, blockType: "" }),
    },
    {
      key: "2",
      label: "Заголовок",
      onClick: () =>
        setAddBlockVisible({ visible: true, blockType: "heading" }),
    },
  ];

  return (
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: isFixedHeader ? 0 : 20,
            width: isFixedHeader ? "fit-content" : "100%",
            flexDirection: isFixedHeader ? "row-reverse" : "row",
            columnGap: 12,
          }}
        >
          {savedDashboards.length === 0 || isFixedHeader ? null : (
            <div
              style={{
                marginRight: 7,
                display: "flex",
                alignItems: "center",
              }}
            >
              {savedDashboards.length > 0 && (
                <>
                  <Popover
                    content={listSegment}
                    open={isPopoverVisible}
                    onOpenChange={(visible) => setIsPopoverVisible(visible)}
                    trigger="click"
                    placement="bottomLeft"
                    showArrow={false}
                    overlayStyle={{ paddingTop: 0 }}
                  >
                    <Button type="dashed">{reportSelectName}</Button>
                  </Popover>
                </>
              )}
            </div>
          )}

          {savedDashboards.length === 0 && !isFixedHeader ? (
            <div>
              <Button
                disabled={isCompare}
                onClick={() => setIsModalCreateVisible(true)}
                type="primary"
              >
                Создать дашборд
              </Button>
            </div>
          ) : null}
          <FixedHeaderSwitcher />
          <Dropdown trigger={[ "click" ]} menu={{ items: menuItems }}>
            <Button
              type="primary"
              style={{ marginLeft: isFixedHeader ? 0 : 16, borderRadius: 8 }}
            >
              Добавить блок <DownOutlined />
            </Button>
          </Dropdown>

          {!isCompare && dashboards?.length > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <Dropdown
                disabled={isCompare}
                menu={{ items: savingItemsDropDown }}
                trigger={[ "click" ]}
              >
                <Button icon={<SaveOutlined />} disabled={isCompare} />
              </Dropdown>
            </div>
          )}
          {tgOptions.visible && (
            <AddReportTG
              setRefetch={setRefetch}
              isVisible={tgOptions.visible}
              setVisible={setTgOptions}
              report={tgOptions}
            />
          )}
          {tgEdit.visible && (
            <EditReportTG
              setRefetch={setRefetch}
              isVisible={tgEdit.visible}
              setVisible={setTgEdit}
              report={tgEdit}
            />
          )}
          <Modal
            title="Сохранить дашборд как"
            open={isModalVisible}
            onOk={() =>
              reportName
                ? saveAs()
                : showErrorMessage("Введите название дашборда")
            }
            onCancel={() => setIsModalVisible(false)}
            okText="Сохранить"
          >
            <Form>
              <Form.Item
                name="reportName"
                label="Название дашборда"
                rules={[
                  { required: true, message: "Введите название дашборда" },
                  { min: 1, message: "Введите название дашборда" },
                ]}
              >
                <Input
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  size="large"
                  placeholder="Введите название дашборда"
                  allowClear
                />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Редактировать название дашборда"
            open={isModalEditVisible}
            onOk={edit}
            onCancel={() => setIsModalEditVisible(false)}
            okText="Сохранить"
          >
            <Input
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              size="large"
              placeholder="Введите название дашборда"
              allowClear
            />
          </Modal>
          <Modal
            title="Создать дашборд"
            open={isModalCreateVisible}
            onOk={create}
            onCancel={() => setIsModalCreateVisible(false)}
            okText="Сохранить"
          >
            <Input
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
              size="large"
              placeholder="Введите название дашборда"
              allowClear
            />
          </Modal>
          <Modal
            title="Скачать XLSX таблицы"
            open={isModalXLSXVisible}
            onOk={saveXSLX}
            onCancel={() => setIsModalXLSXVisible(false)}
            okText="Сохранить"
          >
            <Input
              value={excelName}
              onChange={(e) => setExcelName(e.target.value)}
              size="large"
              placeholder="Введите название файла"
              allowClear
            />
          </Modal>
        </div>
      </Spin>
  );
};

export default FixedUniversalTitle;
