import React, { FC } from "react"

import { Space } from "antd"

import ManualSelect from "./selects/ManualSelect"
import { IAttributionItem } from "../../../../../../app/types/filter"


interface IAttributionProps {
    items: IAttributionItem[]
    setBtnDisabled?: (value: boolean) => void
}

const Attribution: FC<IAttributionProps> = ({ items, setBtnDisabled }) => {
    const isDashboardAttributes = items.some((el)=>el.selectValues.some((element)=>element.id === "blocks") )
    return (
        <Space direction="horizontal" className="params__attr-container">
            {
                items.map((item, index) => (
                    <ManualSelect
                        setBtnDisabled={setBtnDisabled}
                        isDashboardAttributes={isDashboardAttributes}
                        key={index}
                        id={item.id}
                        name={item.name}
                        selectValues={item.selectValues}
                        description={item.description}
                        icon={item.icon}
                    />
                ))
            }
        </Space>
    )
}

export default Attribution
