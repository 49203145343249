import React, { FC, useEffect } from "react"

import "./styles/root.scss"
import "antd/dist/antd.css"
import { bindClickObserver } from "../helpers/utils/hits"
import { AppRouter } from "./providers/router/ui/AppRouter"
import GlobalErrorWrapper from "../shared/common/components/wrappers/global-error-wrapper/GlobalErrorWrapper"
import GlobalModalsWrapper from "../shared/common/components/wrappers/modal-wrapper/GlobalModals"
import { ModalProvider } from "../shared/common/components/reports/dashboards/ContextModal"






const App: FC = () => {
    useEffect(() => {
        bindClickObserver()
    }, [])

    return (
        <div id="app" className="App">
             <ModalProvider>
                <GlobalErrorWrapper>
                    <GlobalModalsWrapper>
                        <AppRouter />
                    </GlobalModalsWrapper>
                </GlobalErrorWrapper>
            </ModalProvider>
        </div>
    )
}

export default App
